input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.6);
    background-size: 100% 10%;
    background-repeat: no-repeat;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #0d6efd;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }