

.table {
  --bs-table-color: #67748e;
  --bs-table-bg: transparent;
  --bs-table-border-color: #e9ecef;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #67748e;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #67748e;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #67748e;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}


.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dfe3fa;
  --bs-table-border-color: #c9cce1;
  --bs-table-striped-bg: #d4d8ee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9cce1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ced2e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e6e9ee;
  --bs-table-border-color: #cfd2d6;
  --bs-table-striped-bg: #dbdde2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cfd2d6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d5d8dc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d5f5e7;
  --bs-table-border-color: #c0ddd0;
  --bs-table-striped-bg: #cae9db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c0ddd0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c5e3d6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff5fc;
  --bs-table-border-color: #badde3;
  --bs-table-striped-bg: #c5e9ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badde3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe3e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fee0d9;
  --bs-table-border-color: #e5cac3;
  --bs-table-striped-bg: #f1d5ce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5cac3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebcfc9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fdd7de;
  --bs-table-border-color: #e4c2c8;
  --bs-table-striped-bg: #f0ccd3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4c2c8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eac7cd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #e9ecef;
  --bs-table-border-color: #d2d4d7;
  --bs-table-striped-bg: #dde0e3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d2d4d7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d8dadd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #344767;
  --bs-table-border-color: #485976;
  --bs-table-striped-bg: #3e506f;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #485976;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #435572;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  min-height: 50vh;
  max-height: fit-content;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* @media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
} */






.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 1rem;
  --bs-card-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  --bs-card-inner-border-radius: 1rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #fff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-admin-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: all 0.15s ease-in, border-radius 0.15s ease;
  --bs-accordion-border-color: rgba(0, 0, 0, 0.125);
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0.25rem;
  --bs-accordion-inner-border-radius: 0.25rem;
  --bs-accordion-btnadmin-padding-x: 1rem;
  --bs-accordion-btnadmin-padding-y: 1rem;
  --bs-accordion-btnadmin-color: #67748e;
  --bs-accordion-btnadmin-bg: transparent;
  --bs-accordion-btnadmin-icon: none;
  --bs-accordion-btnadmin-icon-width: 1rem;
  --bs-accordion-btnadmin-icon-transform: rotate(180deg);
  --bs-accordion-btnadmin-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btnadmin-active-icon: none;
  --bs-accordion-btnadmin-focus-border-color: #5e72e4;
  --bs-accordion-btnadmin-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #344767;
  --bs-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btnadmin-padding-y) var(--bs-accordion-btnadmin-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btnadmin-color);
  text-align: left;
  background-color: var(--bs-accordion-btnadmin-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed-admin) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed-admin)::after {
  background-image: var(--bs-accordion-btnadmin-active-icon);
  transform: var(--bs-accordion-btnadmin-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btnadmin-icon-width);
  height: var(--bs-accordion-btnadmin-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btnadmin-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btnadmin-icon-width);
  transition: var(--bs-accordion-btnadmin-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btnadmin-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btnadmin-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed-admin {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 1rem;
  --bs-breadcrumb-padding-y: 0.5rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: #e9ecef;
  --bs-breadcrumb-border-radius: 0.5rem;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #5e72e4;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.5rem;
  --bs-pagination-hover-color: #233dd2;
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: #233dd2;
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #5e72e4;
  --bs-pagination-active-border-color: #5e72e4;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}



.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-border-radius: 0.75rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.9em;
  --bs-badge-padding-y: 0.55em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.45rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius, 0);
}

.badge:empty {
  display: none;
}

.btnadmin .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.5rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius, 0);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btnadmin-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #384489;
  --bs-alert-bg: #dfe3fa;
  --bs-alert-border-color: #cfd5f7;
}

.alert-primary .alert-link {
  color: #2d366e;
}

.alert-secondary {
  --bs-alert-color: #4f5867;
  --bs-alert-bg: #e6e9ee;
  --bs-alert-border-color: #dadee6;
}

.alert-secondary .alert-link {
  color: #3f4652;
}

.alert-success {
  --bs-alert-color: #125237;
  --bs-alert-bg: #d5f5e7;
  --bs-alert-border-color: #c0f0dc;
}

.alert-success .alert-link {
  color: #0e422c;
}

.alert-info {
  --bs-alert-color: #075260;
  --bs-alert-bg: #cff5fc;
  --bs-alert-border-color: #b8f0fa;
}

.alert-info .alert-link {
  color: #06424d;
}

.alert-warning {
  --bs-alert-color: #973b26;
  --bs-alert-bg: #fee0d9;
  --bs-alert-border-color: #fed0c6;
}

.alert-warning .alert-link {
  color: #792f1e;
}

.alert-danger {
  --bs-alert-color: #932037;
  --bs-alert-bg: #fdd7de;
  --bs-alert-border-color: #fcc3ce;
}

.alert-danger .alert-link {
  color: #761a2c;
}

.alert-light {
  --bs-alert-color: #5d5e60;
  --bs-alert-bg: #fbfbfc;
  --bs-alert-border-color: #f8f9fa;
}

.alert-light .alert-link {
  color: #4a4b4d;
}

.alert-dark {
  --bs-alert-color: #1f2b3e;
  --bs-alert-bg: #d6dae1;
  --bs-alert-border-color: #c2c8d1;
}

.alert-dark .alert-link {
  color: #192232;
}

.alert-white {
  --bs-alert-color: #666666;
  --bs-alert-bg: white;
  --bs-alert-border-color: white;
}

.alert-white .alert-link {
  color: #525252;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 8px;
  }
}

.progress {
  --bs-progress-height: 8px;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.5rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #5e72e4;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: inherit;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.5rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #67748e;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #5e72e4;
  --bs-list-group-active-border-color: #5e72e4;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #384489;
  background-color: #dfe3fa;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #384489;
  background-color: #c9cce1;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #384489;
  border-color: #384489;
}

.list-group-item-secondary {
  color: #4f5867;
  background-color: #e6e9ee;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #4f5867;
  background-color: #cfd2d6;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #4f5867;
  border-color: #4f5867;
}

.list-group-item-success {
  color: #125237;
  background-color: #d5f5e7;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #125237;
  background-color: #c0ddd0;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #125237;
  border-color: #125237;
}

.list-group-item-info {
  color: #075260;
  background-color: #cff5fc;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #075260;
  background-color: #badde3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #075260;
  border-color: #075260;
}

.list-group-item-warning {
  color: #973b26;
  background-color: #fee0d9;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #973b26;
  background-color: #e5cac3;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #973b26;
  border-color: #973b26;
}

.list-group-item-danger {
  color: #932037;
  background-color: #fdd7de;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #932037;
  background-color: #e4c2c8;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #932037;
  border-color: #932037;
}

.list-group-item-light {
  color: #5d5e60;
  background-color: #fbfbfc;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #5d5e60;
  background-color: #e2e2e3;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #5d5e60;
  border-color: #5d5e60;
}

.list-group-item-dark {
  color: #1f2b3e;
  background-color: #d6dae1;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1f2b3e;
  background-color: #c1c4cb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2b3e;
  border-color: #1f2b3e;
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btnadmin-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #fff;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.btnadmin-close:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.75;
}

.btnadmin-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25);
  opacity: 1;
}

.btnadmin-close:disabled,
.btnadmin-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btnadmin-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.75rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 0;
  --bs-toast-border-color: transparent;
  --bs-toast-border-radius: 0.5rem;
  --bs-toast-box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
  --bs-toast-header-color: #344767;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 1090;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btnadmin-close {
  margin-right: calc(var(--bs-toast-padding-x) * -.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1050;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.75rem;
  --bs-modal-box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
  --bs-modal-inner-border-radius: calc(0.75rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #dee2e6;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #dee2e6;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1040;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btnadmin-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(var(--bs-modal-header-padding-y) * -.5) calc(var(--bs-modal-header-padding-x) * -.5) calc(var(--bs-modal-header-padding-y) * -.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1070;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.5rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius, 0);
}

.popover {
  --bs-popover-zindex: 1060;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.75rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 0px;
  --bs-popover-border-color: rgba(0, 0, 0, 0.2);
  --bs-popover-border-radius: 0.75rem;
  --bs-popover-inner-border-radius: calc(0.75rem - 0px);
  --bs-popover-box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #344767;
  --bs-popover-header-bg: #e9ecef;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #67748e;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc((var(--bs-popover-arrow-height) * -1) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }

  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }

  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }

  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }

  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }

  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btnadmin-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btnadmin::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(94, 114, 228, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(131, 146, 171, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(45, 206, 137, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(17, 205, 239, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(251, 99, 64, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(245, 54, 92, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(233, 236, 239, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(52, 71, 103, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #5e72e4 !important;
}

.link-primary:hover,
.link-primary:focus {
  color: #7e8ee9 !important;
}

.link-secondary {
  color: #8392ab !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #9ca8bc !important;
}

.link-success {
  color: #2dce89 !important;
}

.link-success:hover,
.link-success:focus {
  color: #57d8a1 !important;
}

.link-info {
  color: #11cdef !important;
}

.link-info:hover,
.link-info:focus {
  color: #41d7f2 !important;
}

.link-warning {
  color: #fb6340 !important;
}

.link-warning:hover,
.link-warning:focus {
  color: #fc8266 !important;
}

.link-danger {
  color: #f5365c !important;
}

.link-danger:hover,
.link-danger:focus {
  color: #f75e7d !important;
}

.link-light {
  color: #e9ecef !important;
}

.link-light:hover,
.link-light:focus {
  color: #edf0f2 !important;
}

.link-dark {
  color: #344767 !important;
}

.link-dark:hover,
.link-dark:focus {
  color: #2a3952 !important;
}

.link-white {
  color: #fff !important;
}

.link-white:hover,
.link-white:focus {
  color: white !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-admin-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-admin-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-admin-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-admin-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-admin-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-admin-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-admin-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-admin-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-admin-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-admin-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-admin-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-admin-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-adminbaseline {
  vertical-align: baseline !important;
}

.align-admintop {
  vertical-align: top !important;
}

.align-adminmiddle {
  vertical-align: middle !important;
}

.align-adminbottom {
  vertical-align: bottom !important;
}

.align-admintext-bottom {
  vertical-align: text-bottom !important;
}

.align-admintext-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07) !important;
}

.shadow-sm {
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
}

.shadow-lg {
  box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06) !important;
}

.shadow-xl {
  box-shadow: 0 23px 45px -11px rgba(20, 20, 20, 0.25) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.positon-adminstatic {
  position: static !important;
}

.positon-adminrelative-admin-admin {
  position: relative !important;
}

.positon-adminabsolute {
  position: absolute !important;
}

.positon-adminfixed {
  position: fixed !important;
}

.positon-adminsticky {
  position: sticky !important;
}

.top-admin-0 {
  top: 0 !important;
}

.top-admin-1 {
  top: 1% !important;
}

.top-admin-2 {
  top: 2% !important;
}

.top-admin-3 {
  top: 3% !important;
}

.top-admin-4 {
  top: 4% !important;
}

.top-admin-5 {
  top: 5% !important;
}

.top-admin-6 {
  top: 6% !important;
}

.top-admin-7 {
  top: 7% !important;
}

.top-admin-8 {
  top: 8% !important;
}

.top-admin-9 {
  top: 9% !important;
}

.top-admin-10 {
  top: 10% !important;
}

.top-admin-50 {
  top: 50% !important;
}

.top-admin-100 {
  top: 100% !important;
}

.bottom-admin-0 {
  bottom: 0 !important;
}

.bottom-admin-1 {
  bottom: 1% !important;
}

.bottom-admin-2 {
  bottom: 2% !important;
}

.bottom-admin-3 {
  bottom: 3% !important;
}

.bottom-admin-4 {
  bottom: 4% !important;
}

.bottom-admin-5 {
  bottom: 5% !important;
}

.bottom-admin-6 {
  bottom: 6% !important;
}

.bottom-admin-7 {
  bottom: 7% !important;
}

.bottom-admin-8 {
  bottom: 8% !important;
}

.bottom-admin-9 {
  bottom: 9% !important;
}

.bottom-admin-10 {
  bottom: 10% !important;
}

.bottom-admin-50 {
  bottom: 50% !important;
}

.bottom-admin-100 {
  bottom: 100% !important;
}

.start-admin-0 {
  left: 0 !important;
}

.start-admin-1 {
  left: 1% !important;
}

.start-admin-2 {
  left: 2% !important;
}

.start-admin-3 {
  left: 3% !important;
}

.start-admin-4 {
  left: 4% !important;
}

.start-admin-5 {
  left: 5% !important;
}

.start-admin-6 {
  left: 6% !important;
}

.start-admin-7 {
  left: 7% !important;
}

.start-admin-8 {
  left: 8% !important;
}

.start-admin-9 {
  left: 9% !important;
}

.start-admin-10 {
  left: 10% !important;
}

.start-admin-50 {
  left: 50% !important;
}

.start-admin-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-1 {
  right: 1% !important;
}

.end-2 {
  right: 2% !important;
}

.end-3 {
  right: 3% !important;
}

.end-4 {
  right: 4% !important;
}

.end-5 {
  right: 5% !important;
}

.end-6 {
  right: 6% !important;
}

.end-7 {
  right: 7% !important;
}

.end-8 {
  right: 8% !important;
}

.end-9 {
  right: 9% !important;
}

.end-10 {
  right: 10% !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #5e72e4 !important;
}

.border-secondary {
  border-color: #8392ab !important;
}

.border-success {
  border-color: #2dce89 !important;
}

.border-info {
  border-color: #11cdef !important;
}

.border-warning {
  border-color: #fb6340 !important;
}

.border-danger {
  border-color: #f5365c !important;
}

.border-light {
  border-color: #e9ecef !important;
}

.border-dark {
  border-color: #344767 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-admin-0 {
  width: 0% !important;
}

.w-admin-1 {
  width: 1% !important;
}

.w-admin-2 {
  width: 2% !important;
}

.w-admin-3 {
  width: 3% !important;
}

.w-admin-4 {
  width: 4% !important;
}

.w-admin-5 {
  width: 5% !important;
}

.w-admin-6 {
  width: 6% !important;
}

.w-admin-7 {
  width: 7% !important;
}

.w-admin-8 {
  width: 8% !important;
}

.w-admin-9 {
  width: 9% !important;
}

.w-admin-10 {
  width: 10% !important;
}

.w-admin-15 {
  width: 15% !important;
}

.w-admin-20 {
  width: 20% !important;
}

.w-admin-25 {
  width: 25% !important;
}

.w-admin-30 {
  width: 30% !important;
}

.w-admin-35 {
  width: 35% !important;
}

.w-admin-40 {
  width: 40% !important;
}

.w-admin-45 {
  width: 45% !important;
}

.w-admin-50 {
  width: 50% !important;
}

.w-admin-55 {
  width: 55% !important;
}

.w-admin-60 {
  width: 60% !important;
}

.w-admin-65 {
  width: 65% !important;
}

.w-admin-70 {
  width: 70% !important;
}

.w-admin-75 {
  width: 75% !important;
}

.w-admin-80 {
  width: 80% !important;
}

.w-admin-85 {
  width: 85% !important;
}

.w-admin-90 {
  width: 90% !important;
}

.w-admin-95 {
  width: 95% !important;
}

.w-admin-100 {
  width: 100% !important;
}

.w-admin-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-admin-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-admin-25 {
  height: 25% !important;
}

.h-admin-50 {
  height: 50% !important;
}

.h-admin-75 {
  height: 75% !important;
}

.h-admin-100 {
  height: 100% !important;
}

.h-admin-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-35 {
  min-height: 35vh !important;
}

.min-vh-45 {
  min-height: 45vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-55 {
  min-height: 55vh !important;
}

.min-vh-65 {
  min-height: 65vh !important;
}

.min-vh-70 {
  min-height: 70vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-80 {
  min-height: 80vh !important;
}

.min-vh-85 {
  min-height: 85vh !important;
}

.min-vh-90 {
  min-height: 90vh !important;
}

.min-vh-95 {
  min-height: 95vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-admin-fill {
  flex: 1 1 auto !important;
}

.flex-admin-row {
  flex-direction: row !important;
}

.flex-admin-column {
  flex-direction: column !important;
}

.flex-admin-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-admin-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-admin-grow-0 {
  flex-grow: 0 !important;
}

.flex-admin-grow-1 {
  flex-grow: 1 !important;
}

.flex-admin-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-admin-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-admin-wrap {
  flex-wrap: wrap !important;
}

.flex-admin-nowrap {
  flex-wrap: nowrap !important;
}

.flex-admin-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-admincontent-start {
  justify-content: flex-start !important;
}

.justify-admincontent-end {
  justify-content: flex-end !important;
}

.justify-admincontent-center {
  justify-content: center !important;
}

.justify-admincontent-between {
  justify-content: space-between !important;
}

.justify-admincontent-around {
  justify-content: space-around !important;
}

.justify-admincontent-evenly {
  justify-content: space-evenly !important;
}

.align-adminitems-start {
  align-items: flex-start !important;
}

.align-adminitems-end {
  align-items: flex-end !important;
}

.align-adminitems-center {
  align-items: center !important;
}

.align-adminitems-baseline {
  align-items: baseline !important;
}

.align-adminitems-stretch {
  align-items: stretch !important;
}

.align-admincontent-start {
  align-content: flex-start !important;
}

.align-admincontent-end {
  align-content: flex-end !important;
}

.align-admincontent-center {
  align-content: center !important;
}

.align-admincontent-between {
  align-content: space-between !important;
}

.align-admincontent-around {
  align-content: space-around !important;
}

.align-admincontent-stretch {
  align-content: stretch !important;
}

.align-adminself-auto {
  align-self: auto !important;
}

.align-adminself-start {
  align-self: flex-start !important;
}

.align-adminself-end {
  align-self: flex-end !important;
}

.align-adminself-center {
  align-self: center !important;
}

.align-adminself-baseline {
  align-self: baseline !important;
}

.align-adminself-stretch {
  align-self: stretch !important;
}

.order-admin-first {
  order: -1 !important;
}

.order-admin-0 {
  order: 0 !important;
}

.order-admin-1 {
  order: 1 !important;
}

.order-admin-2 {
  order: 2 !important;
}

.order-admin-3 {
  order: 3 !important;
}

.order-admin-4 {
  order: 4 !important;
}

.order-admin-5 {
  order: 5 !important;
}

.order-admin-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.m-10 {
  margin: 12rem !important;
}

.m-11 {
  margin: 14rem !important;
}

.m-12 {
  margin: 16rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-9 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-10 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-11 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-12 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-9 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-10 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-11 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-12 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}








.mt-7 {
  margin-top: 6rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 10rem !important;
}

.mt-10 {
  margin-top: 12rem !important;
}

.mt-11 {
  margin-top: 14rem !important;
}

.mt-12 {
  margin-top: 16rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}



.me-7 {
  margin-right: 6rem !important;
}

.me-8 {
  margin-right: 8rem !important;
}

.me-9 {
  margin-right: 10rem !important;
}

.me-10 {
  margin-right: 12rem !important;
}

.me-11 {
  margin-right: 14rem !important;
}

.me-12 {
  margin-right: 16rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}



.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 10rem !important;
}

.mb-10 {
  margin-bottom: 12rem !important;
}

.mb-11 {
  margin-bottom: 14rem !important;
}

.mb-12 {
  margin-bottom: 16rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}


.ms-7 {
  margin-left: 6rem !important;
}

.ms-8 {
  margin-left: 8rem !important;
}

.ms-9 {
  margin-left: 10rem !important;
}

.ms-10 {
  margin-left: 12rem !important;
}

.ms-11 {
  margin-left: 14rem !important;
}

.ms-12 {
  margin-left: 16rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.m-n8 {
  margin: -8rem !important;
}

.m-n9 {
  margin: -10rem !important;
}

.m-n10 {
  margin: -12rem !important;
}

.m-n11 {
  margin: -14rem !important;
}

.m-n12 {
  margin: -16rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n8 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n9 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n10 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.mx-n11 {
  margin-right: -14rem !important;
  margin-left: -14rem !important;
}

.mx-n12 {
  margin-right: -16rem !important;
  margin-left: -16rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n8 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n9 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n10 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.my-n11 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.my-n12 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.mt-n8 {
  margin-top: -8rem !important;
}

.mt-n9 {
  margin-top: -10rem !important;
}

.mt-n10 {
  margin-top: -12rem !important;
}

.mt-n11 {
  margin-top: -14rem !important;
}

.mt-n12 {
  margin-top: -16rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -4rem !important;
}

.me-n7 {
  margin-right: -6rem !important;
}

.me-n8 {
  margin-right: -8rem !important;
}

.me-n9 {
  margin-right: -10rem !important;
}

.me-n10 {
  margin-right: -12rem !important;
}

.me-n11 {
  margin-right: -14rem !important;
}

.me-n12 {
  margin-right: -16rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4rem !important;
}

.mb-n7 {
  margin-bottom: -6rem !important;
}

.mb-n8 {
  margin-bottom: -8rem !important;
}

.mb-n9 {
  margin-bottom: -10rem !important;
}

.mb-n10 {
  margin-bottom: -12rem !important;
}

.mb-n11 {
  margin-bottom: -14rem !important;
}

.mb-n12 {
  margin-bottom: -16rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -4rem !important;
}

.ms-n7 {
  margin-left: -6rem !important;
}

.ms-n8 {
  margin-left: -8rem !important;
}

.ms-n9 {
  margin-left: -10rem !important;
}

.ms-n10 {
  margin-left: -12rem !important;
}

.ms-n11 {
  margin-left: -14rem !important;
}

.ms-n12 {
  margin-left: -16rem !important;
}

.p-admin-0 {
  padding: 0 !important;
}

.p-admin-1 {
  padding: 0.25rem !important;
}

.p-admin-2 {
  padding: 0.5rem !important;
}

.p-admin-3 {
  padding: 1rem !important;
}

.p-admin-4 {
  padding: 1.5rem !important;
}

.p-admin-5 {
  padding: 3rem !important;
}

.p-admin-6 {
  padding: 4rem !important;
}

.p-admin-7 {
  padding: 6rem !important;
}

.p-admin-8 {
  padding: 8rem !important;
}

.p-admin-9 {
  padding: 10rem !important;
}

.p-admin-10 {
  padding: 12rem !important;
}

.p-admin-11 {
  padding: 14rem !important;
}

.p-admin-12 {
  padding: 16rem !important;
}

/* .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
 .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
} 

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-9 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-10 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-11 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-12 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-10 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-11 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-12 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}*/

.pt-0 {
  padding-top: 0 !important;
}


.pt-7 {
  padding-top: 6rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-9 {
  padding-top: 10rem !important;
}

.pt-10 {
  padding-top: 12rem !important;
}

.pt-11 {
  padding-top: 14rem !important;
}

.pt-12 {
  padding-top: 16rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}


.pe-7 {
  padding-right: 6rem !important;
}

.pe-8 {
  padding-right: 8rem !important;
}

.pe-9 {
  padding-right: 10rem !important;
}

.pe-10 {
  padding-right: 12rem !important;
}

.pe-11 {
  padding-right: 14rem !important;
}

.pe-12 {
  padding-right: 16rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}



.pb-7 {
  padding-bottom: 6rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.pb-9 {
  padding-bottom: 10rem !important;
}

.pb-10 {
  padding-bottom: 12rem !important;
}

.pb-11 {
  padding-bottom: 14rem !important;
}

.pb-12 {
  padding-bottom: 16rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}



.ps-7 {
  padding-left: 6rem !important;
}

.ps-8 {
  padding-left: 8rem !important;
}

.ps-9 {
  padding-left: 10rem !important;
}

.ps-10 {
  padding-left: 12rem !important;
}

.ps-11 {
  padding-left: 14rem !important;
}

.ps-12 {
  padding-left: 16rem !important;
}

.gap-0 {
  gap: 0 !important;
}



.gap-7 {
  gap: 6rem !important;
}

.gap-8 {
  gap: 8rem !important;
}

.gap-9 {
  gap: 10rem !important;
}

.gap-10 {
  gap: 12rem !important;
}

.gap-11 {
  gap: 14rem !important;
}

.gap-12 {
  gap: 16rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}


.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #5e72e4 !important;
}

.text-secondary {
  color: #8392ab !important;
}

.text-success {
  color: #2dce89 !important;
}

.text-info {
  color: #11cdef !important;
}

.text-warning {
  color: #fb6340 !important;
}

.text-danger {
  color: #f5365c !important;
}

.text-light {
  color: #e9ecef !important;
}

.text-dark {
  /* color: #344767 !important; */
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #67748e !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  background-color: #5e72e4 !important;
}

.bg-secondary {
  background-color: #8392ab !important;
}

.bg-success {
  background-color: #2dce89 !important;
}

.bg-info {
  background-color: #11cdef !important;
}

.bg-warning {
  background-color: #fb6340 !important;
}

.bg-danger {
  background-color: #f5365c !important;
}

.bg-light {
  background-color: #e9ecef !important;
}

.bg-dark {
  background-color: #344767 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gray-100 {
  background-color: #f8f9fa !important;
}

.bg-gray-200 {
  background-color: #e9ecef !important;
}

.bg-gray-300 {
  background-color: #dee2e6 !important;
}

.bg-gray-400 {
  background-color: #ced4da !important;
}

.bg-gray-500 {
  background-color: #adb5bd !important;
}

.bg-gray-600 {
  background-color: #6c757d !important;
}

.bg-gray-700 {
  background-color: #495057 !important;
}

.bg-gray-800 {
  background-color: #343a40 !important;
}

.bg-gray-900 {
  background-color: #212529 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.25rem !important;
}

.rounded-2 {
  border-radius: 0.375rem !important;
}

.rounded-3 {
  border-radius: 0.75rem !important;
}

.rounded-circle,
.avatar.rounded-circle img {
  border-radius: 100% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.transform-scale-5 {
  transform: scale(0.5) !important;
}

.transform-scale-6 {
  transform: scale(0.6) !important;
}

.transform-scale-7 {
  transform: scale(0.7) !important;
}

.transform-scale-8 {
  transform: scale(0.8) !important;
}

.transform-scale-9 {
  transform: scale(0.9) !important;
}

.transform-scale-10 {
  transform: scale(1) !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.letter-spacing-1 {
  letter-spacing: 1px !important;
}

.letter-spacing-2 {
  letter-spacing: 2px !important;
}

.letter-spacing-3 {
  letter-spacing: 3px !important;
}

.letter-spacing-4 {
  letter-spacing: 4px !important;
}

.letter-spacing-5 {
  letter-spacing: 5px !important;
}

.border-radius-top-start {
  border-top-left-radius: 0.375rem !important;
}

.border-radius-top-start-0 {
  border-top-left-radius: 0 !important;
}

.border-radius-top-start-sm {
  border-top-left-radius: 0.25rem !important;
}

.border-radius-top-start-md {
  border-top-left-radius: 0.375rem !important;
}

.border-radius-top-start-lg {
  border-top-left-radius: 0.75rem !important;
}

.border-radius-top-start-xl {
  border-top-left-radius: 1rem !important;
}

.border-radius-top-start-2xl {
  border-top-left-radius: 1.5rem !important;
}

.border-radius-top-start-circle {
  border-top-left-radius: 50% !important;
}

.border-radius-top-start-pill {
  border-top-left-radius: 50rem !important;
}

.border-radius-top-end {
  border-top-right-radius: 0.375rem !important;
}

.border-radius-top-end-0 {
  border-top-right-radius: 0 !important;
}

.border-radius-top-end-sm {
  border-top-right-radius: 0.25rem !important;
}

.border-radius-top-end-md {
  border-top-right-radius: 0.375rem !important;
}

.border-radius-top-end-lg {
  border-top-right-radius: 0.75rem !important;
}

.border-radius-top-end-xl {
  border-top-right-radius: 1rem !important;
}

.border-radius-top-end-2xl {
  border-top-right-radius: 1.5rem !important;
}

.border-radius-top-end-circle {
  border-top-right-radius: 50% !important;
}

.border-radius-top-end-pill {
  border-top-right-radius: 50rem !important;
}

.border-radius-bottom-start {
  border-bottom-left-radius: 0.375rem !important;
}

.border-radius-bottom-start-0 {
  border-bottom-left-radius: 0 !important;
}

.border-radius-bottom-start-sm {
  border-bottom-left-radius: 0.25rem !important;
}

.border-radius-bottom-start-md {
  border-bottom-left-radius: 0.375rem !important;
}

.border-radius-bottom-start-lg {
  border-bottom-left-radius: 0.75rem !important;
}

.border-radius-bottom-start-xl {
  border-bottom-left-radius: 1rem !important;
}

.border-radius-bottom-start-2xl {
  border-bottom-left-radius: 1.5rem !important;
}

.border-radius-bottom-start-circle {
  border-bottom-left-radius: 50% !important;
}

.border-radius-bottom-start-pill {
  border-bottom-left-radius: 50rem !important;
}

.border-radius-bottom-end {
  border-bottom-right-radius: 0.375rem !important;
}

.border-radius-bottom-end-0 {
  border-bottom-right-radius: 0 !important;
}

.border-radius-bottom-end-sm {
  border-bottom-right-radius: 0.25rem !important;
}

.border-radius-bottom-end-md {
  border-bottom-right-radius: 0.375rem !important;
}

.border-radius-bottom-end-lg {
  border-bottom-right-radius: 0.75rem !important;
}

.border-radius-bottom-end-xl {
  border-bottom-right-radius: 1rem !important;
}

.border-radius-bottom-end-2xl {
  border-bottom-right-radius: 1.5rem !important;
}

.border-radius-bottom-end-circle {
  border-bottom-right-radius: 50% !important;
}

.border-radius-bottom-end-pill {
  border-bottom-right-radius: 50rem !important;
}

.max-height-100 {
  max-height: 100px !important;
}

.max-height-150 {
  max-height: 150px !important;
}

.max-height-160 {
  max-height: 160px !important;
}

.max-height-200 {
  max-height: 200px !important;
}

.max-height-250 {
  max-height: 250px !important;
}

.max-height-300 {
  max-height: 300px !important;
}

.max-height-400 {
  max-height: 400px !important;
}

.max-height-500 {
  max-height: 500px !important;
}

.max-height-600 {
  max-height: 600px !important;
}

.max-height-vh-10 {
  max-height: 10vh !important;
}

.max-height-vh-20 {
  max-height: 20vh !important;
}

.max-height-vh-30 {
  max-height: 30vh !important;
}

.max-height-vh-40 {
  max-height: 40vh !important;
}

.max-height-vh-50 {
  max-height: 50vh !important;
}

.max-height-vh-60 {
  max-height: 60vh !important;
}

.max-height-vh-70 {
  max-height: 70vh !important;
}

.max-height-vh-80 {
  max-height: 80vh !important;
}

.max-height-vh-90 {
  max-height: 90vh !important;
}

.max-height-vh-100 {
  max-height: 100vh !important;
}

.min-height-100 {
  min-height: 100px !important;
}

.min-height-150 {
  min-height: 150px !important;
}

.min-height-160 {
  min-height: 160px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.min-height-250 {
  min-height: 250px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

.min-height-400 {
  min-height: 400px !important;
}

.min-height-500 {
  min-height: 500px !important;
}

.min-height-600 {
  min-height: 600px !important;
}

.height-100 {
  height: 100px !important;
}

.height-200 {
  height: 200px !important;
}

.height-300 {
  height: 300px !important;
}

.height-400 {
  height: 400px !important;
}

.height-500 {
  height: 500px !important;
}

.height-600 {
  height: 600px !important;
}

.max-width-100 {
  max-width: 100px !important;
}

.max-width-200 {
  max-width: 200px !important;
}

.max-width-300 {
  max-width: 300px !important;
}

.max-width-400 {
  max-width: 400px !important;
}

.max-width-500 {
  max-width: 500px !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .border-top-sm {
    border-top: 1px solid #dee2e6 !important;
  }

  .border-top-sm-0 {
    border-top: 0 !important;
  }

  .border-end-sm {
    border-right: 1px solid #dee2e6 !important;
  }

  .border-end-sm-0 {
    border-right: 0 !important;
  }

  .border-bottom-sm {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }

  .border-start-sm {
    border-left: 1px solid #dee2e6 !important;
  }

  .border-start-sm-0 {
    border-left: 0 !important;
  }

  .w-admin-sm-0 {
    width: 0% !important;
  }

  .w-admin-sm-1 {
    width: 1% !important;
  }

  .w-admin-sm-2 {
    width: 2% !important;
  }

  .w-admin-sm-3 {
    width: 3% !important;
  }

  .w-admin-sm-4 {
    width: 4% !important;
  }

  .w-admin-sm-5 {
    width: 5% !important;
  }

  .w-admin-sm-6 {
    width: 6% !important;
  }

  .w-admin-sm-7 {
    width: 7% !important;
  }

  .w-admin-sm-8 {
    width: 8% !important;
  }

  .w-admin-sm-9 {
    width: 9% !important;
  }

  .w-admin-sm-10 {
    width: 10% !important;
  }

  .w-admin-sm-15 {
    width: 15% !important;
  }

  .w-admin-sm-20 {
    width: 20% !important;
  }

  .w-admin-sm-25 {
    width: 25% !important;
  }

  .w-admin-sm-30 {
    width: 30% !important;
  }

  .w-admin-sm-35 {
    width: 35% !important;
  }

  .w-admin-sm-40 {
    width: 40% !important;
  }

  .w-admin-sm-45 {
    width: 45% !important;
  }

  .w-admin-sm-50 {
    width: 50% !important;
  }

  .w-admin-sm-55 {
    width: 55% !important;
  }

  .w-admin-sm-60 {
    width: 60% !important;
  }

  .w-admin-sm-65 {
    width: 65% !important;
  }

  .w-admin-sm-70 {
    width: 70% !important;
  }

  .w-admin-sm-75 {
    width: 75% !important;
  }

  .w-admin-sm-80 {
    width: 80% !important;
  }

  .w-admin-sm-85 {
    width: 85% !important;
  }

  .w-admin-sm-90 {
    width: 90% !important;
  }

  .w-admin-sm-95 {
    width: 95% !important;
  }

  .w-admin-sm-100 {
    width: 100% !important;
  }

  .w-admin-sm-auto {
    width: auto !important;
  }

  .flex-admin-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-admin-sm-row {
    flex-direction: row !important;
  }

  .flex-admin-sm-column {
    flex-direction: column !important;
  }

  .flex-admin-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-admin-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-admin-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-admin-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-admin-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-admin-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-admin-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-admin-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-admin-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-admincontent-sm-start {
    justify-content: flex-start !important;
  }

  .justify-admincontent-sm-end {
    justify-content: flex-end !important;
  }

  .justify-admincontent-sm-center {
    justify-content: center !important;
  }

  .justify-admincontent-sm-between {
    justify-content: space-between !important;
  }

  .justify-admincontent-sm-around {
    justify-content: space-around !important;
  }

  .justify-admincontent-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-adminitems-sm-start {
    align-items: flex-start !important;
  }

  .align-adminitems-sm-end {
    align-items: flex-end !important;
  }

  .align-adminitems-sm-center {
    align-items: center !important;
  }

  .align-adminitems-sm-baseline {
    align-items: baseline !important;
  }

  .align-adminitems-sm-stretch {
    align-items: stretch !important;
  }

  .align-admincontent-sm-start {
    align-content: flex-start !important;
  }

  .align-admincontent-sm-end {
    align-content: flex-end !important;
  }

  .align-admincontent-sm-center {
    align-content: center !important;
  }

  .align-admincontent-sm-between {
    align-content: space-between !important;
  }

  .align-admincontent-sm-around {
    align-content: space-around !important;
  }

  .align-admincontent-sm-stretch {
    align-content: stretch !important;
  }

  .align-adminself-sm-auto {
    align-self: auto !important;
  }

  .align-adminself-sm-start {
    align-self: flex-start !important;
  }

  .align-adminself-sm-end {
    align-self: flex-end !important;
  }

  .align-adminself-sm-center {
    align-self: center !important;
  }

  .align-adminself-sm-baseline {
    align-self: baseline !important;
  }

  .align-adminself-sm-stretch {
    align-self: stretch !important;
  }

  .order-admin-sm-first {
    order: -1 !important;
  }

  .order-admin-sm-0 {
    order: 0 !important;
  }

  .order-admin-sm-1 {
    order: 1 !important;
  }

  .order-admin-sm-2 {
    order: 2 !important;
  }

  .order-admin-sm-3 {
    order: 3 !important;
  }

  .order-admin-sm-4 {
    order: 4 !important;
  }

  .order-admin-sm-5 {
    order: 5 !important;
  }

  .order-admin-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 4rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .m-sm-8 {
    margin: 8rem !important;
  }

  .m-sm-9 {
    margin: 10rem !important;
  }

  .m-sm-10 {
    margin: 12rem !important;
  }

  .m-sm-11 {
    margin: 14rem !important;
  }

  .m-sm-12 {
    margin: 16rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-sm-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-sm-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-sm-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-sm-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 4rem !important;
  }

  .mt-sm-7 {
    margin-top: 6rem !important;
  }

  .mt-sm-8 {
    margin-top: 8rem !important;
  }

  .mt-sm-9 {
    margin-top: 10rem !important;
  }

  .mt-sm-10 {
    margin-top: 12rem !important;
  }

  .mt-sm-11 {
    margin-top: 14rem !important;
  }

  .mt-sm-12 {
    margin-top: 16rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 4rem !important;
  }

  .me-sm-7 {
    margin-right: 6rem !important;
  }

  .me-sm-8 {
    margin-right: 8rem !important;
  }

  .me-sm-9 {
    margin-right: 10rem !important;
  }

  .me-sm-10 {
    margin-right: 12rem !important;
  }

  .me-sm-11 {
    margin-right: 14rem !important;
  }

  .me-sm-12 {
    margin-right: 16rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 14rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 16rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 4rem !important;
  }

  .ms-sm-7 {
    margin-left: 6rem !important;
  }

  .ms-sm-8 {
    margin-left: 8rem !important;
  }

  .ms-sm-9 {
    margin-left: 10rem !important;
  }

  .ms-sm-10 {
    margin-left: 12rem !important;
  }

  .ms-sm-11 {
    margin-left: 14rem !important;
  }

  .ms-sm-12 {
    margin-left: 16rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .m-sm-n6 {
    margin: -4rem !important;
  }

  .m-sm-n7 {
    margin: -6rem !important;
  }

  .m-sm-n8 {
    margin: -8rem !important;
  }

  .m-sm-n9 {
    margin: -10rem !important;
  }

  .m-sm-n10 {
    margin: -12rem !important;
  }

  .m-sm-n11 {
    margin: -14rem !important;
  }

  .m-sm-n12 {
    margin: -16rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-sm-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-sm-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-sm-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-sm-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-sm-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-sm-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-sm-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .mt-sm-n6 {
    margin-top: -4rem !important;
  }

  .mt-sm-n7 {
    margin-top: -6rem !important;
  }

  .mt-sm-n8 {
    margin-top: -8rem !important;
  }

  .mt-sm-n9 {
    margin-top: -10rem !important;
  }

  .mt-sm-n10 {
    margin-top: -12rem !important;
  }

  .mt-sm-n11 {
    margin-top: -14rem !important;
  }

  .mt-sm-n12 {
    margin-top: -16rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .me-sm-n6 {
    margin-right: -4rem !important;
  }

  .me-sm-n7 {
    margin-right: -6rem !important;
  }

  .me-sm-n8 {
    margin-right: -8rem !important;
  }

  .me-sm-n9 {
    margin-right: -10rem !important;
  }

  .me-sm-n10 {
    margin-right: -12rem !important;
  }

  .me-sm-n11 {
    margin-right: -14rem !important;
  }

  .me-sm-n12 {
    margin-right: -16rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -16rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .ms-sm-n6 {
    margin-left: -4rem !important;
  }

  .ms-sm-n7 {
    margin-left: -6rem !important;
  }

  .ms-sm-n8 {
    margin-left: -8rem !important;
  }

  .ms-sm-n9 {
    margin-left: -10rem !important;
  }

  .ms-sm-n10 {
    margin-left: -12rem !important;
  }

  .ms-sm-n11 {
    margin-left: -14rem !important;
  }

  .ms-sm-n12 {
    margin-left: -16rem !important;
  }

  .p-admin-sm-0 {
    padding: 0 !important;
  }

  .p-admin-sm-1 {
    padding: 0.25rem !important;
  }

  .p-admin-sm-2 {
    padding: 0.5rem !important;
  }

  .p-admin-sm-3 {
    padding: 1rem !important;
  }

  .p-admin-sm-4 {
    padding: 1.5rem !important;
  }

  .p-admin-sm-5 {
    padding: 3rem !important;
  }

  .p-admin-sm-6 {
    padding: 4rem !important;
  }

  .p-admin-sm-7 {
    padding: 6rem !important;
  }

  .p-admin-sm-8 {
    padding: 8rem !important;
  }

  .p-admin-sm-9 {
    padding: 10rem !important;
  }

  .p-admin-sm-10 {
    padding: 12rem !important;
  }

  .p-admin-sm-11 {
    padding: 14rem !important;
  }

  .p-admin-sm-12 {
    padding: 16rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-sm-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-sm-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-sm-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-sm-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-sm-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 4rem !important;
  }

  .pt-sm-7 {
    padding-top: 6rem !important;
  }

  .pt-sm-8 {
    padding-top: 8rem !important;
  }

  .pt-sm-9 {
    padding-top: 10rem !important;
  }

  .pt-sm-10 {
    padding-top: 12rem !important;
  }

  .pt-sm-11 {
    padding-top: 14rem !important;
  }

  .pt-sm-12 {
    padding-top: 16rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 4rem !important;
  }

  .pe-sm-7 {
    padding-right: 6rem !important;
  }

  .pe-sm-8 {
    padding-right: 8rem !important;
  }

  .pe-sm-9 {
    padding-right: 10rem !important;
  }

  .pe-sm-10 {
    padding-right: 12rem !important;
  }

  .pe-sm-11 {
    padding-right: 14rem !important;
  }

  .pe-sm-12 {
    padding-right: 16rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 12rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 14rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 16rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 4rem !important;
  }

  .ps-sm-7 {
    padding-left: 6rem !important;
  }

  .ps-sm-8 {
    padding-left: 8rem !important;
  }

  .ps-sm-9 {
    padding-left: 10rem !important;
  }

  .ps-sm-10 {
    padding-left: 12rem !important;
  }

  .ps-sm-11 {
    padding-left: 14rem !important;
  }

  .ps-sm-12 {
    padding-left: 16rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 4rem !important;
  }

  .gap-sm-7 {
    gap: 6rem !important;
  }

  .gap-sm-8 {
    gap: 8rem !important;
  }

  .gap-sm-9 {
    gap: 10rem !important;
  }

  .gap-sm-10 {
    gap: 12rem !important;
  }

  .gap-sm-11 {
    gap: 14rem !important;
  }

  .gap-sm-12 {
    gap: 16rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .transform-scale-sm-5 {
    transform: scale(0.5) !important;
  }

  .transform-scale-sm-6 {
    transform: scale(0.6) !important;
  }

  .transform-scale-sm-7 {
    transform: scale(0.7) !important;
  }

  .transform-scale-sm-8 {
    transform: scale(0.8) !important;
  }

  .transform-scale-sm-9 {
    transform: scale(0.9) !important;
  }

  .transform-scale-sm-10 {
    transform: scale(1) !important;
  }

  .border-radius-top-start-sm {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-sm-0 {
    border-top-left-radius: 0 !important;
  }

  .border-radius-top-start-sm-sm {
    border-top-left-radius: 0.25rem !important;
  }

  .border-radius-top-start-sm-md {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-sm-lg {
    border-top-left-radius: 0.75rem !important;
  }

  .border-radius-top-start-sm-xl {
    border-top-left-radius: 1rem !important;
  }

  .border-radius-top-start-sm-2xl {
    border-top-left-radius: 1.5rem !important;
  }

  .border-radius-top-start-sm-circle {
    border-top-left-radius: 50% !important;
  }

  .border-radius-top-start-sm-pill {
    border-top-left-radius: 50rem !important;
  }

  .border-radius-top-end-sm {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-sm-0 {
    border-top-right-radius: 0 !important;
  }

  .border-radius-top-end-sm-sm {
    border-top-right-radius: 0.25rem !important;
  }

  .border-radius-top-end-sm-md {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-sm-lg {
    border-top-right-radius: 0.75rem !important;
  }

  .border-radius-top-end-sm-xl {
    border-top-right-radius: 1rem !important;
  }

  .border-radius-top-end-sm-2xl {
    border-top-right-radius: 1.5rem !important;
  }

  .border-radius-top-end-sm-circle {
    border-top-right-radius: 50% !important;
  }

  .border-radius-top-end-sm-pill {
    border-top-right-radius: 50rem !important;
  }

  .border-radius-bottom-start-sm {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-sm-0 {
    border-bottom-left-radius: 0 !important;
  }

  .border-radius-bottom-start-sm-sm {
    border-bottom-left-radius: 0.25rem !important;
  }

  .border-radius-bottom-start-sm-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-sm-lg {
    border-bottom-left-radius: 0.75rem !important;
  }

  .border-radius-bottom-start-sm-xl {
    border-bottom-left-radius: 1rem !important;
  }

  .border-radius-bottom-start-sm-2xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .border-radius-bottom-start-sm-circle {
    border-bottom-left-radius: 50% !important;
  }

  .border-radius-bottom-start-sm-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .border-radius-bottom-end-sm {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-sm-0 {
    border-bottom-right-radius: 0 !important;
  }

  .border-radius-bottom-end-sm-sm {
    border-bottom-right-radius: 0.25rem !important;
  }

  .border-radius-bottom-end-sm-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-sm-lg {
    border-bottom-right-radius: 0.75rem !important;
  }

  .border-radius-bottom-end-sm-xl {
    border-bottom-right-radius: 1rem !important;
  }

  .border-radius-bottom-end-sm-2xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .border-radius-bottom-end-sm-circle {
    border-bottom-right-radius: 50% !important;
  }

  .border-radius-bottom-end-sm-pill {
    border-bottom-right-radius: 50rem !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .border-top-md {
    border-top: 1px solid #dee2e6 !important;
  }

  .border-top-md-0 {
    border-top: 0 !important;
  }

  .border-end-md {
    border-right: 1px solid #dee2e6 !important;
  }

  .border-end-md-0 {
    border-right: 0 !important;
  }

  .border-bottom-md {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }

  .border-start-md {
    border-left: 1px solid #dee2e6 !important;
  }

  .border-start-md-0 {
    border-left: 0 !important;
  }

  .w-admin-md-0 {
    width: 0% !important;
  }

  .w-admin-md-1 {
    width: 1% !important;
  }

  .w-admin-md-2 {
    width: 2% !important;
  }

  .w-admin-md-3 {
    width: 3% !important;
  }

  .w-admin-md-4 {
    width: 4% !important;
  }

  .w-admin-md-5 {
    width: 5% !important;
  }

  .w-admin-md-6 {
    width: 6% !important;
  }

  .w-admin-md-7 {
    width: 7% !important;
  }

  .w-admin-md-8 {
    width: 8% !important;
  }

  .w-admin-md-9 {
    width: 9% !important;
  }

  .w-admin-md-10 {
    width: 10% !important;
  }

  .w-admin-md-15 {
    width: 15% !important;
  }

  .w-admin-md-20 {
    width: 20% !important;
  }

  .w-admin-md-25 {
    width: 25% !important;
  }

  .w-admin-md-30 {
    width: 30% !important;
  }

  .w-admin-md-35 {
    width: 35% !important;
  }

  .w-admin-md-40 {
    width: 40% !important;
  }

  .w-admin-md-45 {
    width: 45% !important;
  }

  .w-admin-md-50 {
    width: 50% !important;
  }

  .w-admin-md-55 {
    width: 55% !important;
  }

  .w-admin-md-60 {
    width: 60% !important;
  }

  .w-admin-md-65 {
    width: 65% !important;
  }

  .w-admin-md-70 {
    width: 70% !important;
  }

  .w-admin-md-75 {
    width: 75% !important;
  }

  .w-admin-md-80 {
    width: 80% !important;
  }

  .w-admin-md-85 {
    width: 85% !important;
  }

  .w-admin-md-90 {
    width: 90% !important;
  }

  .w-admin-md-95 {
    width: 95% !important;
  }

  .w-admin-md-100 {
    width: 100% !important;
  }

  .w-admin-md-auto {
    width: auto !important;
  }

  .flex-admin-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-admin-md-row {
    flex-direction: row !important;
  }

  .flex-admin-md-column {
    flex-direction: column !important;
  }

  .flex-admin-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-admin-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-admin-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-admin-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-admin-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-admin-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-admin-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-admin-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-admin-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-admincontent-md-start {
    justify-content: flex-start !important;
  }

  .justify-admincontent-md-end {
    justify-content: flex-end !important;
  }

  .justify-admincontent-md-center {
    justify-content: center !important;
  }

  .justify-admincontent-md-between {
    justify-content: space-between !important;
  }

  .justify-admincontent-md-around {
    justify-content: space-around !important;
  }

  .justify-admincontent-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-adminitems-md-start {
    align-items: flex-start !important;
  }

  .align-adminitems-md-end {
    align-items: flex-end !important;
  }

  .align-adminitems-md-center {
    align-items: center !important;
  }

  .align-adminitems-md-baseline {
    align-items: baseline !important;
  }

  .align-adminitems-md-stretch {
    align-items: stretch !important;
  }

  .align-admincontent-md-start {
    align-content: flex-start !important;
  }

  .align-admincontent-md-end {
    align-content: flex-end !important;
  }

  .align-admincontent-md-center {
    align-content: center !important;
  }

  .align-admincontent-md-between {
    align-content: space-between !important;
  }

  .align-admincontent-md-around {
    align-content: space-around !important;
  }

  .align-admincontent-md-stretch {
    align-content: stretch !important;
  }

  .align-adminself-md-auto {
    align-self: auto !important;
  }

  .align-adminself-md-start {
    align-self: flex-start !important;
  }

  .align-adminself-md-end {
    align-self: flex-end !important;
  }

  .align-adminself-md-center {
    align-self: center !important;
  }

  .align-adminself-md-baseline {
    align-self: baseline !important;
  }

  .align-adminself-md-stretch {
    align-self: stretch !important;
  }

  .order-admin-md-first {
    order: -1 !important;
  }

  .order-admin-md-0 {
    order: 0 !important;
  }

  .order-admin-md-1 {
    order: 1 !important;
  }

  .order-admin-md-2 {
    order: 2 !important;
  }

  .order-admin-md-3 {
    order: 3 !important;
  }

  .order-admin-md-4 {
    order: 4 !important;
  }

  .order-admin-md-5 {
    order: 5 !important;
  }

  .order-admin-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 4rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .m-md-8 {
    margin: 8rem !important;
  }

  .m-md-9 {
    margin: 10rem !important;
  }

  .m-md-10 {
    margin: 12rem !important;
  }

  .m-md-11 {
    margin: 14rem !important;
  }

  .m-md-12 {
    margin: 16rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-md-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-md-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-md-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-md-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 4rem !important;
  }

  .mt-md-7 {
    margin-top: 6rem !important;
  }

  .mt-md-8 {
    margin-top: 8rem !important;
  }

  .mt-md-9 {
    margin-top: 10rem !important;
  }

  .mt-md-10 {
    margin-top: 12rem !important;
  }

  .mt-md-11 {
    margin-top: 14rem !important;
  }

  .mt-md-12 {
    margin-top: 16rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 4rem !important;
  }

  .me-md-7 {
    margin-right: 6rem !important;
  }

  .me-md-8 {
    margin-right: 8rem !important;
  }

  .me-md-9 {
    margin-right: 10rem !important;
  }

  .me-md-10 {
    margin-right: 12rem !important;
  }

  .me-md-11 {
    margin-right: 14rem !important;
  }

  .me-md-12 {
    margin-right: 16rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 4rem !important;
  }

  .mb-md-7 {
    margin-bottom: 6rem !important;
  }

  .mb-md-8 {
    margin-bottom: 8rem !important;
  }

  .mb-md-9 {
    margin-bottom: 10rem !important;
  }

  .mb-md-10 {
    margin-bottom: 12rem !important;
  }

  .mb-md-11 {
    margin-bottom: 14rem !important;
  }

  .mb-md-12 {
    margin-bottom: 16rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 4rem !important;
  }

  .ms-md-7 {
    margin-left: 6rem !important;
  }

  .ms-md-8 {
    margin-left: 8rem !important;
  }

  .ms-md-9 {
    margin-left: 10rem !important;
  }

  .ms-md-10 {
    margin-left: 12rem !important;
  }

  .ms-md-11 {
    margin-left: 14rem !important;
  }

  .ms-md-12 {
    margin-left: 16rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .m-md-n6 {
    margin: -4rem !important;
  }

  .m-md-n7 {
    margin: -6rem !important;
  }

  .m-md-n8 {
    margin: -8rem !important;
  }

  .m-md-n9 {
    margin: -10rem !important;
  }

  .m-md-n10 {
    margin: -12rem !important;
  }

  .m-md-n11 {
    margin: -14rem !important;
  }

  .m-md-n12 {
    margin: -16rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-md-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-md-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-md-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-md-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-md-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-md-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-md-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .mt-md-n6 {
    margin-top: -4rem !important;
  }

  .mt-md-n7 {
    margin-top: -6rem !important;
  }

  .mt-md-n8 {
    margin-top: -8rem !important;
  }

  .mt-md-n9 {
    margin-top: -10rem !important;
  }

  .mt-md-n10 {
    margin-top: -12rem !important;
  }

  .mt-md-n11 {
    margin-top: -14rem !important;
  }

  .mt-md-n12 {
    margin-top: -16rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .me-md-n6 {
    margin-right: -4rem !important;
  }

  .me-md-n7 {
    margin-right: -6rem !important;
  }

  .me-md-n8 {
    margin-right: -8rem !important;
  }

  .me-md-n9 {
    margin-right: -10rem !important;
  }

  .me-md-n10 {
    margin-right: -12rem !important;
  }

  .me-md-n11 {
    margin-right: -14rem !important;
  }

  .me-md-n12 {
    margin-right: -16rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -16rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .ms-md-n6 {
    margin-left: -4rem !important;
  }

  .ms-md-n7 {
    margin-left: -6rem !important;
  }

  .ms-md-n8 {
    margin-left: -8rem !important;
  }

  .ms-md-n9 {
    margin-left: -10rem !important;
  }

  .ms-md-n10 {
    margin-left: -12rem !important;
  }

  .ms-md-n11 {
    margin-left: -14rem !important;
  }

  .ms-md-n12 {
    margin-left: -16rem !important;
  }

  .p-admin-md-0 {
    padding: 0 !important;
  }

  .p-admin-md-1 {
    padding: 0.25rem !important;
  }

  .p-admin-md-2 {
    padding: 0.5rem !important;
  }

  .p-admin-md-3 {
    padding: 1rem !important;
  }

  .p-admin-md-4 {
    padding: 1.5rem !important;
  }

  .p-admin-md-5 {
    padding: 3rem !important;
  }

  .p-admin-md-6 {
    padding: 4rem !important;
  }

  .p-admin-md-7 {
    padding: 6rem !important;
  }

  .p-admin-md-8 {
    padding: 8rem !important;
  }

  .p-admin-md-9 {
    padding: 10rem !important;
  }

  .p-admin-md-10 {
    padding: 12rem !important;
  }

  .p-admin-md-11 {
    padding: 14rem !important;
  }

  .p-admin-md-12 {
    padding: 16rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-md-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-md-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-md-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-md-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-md-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 4rem !important;
  }

  .pt-md-7 {
    padding-top: 6rem !important;
  }

  .pt-md-8 {
    padding-top: 8rem !important;
  }

  .pt-md-9 {
    padding-top: 10rem !important;
  }

  .pt-md-10 {
    padding-top: 12rem !important;
  }

  .pt-md-11 {
    padding-top: 14rem !important;
  }

  .pt-md-12 {
    padding-top: 16rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 4rem !important;
  }

  .pe-md-7 {
    padding-right: 6rem !important;
  }

  .pe-md-8 {
    padding-right: 8rem !important;
  }

  .pe-md-9 {
    padding-right: 10rem !important;
  }

  .pe-md-10 {
    padding-right: 12rem !important;
  }

  .pe-md-11 {
    padding-right: 14rem !important;
  }

  .pe-md-12 {
    padding-right: 16rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 4rem !important;
  }

  .pb-md-7 {
    padding-bottom: 6rem !important;
  }

  .pb-md-8 {
    padding-bottom: 8rem !important;
  }

  .pb-md-9 {
    padding-bottom: 10rem !important;
  }

  .pb-md-10 {
    padding-bottom: 12rem !important;
  }

  .pb-md-11 {
    padding-bottom: 14rem !important;
  }

  .pb-md-12 {
    padding-bottom: 16rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 4rem !important;
  }

  .ps-md-7 {
    padding-left: 6rem !important;
  }

  .ps-md-8 {
    padding-left: 8rem !important;
  }

  .ps-md-9 {
    padding-left: 10rem !important;
  }

  .ps-md-10 {
    padding-left: 12rem !important;
  }

  .ps-md-11 {
    padding-left: 14rem !important;
  }

  .ps-md-12 {
    padding-left: 16rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 4rem !important;
  }

  .gap-md-7 {
    gap: 6rem !important;
  }

  .gap-md-8 {
    gap: 8rem !important;
  }

  .gap-md-9 {
    gap: 10rem !important;
  }

  .gap-md-10 {
    gap: 12rem !important;
  }

  .gap-md-11 {
    gap: 14rem !important;
  }

  .gap-md-12 {
    gap: 16rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .transform-scale-md-5 {
    transform: scale(0.5) !important;
  }

  .transform-scale-md-6 {
    transform: scale(0.6) !important;
  }

  .transform-scale-md-7 {
    transform: scale(0.7) !important;
  }

  .transform-scale-md-8 {
    transform: scale(0.8) !important;
  }

  .transform-scale-md-9 {
    transform: scale(0.9) !important;
  }

  .transform-scale-md-10 {
    transform: scale(1) !important;
  }

  .border-radius-top-start-md {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-md-0 {
    border-top-left-radius: 0 !important;
  }

  .border-radius-top-start-md-sm {
    border-top-left-radius: 0.25rem !important;
  }

  .border-radius-top-start-md-md {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-md-lg {
    border-top-left-radius: 0.75rem !important;
  }

  .border-radius-top-start-md-xl {
    border-top-left-radius: 1rem !important;
  }

  .border-radius-top-start-md-2xl {
    border-top-left-radius: 1.5rem !important;
  }

  .border-radius-top-start-md-circle {
    border-top-left-radius: 50% !important;
  }

  .border-radius-top-start-md-pill {
    border-top-left-radius: 50rem !important;
  }

  .border-radius-top-end-md {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-md-0 {
    border-top-right-radius: 0 !important;
  }

  .border-radius-top-end-md-sm {
    border-top-right-radius: 0.25rem !important;
  }

  .border-radius-top-end-md-md {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-md-lg {
    border-top-right-radius: 0.75rem !important;
  }

  .border-radius-top-end-md-xl {
    border-top-right-radius: 1rem !important;
  }

  .border-radius-top-end-md-2xl {
    border-top-right-radius: 1.5rem !important;
  }

  .border-radius-top-end-md-circle {
    border-top-right-radius: 50% !important;
  }

  .border-radius-top-end-md-pill {
    border-top-right-radius: 50rem !important;
  }

  .border-radius-bottom-start-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-md-0 {
    border-bottom-left-radius: 0 !important;
  }

  .border-radius-bottom-start-md-sm {
    border-bottom-left-radius: 0.25rem !important;
  }

  .border-radius-bottom-start-md-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-md-lg {
    border-bottom-left-radius: 0.75rem !important;
  }

  .border-radius-bottom-start-md-xl {
    border-bottom-left-radius: 1rem !important;
  }

  .border-radius-bottom-start-md-2xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .border-radius-bottom-start-md-circle {
    border-bottom-left-radius: 50% !important;
  }

  .border-radius-bottom-start-md-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .border-radius-bottom-end-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-md-0 {
    border-bottom-right-radius: 0 !important;
  }

  .border-radius-bottom-end-md-sm {
    border-bottom-right-radius: 0.25rem !important;
  }

  .border-radius-bottom-end-md-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-md-lg {
    border-bottom-right-radius: 0.75rem !important;
  }

  .border-radius-bottom-end-md-xl {
    border-bottom-right-radius: 1rem !important;
  }

  .border-radius-bottom-end-md-2xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .border-radius-bottom-end-md-circle {
    border-bottom-right-radius: 50% !important;
  }

  .border-radius-bottom-end-md-pill {
    border-bottom-right-radius: 50rem !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .border-top-lg {
    border-top: 1px solid #dee2e6 !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .border-end-lg {
    border-right: 1px solid #dee2e6 !important;
  }

  .border-end-lg-0 {
    border-right: 0 !important;
  }

  .border-bottom-lg {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  .border-start-lg {
    border-left: 1px solid #dee2e6 !important;
  }

  .border-start-lg-0 {
    border-left: 0 !important;
  }

  .w-admin-lg-0 {
    width: 0% !important;
  }

  .w-admin-lg-1 {
    width: 1% !important;
  }

  .w-admin-lg-2 {
    width: 2% !important;
  }

  .w-admin-lg-3 {
    width: 3% !important;
  }

  .w-admin-lg-4 {
    width: 4% !important;
  }

  .w-admin-lg-5 {
    width: 5% !important;
  }

  .w-admin-lg-6 {
    width: 6% !important;
  }

  .w-admin-lg-7 {
    width: 7% !important;
  }

  .w-admin-lg-8 {
    width: 8% !important;
  }

  .w-admin-lg-9 {
    width: 9% !important;
  }

  .w-admin-lg-10 {
    width: 10% !important;
  }

  .w-admin-lg-15 {
    width: 15% !important;
  }

  .w-admin-lg-20 {
    width: 20% !important;
  }

  .w-admin-lg-25 {
    width: 25% !important;
  }

  .w-admin-lg-30 {
    width: 30% !important;
  }

  .w-admin-lg-35 {
    width: 35% !important;
  }

  .w-admin-lg-40 {
    width: 40% !important;
  }

  .w-admin-lg-45 {
    width: 45% !important;
  }

  .w-admin-lg-50 {
    width: 50% !important;
  }

  .w-admin-lg-55 {
    width: 55% !important;
  }

  .w-admin-lg-60 {
    width: 60% !important;
  }

  .w-admin-lg-65 {
    width: 65% !important;
  }

  .w-admin-lg-70 {
    width: 70% !important;
  }

  .w-admin-lg-75 {
    width: 75% !important;
  }

  .w-admin-lg-80 {
    width: 80% !important;
  }

  .w-admin-lg-85 {
    width: 85% !important;
  }

  .w-admin-lg-90 {
    width: 90% !important;
  }

  .w-admin-lg-95 {
    width: 95% !important;
  }

  .w-admin-lg-100 {
    width: 100% !important;
  }

  .w-admin-lg-auto {
    width: auto !important;
  }

  .flex-admin-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-admin-lg-row {
    flex-direction: row !important;
  }

  .flex-admin-lg-column {
    flex-direction: column !important;
  }

  .flex-admin-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-admin-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-admin-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-admin-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-admin-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-admin-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-admin-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-admin-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-admin-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-admincontent-lg-start {
    justify-content: flex-start !important;
  }

  .justify-admincontent-lg-end {
    justify-content: flex-end !important;
  }

  .justify-admincontent-lg-center {
    justify-content: center !important;
  }

  .justify-admincontent-lg-between {
    justify-content: space-between !important;
  }

  .justify-admincontent-lg-around {
    justify-content: space-around !important;
  }

  .justify-admincontent-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-adminitems-lg-start {
    align-items: flex-start !important;
  }

  .align-adminitems-lg-end {
    align-items: flex-end !important;
  }

  .align-adminitems-lg-center {
    align-items: center !important;
  }

  .align-adminitems-lg-baseline {
    align-items: baseline !important;
  }

  .align-adminitems-lg-stretch {
    align-items: stretch !important;
  }

  .align-admincontent-lg-start {
    align-content: flex-start !important;
  }

  .align-admincontent-lg-end {
    align-content: flex-end !important;
  }

  .align-admincontent-lg-center {
    align-content: center !important;
  }

  .align-admincontent-lg-between {
    align-content: space-between !important;
  }

  .align-admincontent-lg-around {
    align-content: space-around !important;
  }

  .align-admincontent-lg-stretch {
    align-content: stretch !important;
  }

  .align-adminself-lg-auto {
    align-self: auto !important;
  }

  .align-adminself-lg-start {
    align-self: flex-start !important;
  }

  .align-adminself-lg-end {
    align-self: flex-end !important;
  }

  .align-adminself-lg-center {
    align-self: center !important;
  }

  .align-adminself-lg-baseline {
    align-self: baseline !important;
  }

  .align-adminself-lg-stretch {
    align-self: stretch !important;
  }

  .order-admin-lg-first {
    order: -1 !important;
  }

  .order-admin-lg-0 {
    order: 0 !important;
  }

  .order-admin-lg-1 {
    order: 1 !important;
  }

  .order-admin-lg-2 {
    order: 2 !important;
  }

  .order-admin-lg-3 {
    order: 3 !important;
  }

  .order-admin-lg-4 {
    order: 4 !important;
  }

  .order-admin-lg-5 {
    order: 5 !important;
  }

  .order-admin-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 4rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .m-lg-8 {
    margin: 8rem !important;
  }

  .m-lg-9 {
    margin: 10rem !important;
  }

  .m-lg-10 {
    margin: 12rem !important;
  }

  .m-lg-11 {
    margin: 14rem !important;
  }

  .m-lg-12 {
    margin: 16rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-lg-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-lg-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-lg-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-lg-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 4rem !important;
  }

  .mt-lg-7 {
    margin-top: 6rem !important;
  }

  .mt-lg-8 {
    margin-top: 8rem !important;
  }

  .mt-lg-9 {
    margin-top: 10rem !important;
  }

  .mt-lg-10 {
    margin-top: 12rem !important;
  }

  .mt-lg-11 {
    margin-top: 14rem !important;
  }

  .mt-lg-12 {
    margin-top: 16rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 4rem !important;
  }

  .me-lg-7 {
    margin-right: 6rem !important;
  }

  .me-lg-8 {
    margin-right: 8rem !important;
  }

  .me-lg-9 {
    margin-right: 10rem !important;
  }

  .me-lg-10 {
    margin-right: 12rem !important;
  }

  .me-lg-11 {
    margin-right: 14rem !important;
  }

  .me-lg-12 {
    margin-right: 16rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 14rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 16rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 4rem !important;
  }

  .ms-lg-7 {
    margin-left: 6rem !important;
  }

  .ms-lg-8 {
    margin-left: 8rem !important;
  }

  .ms-lg-9 {
    margin-left: 10rem !important;
  }

  .ms-lg-10 {
    margin-left: 12rem !important;
  }

  .ms-lg-11 {
    margin-left: 14rem !important;
  }

  .ms-lg-12 {
    margin-left: 16rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .m-lg-n6 {
    margin: -4rem !important;
  }

  .m-lg-n7 {
    margin: -6rem !important;
  }

  .m-lg-n8 {
    margin: -8rem !important;
  }

  .m-lg-n9 {
    margin: -10rem !important;
  }

  .m-lg-n10 {
    margin: -12rem !important;
  }

  .m-lg-n11 {
    margin: -14rem !important;
  }

  .m-lg-n12 {
    margin: -16rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-lg-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-lg-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-lg-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-lg-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-lg-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-lg-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-lg-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .mt-lg-n6 {
    margin-top: -4rem !important;
  }

  .mt-lg-n7 {
    margin-top: -6rem !important;
  }

  .mt-lg-n8 {
    margin-top: -8rem !important;
  }

  .mt-lg-n9 {
    margin-top: -10rem !important;
  }

  .mt-lg-n10 {
    margin-top: -12rem !important;
  }

  .mt-lg-n11 {
    margin-top: -14rem !important;
  }

  .mt-lg-n12 {
    margin-top: -16rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .me-lg-n6 {
    margin-right: -4rem !important;
  }

  .me-lg-n7 {
    margin-right: -6rem !important;
  }

  .me-lg-n8 {
    margin-right: -8rem !important;
  }

  .me-lg-n9 {
    margin-right: -10rem !important;
  }

  .me-lg-n10 {
    margin-right: -12rem !important;
  }

  .me-lg-n11 {
    margin-right: -14rem !important;
  }

  .me-lg-n12 {
    margin-right: -16rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -16rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .ms-lg-n6 {
    margin-left: -4rem !important;
  }

  .ms-lg-n7 {
    margin-left: -6rem !important;
  }

  .ms-lg-n8 {
    margin-left: -8rem !important;
  }

  .ms-lg-n9 {
    margin-left: -10rem !important;
  }

  .ms-lg-n10 {
    margin-left: -12rem !important;
  }

  .ms-lg-n11 {
    margin-left: -14rem !important;
  }

  .ms-lg-n12 {
    margin-left: -16rem !important;
  }

  .p-admin-lg-0 {
    padding: 0 !important;
  }

  .p-admin-lg-1 {
    padding: 0.25rem !important;
  }

  .p-admin-lg-2 {
    padding: 0.5rem !important;
  }

  .p-admin-lg-3 {
    padding: 1rem !important;
  }

  .p-admin-lg-4 {
    padding: 1.5rem !important;
  }

  .p-admin-lg-5 {
    padding: 3rem !important;
  }

  .p-admin-lg-6 {
    padding: 4rem !important;
  }

  .p-admin-lg-7 {
    padding: 6rem !important;
  }

  .p-admin-lg-8 {
    padding: 8rem !important;
  }

  .p-admin-lg-9 {
    padding: 10rem !important;
  }

  .p-admin-lg-10 {
    padding: 12rem !important;
  }

  .p-admin-lg-11 {
    padding: 14rem !important;
  }

  .p-admin-lg-12 {
    padding: 16rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-lg-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-lg-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-lg-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-lg-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-lg-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 4rem !important;
  }

  .pt-lg-7 {
    padding-top: 6rem !important;
  }

  .pt-lg-8 {
    padding-top: 8rem !important;
  }

  .pt-lg-9 {
    padding-top: 10rem !important;
  }

  .pt-lg-10 {
    padding-top: 12rem !important;
  }

  .pt-lg-11 {
    padding-top: 14rem !important;
  }

  .pt-lg-12 {
    padding-top: 16rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 4rem !important;
  }

  .pe-lg-7 {
    padding-right: 6rem !important;
  }

  .pe-lg-8 {
    padding-right: 8rem !important;
  }

  .pe-lg-9 {
    padding-right: 10rem !important;
  }

  .pe-lg-10 {
    padding-right: 12rem !important;
  }

  .pe-lg-11 {
    padding-right: 14rem !important;
  }

  .pe-lg-12 {
    padding-right: 16rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 12rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 14rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 16rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 4rem !important;
  }

  .ps-lg-7 {
    padding-left: 6rem !important;
  }

  .ps-lg-8 {
    padding-left: 8rem !important;
  }

  .ps-lg-9 {
    padding-left: 10rem !important;
  }

  .ps-lg-10 {
    padding-left: 12rem !important;
  }

  .ps-lg-11 {
    padding-left: 14rem !important;
  }

  .ps-lg-12 {
    padding-left: 16rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 4rem !important;
  }

  .gap-lg-7 {
    gap: 6rem !important;
  }

  .gap-lg-8 {
    gap: 8rem !important;
  }

  .gap-lg-9 {
    gap: 10rem !important;
  }

  .gap-lg-10 {
    gap: 12rem !important;
  }

  .gap-lg-11 {
    gap: 14rem !important;
  }

  .gap-lg-12 {
    gap: 16rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .transform-scale-lg-5 {
    transform: scale(0.5) !important;
  }

  .transform-scale-lg-6 {
    transform: scale(0.6) !important;
  }

  .transform-scale-lg-7 {
    transform: scale(0.7) !important;
  }

  .transform-scale-lg-8 {
    transform: scale(0.8) !important;
  }

  .transform-scale-lg-9 {
    transform: scale(0.9) !important;
  }

  .transform-scale-lg-10 {
    transform: scale(1) !important;
  }

  .border-radius-top-start-lg {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-lg-0 {
    border-top-left-radius: 0 !important;
  }

  .border-radius-top-start-lg-sm {
    border-top-left-radius: 0.25rem !important;
  }

  .border-radius-top-start-lg-md {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-lg-lg {
    border-top-left-radius: 0.75rem !important;
  }

  .border-radius-top-start-lg-xl {
    border-top-left-radius: 1rem !important;
  }

  .border-radius-top-start-lg-2xl {
    border-top-left-radius: 1.5rem !important;
  }

  .border-radius-top-start-lg-circle {
    border-top-left-radius: 50% !important;
  }

  .border-radius-top-start-lg-pill {
    border-top-left-radius: 50rem !important;
  }

  .border-radius-top-end-lg {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-lg-0 {
    border-top-right-radius: 0 !important;
  }

  .border-radius-top-end-lg-sm {
    border-top-right-radius: 0.25rem !important;
  }

  .border-radius-top-end-lg-md {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-lg-lg {
    border-top-right-radius: 0.75rem !important;
  }

  .border-radius-top-end-lg-xl {
    border-top-right-radius: 1rem !important;
  }

  .border-radius-top-end-lg-2xl {
    border-top-right-radius: 1.5rem !important;
  }

  .border-radius-top-end-lg-circle {
    border-top-right-radius: 50% !important;
  }

  .border-radius-top-end-lg-pill {
    border-top-right-radius: 50rem !important;
  }

  .border-radius-bottom-start-lg {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-lg-0 {
    border-bottom-left-radius: 0 !important;
  }

  .border-radius-bottom-start-lg-sm {
    border-bottom-left-radius: 0.25rem !important;
  }

  .border-radius-bottom-start-lg-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-lg-lg {
    border-bottom-left-radius: 0.75rem !important;
  }

  .border-radius-bottom-start-lg-xl {
    border-bottom-left-radius: 1rem !important;
  }

  .border-radius-bottom-start-lg-2xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .border-radius-bottom-start-lg-circle {
    border-bottom-left-radius: 50% !important;
  }

  .border-radius-bottom-start-lg-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .border-radius-bottom-end-lg {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-lg-0 {
    border-bottom-right-radius: 0 !important;
  }

  .border-radius-bottom-end-lg-sm {
    border-bottom-right-radius: 0.25rem !important;
  }

  .border-radius-bottom-end-lg-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-lg-lg {
    border-bottom-right-radius: 0.75rem !important;
  }

  .border-radius-bottom-end-lg-xl {
    border-bottom-right-radius: 1rem !important;
  }

  .border-radius-bottom-end-lg-2xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .border-radius-bottom-end-lg-circle {
    border-bottom-right-radius: 50% !important;
  }

  .border-radius-bottom-end-lg-pill {
    border-bottom-right-radius: 50rem !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .border-top-xl {
    border-top: 1px solid #dee2e6 !important;
  }

  .border-top-xl-0 {
    border-top: 0 !important;
  }

  .border-end-xl {
    border-right: 1px solid #dee2e6 !important;
  }

  .border-end-xl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xl {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xl {
    border-left: 1px solid #dee2e6 !important;
  }

  .border-start-xl-0 {
    border-left: 0 !important;
  }

  .w-admin-xl-0 {
    width: 0% !important;
  }

  .w-admin-xl-1 {
    width: 1% !important;
  }

  .w-admin-xl-2 {
    width: 2% !important;
  }

  .w-admin-xl-3 {
    width: 3% !important;
  }

  .w-admin-xl-4 {
    width: 4% !important;
  }

  .w-admin-xl-5 {
    width: 5% !important;
  }

  .w-admin-xl-6 {
    width: 6% !important;
  }

  .w-admin-xl-7 {
    width: 7% !important;
  }

  .w-admin-xl-8 {
    width: 8% !important;
  }

  .w-admin-xl-9 {
    width: 9% !important;
  }

  .w-admin-xl-10 {
    width: 10% !important;
  }

  .w-admin-xl-15 {
    width: 15% !important;
  }

  .w-admin-xl-20 {
    width: 20% !important;
  }

  .w-admin-xl-25 {
    width: 25% !important;
  }

  .w-admin-xl-30 {
    width: 30% !important;
  }

  .w-admin-xl-35 {
    width: 35% !important;
  }

  .w-admin-xl-40 {
    width: 40% !important;
  }

  .w-admin-xl-45 {
    width: 45% !important;
  }

  .w-admin-xl-50 {
    width: 50% !important;
  }

  .w-admin-xl-55 {
    width: 55% !important;
  }

  .w-admin-xl-60 {
    width: 60% !important;
  }

  .w-admin-xl-65 {
    width: 65% !important;
  }

  .w-admin-xl-70 {
    width: 70% !important;
  }

  .w-admin-xl-75 {
    width: 75% !important;
  }

  .w-admin-xl-80 {
    width: 80% !important;
  }

  .w-admin-xl-85 {
    width: 85% !important;
  }

  .w-admin-xl-90 {
    width: 90% !important;
  }

  .w-admin-xl-95 {
    width: 95% !important;
  }

  .w-admin-xl-100 {
    width: 100% !important;
  }

  .w-admin-xl-auto {
    width: auto !important;
  }

  .flex-admin-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-admin-xl-row {
    flex-direction: row !important;
  }

  .flex-admin-xl-column {
    flex-direction: column !important;
  }

  .flex-admin-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-admin-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-admin-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-admin-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-admin-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-admin-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-admin-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-admin-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-admin-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-admincontent-xl-start {
    justify-content: flex-start !important;
  }

  .justify-admincontent-xl-end {
    justify-content: flex-end !important;
  }

  .justify-admincontent-xl-center {
    justify-content: center !important;
  }

  .justify-admincontent-xl-between {
    justify-content: space-between !important;
  }

  .justify-admincontent-xl-around {
    justify-content: space-around !important;
  }

  .justify-admincontent-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-adminitems-xl-start {
    align-items: flex-start !important;
  }

  .align-adminitems-xl-end {
    align-items: flex-end !important;
  }

  .align-adminitems-xl-center {
    align-items: center !important;
  }

  .align-adminitems-xl-baseline {
    align-items: baseline !important;
  }

  .align-adminitems-xl-stretch {
    align-items: stretch !important;
  }

  .align-admincontent-xl-start {
    align-content: flex-start !important;
  }

  .align-admincontent-xl-end {
    align-content: flex-end !important;
  }

  .align-admincontent-xl-center {
    align-content: center !important;
  }

  .align-admincontent-xl-between {
    align-content: space-between !important;
  }

  .align-admincontent-xl-around {
    align-content: space-around !important;
  }

  .align-admincontent-xl-stretch {
    align-content: stretch !important;
  }

  .align-adminself-xl-auto {
    align-self: auto !important;
  }

  .align-adminself-xl-start {
    align-self: flex-start !important;
  }

  .align-adminself-xl-end {
    align-self: flex-end !important;
  }

  .align-adminself-xl-center {
    align-self: center !important;
  }

  .align-adminself-xl-baseline {
    align-self: baseline !important;
  }

  .align-adminself-xl-stretch {
    align-self: stretch !important;
  }

  .order-admin-xl-first {
    order: -1 !important;
  }

  .order-admin-xl-0 {
    order: 0 !important;
  }

  .order-admin-xl-1 {
    order: 1 !important;
  }

  .order-admin-xl-2 {
    order: 2 !important;
  }

  .order-admin-xl-3 {
    order: 3 !important;
  }

  .order-admin-xl-4 {
    order: 4 !important;
  }

  .order-admin-xl-5 {
    order: 5 !important;
  }

  .order-admin-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 4rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .m-xl-8 {
    margin: 8rem !important;
  }

  .m-xl-9 {
    margin: 10rem !important;
  }

  .m-xl-10 {
    margin: 12rem !important;
  }

  .m-xl-11 {
    margin: 14rem !important;
  }

  .m-xl-12 {
    margin: 16rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xl-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-xl-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xl-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xl-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 4rem !important;
  }

  .mt-xl-7 {
    margin-top: 6rem !important;
  }

  .mt-xl-8 {
    margin-top: 8rem !important;
  }

  .mt-xl-9 {
    margin-top: 10rem !important;
  }

  .mt-xl-10 {
    margin-top: 12rem !important;
  }

  .mt-xl-11 {
    margin-top: 14rem !important;
  }

  .mt-xl-12 {
    margin-top: 16rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 4rem !important;
  }

  .me-xl-7 {
    margin-right: 6rem !important;
  }

  .me-xl-8 {
    margin-right: 8rem !important;
  }

  .me-xl-9 {
    margin-right: 10rem !important;
  }

  .me-xl-10 {
    margin-right: 12rem !important;
  }

  .me-xl-11 {
    margin-right: 14rem !important;
  }

  .me-xl-12 {
    margin-right: 16rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 14rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 16rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 4rem !important;
  }

  .ms-xl-7 {
    margin-left: 6rem !important;
  }

  .ms-xl-8 {
    margin-left: 8rem !important;
  }

  .ms-xl-9 {
    margin-left: 10rem !important;
  }

  .ms-xl-10 {
    margin-left: 12rem !important;
  }

  .ms-xl-11 {
    margin-left: 14rem !important;
  }

  .ms-xl-12 {
    margin-left: 16rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .m-xl-n6 {
    margin: -4rem !important;
  }

  .m-xl-n7 {
    margin: -6rem !important;
  }

  .m-xl-n8 {
    margin: -8rem !important;
  }

  .m-xl-n9 {
    margin: -10rem !important;
  }

  .m-xl-n10 {
    margin: -12rem !important;
  }

  .m-xl-n11 {
    margin: -14rem !important;
  }

  .m-xl-n12 {
    margin: -16rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xl-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xl-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xl-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-xl-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-xl-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xl-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xl-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xl-n6 {
    margin-top: -4rem !important;
  }

  .mt-xl-n7 {
    margin-top: -6rem !important;
  }

  .mt-xl-n8 {
    margin-top: -8rem !important;
  }

  .mt-xl-n9 {
    margin-top: -10rem !important;
  }

  .mt-xl-n10 {
    margin-top: -12rem !important;
  }

  .mt-xl-n11 {
    margin-top: -14rem !important;
  }

  .mt-xl-n12 {
    margin-top: -16rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .me-xl-n6 {
    margin-right: -4rem !important;
  }

  .me-xl-n7 {
    margin-right: -6rem !important;
  }

  .me-xl-n8 {
    margin-right: -8rem !important;
  }

  .me-xl-n9 {
    margin-right: -10rem !important;
  }

  .me-xl-n10 {
    margin-right: -12rem !important;
  }

  .me-xl-n11 {
    margin-right: -14rem !important;
  }

  .me-xl-n12 {
    margin-right: -16rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -16rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xl-n6 {
    margin-left: -4rem !important;
  }

  .ms-xl-n7 {
    margin-left: -6rem !important;
  }

  .ms-xl-n8 {
    margin-left: -8rem !important;
  }

  .ms-xl-n9 {
    margin-left: -10rem !important;
  }

  .ms-xl-n10 {
    margin-left: -12rem !important;
  }

  .ms-xl-n11 {
    margin-left: -14rem !important;
  }

  .ms-xl-n12 {
    margin-left: -16rem !important;
  }

  .p-admin-xl-0 {
    padding: 0 !important;
  }

  .p-admin-xl-1 {
    padding: 0.25rem !important;
  }

  .p-admin-xl-2 {
    padding: 0.5rem !important;
  }

  .p-admin-xl-3 {
    padding: 1rem !important;
  }

  .p-admin-xl-4 {
    padding: 1.5rem !important;
  }

  .p-admin-xl-5 {
    padding: 3rem !important;
  }

  .p-admin-xl-6 {
    padding: 4rem !important;
  }

  .p-admin-xl-7 {
    padding: 6rem !important;
  }

  .p-admin-xl-8 {
    padding: 8rem !important;
  }

  .p-admin-xl-9 {
    padding: 10rem !important;
  }

  .p-admin-xl-10 {
    padding: 12rem !important;
  }

  .p-admin-xl-11 {
    padding: 14rem !important;
  }

  .p-admin-xl-12 {
    padding: 16rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xl-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-xl-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-xl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xl-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 4rem !important;
  }

  .pt-xl-7 {
    padding-top: 6rem !important;
  }

  .pt-xl-8 {
    padding-top: 8rem !important;
  }

  .pt-xl-9 {
    padding-top: 10rem !important;
  }

  .pt-xl-10 {
    padding-top: 12rem !important;
  }

  .pt-xl-11 {
    padding-top: 14rem !important;
  }

  .pt-xl-12 {
    padding-top: 16rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 4rem !important;
  }

  .pe-xl-7 {
    padding-right: 6rem !important;
  }

  .pe-xl-8 {
    padding-right: 8rem !important;
  }

  .pe-xl-9 {
    padding-right: 10rem !important;
  }

  .pe-xl-10 {
    padding-right: 12rem !important;
  }

  .pe-xl-11 {
    padding-right: 14rem !important;
  }

  .pe-xl-12 {
    padding-right: 16rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 12rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 14rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 16rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 4rem !important;
  }

  .ps-xl-7 {
    padding-left: 6rem !important;
  }

  .ps-xl-8 {
    padding-left: 8rem !important;
  }

  .ps-xl-9 {
    padding-left: 10rem !important;
  }

  .ps-xl-10 {
    padding-left: 12rem !important;
  }

  .ps-xl-11 {
    padding-left: 14rem !important;
  }

  .ps-xl-12 {
    padding-left: 16rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 4rem !important;
  }

  .gap-xl-7 {
    gap: 6rem !important;
  }

  .gap-xl-8 {
    gap: 8rem !important;
  }

  .gap-xl-9 {
    gap: 10rem !important;
  }

  .gap-xl-10 {
    gap: 12rem !important;
  }

  .gap-xl-11 {
    gap: 14rem !important;
  }

  .gap-xl-12 {
    gap: 16rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .transform-scale-xl-5 {
    transform: scale(0.5) !important;
  }

  .transform-scale-xl-6 {
    transform: scale(0.6) !important;
  }

  .transform-scale-xl-7 {
    transform: scale(0.7) !important;
  }

  .transform-scale-xl-8 {
    transform: scale(0.8) !important;
  }

  .transform-scale-xl-9 {
    transform: scale(0.9) !important;
  }

  .transform-scale-xl-10 {
    transform: scale(1) !important;
  }

  .border-radius-top-start-xl {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-xl-0 {
    border-top-left-radius: 0 !important;
  }

  .border-radius-top-start-xl-sm {
    border-top-left-radius: 0.25rem !important;
  }

  .border-radius-top-start-xl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-xl-lg {
    border-top-left-radius: 0.75rem !important;
  }

  .border-radius-top-start-xl-xl {
    border-top-left-radius: 1rem !important;
  }

  .border-radius-top-start-xl-2xl {
    border-top-left-radius: 1.5rem !important;
  }

  .border-radius-top-start-xl-circle {
    border-top-left-radius: 50% !important;
  }

  .border-radius-top-start-xl-pill {
    border-top-left-radius: 50rem !important;
  }

  .border-radius-top-end-xl {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-xl-0 {
    border-top-right-radius: 0 !important;
  }

  .border-radius-top-end-xl-sm {
    border-top-right-radius: 0.25rem !important;
  }

  .border-radius-top-end-xl-md {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-xl-lg {
    border-top-right-radius: 0.75rem !important;
  }

  .border-radius-top-end-xl-xl {
    border-top-right-radius: 1rem !important;
  }

  .border-radius-top-end-xl-2xl {
    border-top-right-radius: 1.5rem !important;
  }

  .border-radius-top-end-xl-circle {
    border-top-right-radius: 50% !important;
  }

  .border-radius-top-end-xl-pill {
    border-top-right-radius: 50rem !important;
  }

  .border-radius-bottom-start-xl {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-xl-0 {
    border-bottom-left-radius: 0 !important;
  }

  .border-radius-bottom-start-xl-sm {
    border-bottom-left-radius: 0.25rem !important;
  }

  .border-radius-bottom-start-xl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-xl-lg {
    border-bottom-left-radius: 0.75rem !important;
  }

  .border-radius-bottom-start-xl-xl {
    border-bottom-left-radius: 1rem !important;
  }

  .border-radius-bottom-start-xl-2xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .border-radius-bottom-start-xl-circle {
    border-bottom-left-radius: 50% !important;
  }

  .border-radius-bottom-start-xl-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .border-radius-bottom-end-xl {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-xl-0 {
    border-bottom-right-radius: 0 !important;
  }

  .border-radius-bottom-end-xl-sm {
    border-bottom-right-radius: 0.25rem !important;
  }

  .border-radius-bottom-end-xl-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-xl-lg {
    border-bottom-right-radius: 0.75rem !important;
  }

  .border-radius-bottom-end-xl-xl {
    border-bottom-right-radius: 1rem !important;
  }

  .border-radius-bottom-end-xl-2xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .border-radius-bottom-end-xl-circle {
    border-bottom-right-radius: 50% !important;
  }

  .border-radius-bottom-end-xl-pill {
    border-bottom-right-radius: 50rem !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .border-top-xxl {
    border-top: 1px solid #dee2e6 !important;
  }

  .border-top-xxl-0 {
    border-top: 0 !important;
  }

  .border-end-xxl {
    border-right: 1px solid #dee2e6 !important;
  }

  .border-end-xxl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xxl {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xxl {
    border-left: 1px solid #dee2e6 !important;
  }

  .border-start-xxl-0 {
    border-left: 0 !important;
  }

  .w-admin-xxl-0 {
    width: 0% !important;
  }

  .w-admin-xxl-1 {
    width: 1% !important;
  }

  .w-admin-xxl-2 {
    width: 2% !important;
  }

  .w-admin-xxl-3 {
    width: 3% !important;
  }

  .w-admin-xxl-4 {
    width: 4% !important;
  }

  .w-admin-xxl-5 {
    width: 5% !important;
  }

  .w-admin-xxl-6 {
    width: 6% !important;
  }

  .w-admin-xxl-7 {
    width: 7% !important;
  }

  .w-admin-xxl-8 {
    width: 8% !important;
  }

  .w-admin-xxl-9 {
    width: 9% !important;
  }

  .w-admin-xxl-10 {
    width: 10% !important;
  }

  .w-admin-xxl-15 {
    width: 15% !important;
  }

  .w-admin-xxl-20 {
    width: 20% !important;
  }

  .w-admin-xxl-25 {
    width: 25% !important;
  }

  .w-admin-xxl-30 {
    width: 30% !important;
  }

  .w-admin-xxl-35 {
    width: 35% !important;
  }

  .w-admin-xxl-40 {
    width: 40% !important;
  }

  .w-admin-xxl-45 {
    width: 45% !important;
  }

  .w-admin-xxl-50 {
    width: 50% !important;
  }

  .w-admin-xxl-55 {
    width: 55% !important;
  }

  .w-admin-xxl-60 {
    width: 60% !important;
  }

  .w-admin-xxl-65 {
    width: 65% !important;
  }

  .w-admin-xxl-70 {
    width: 70% !important;
  }

  .w-admin-xxl-75 {
    width: 75% !important;
  }

  .w-admin-xxl-80 {
    width: 80% !important;
  }

  .w-admin-xxl-85 {
    width: 85% !important;
  }

  .w-admin-xxl-90 {
    width: 90% !important;
  }

  .w-admin-xxl-95 {
    width: 95% !important;
  }

  .w-admin-xxl-100 {
    width: 100% !important;
  }

  .w-admin-xxl-auto {
    width: auto !important;
  }

  .flex-admin-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-admin-xxl-row {
    flex-direction: row !important;
  }

  .flex-admin-xxl-column {
    flex-direction: column !important;
  }

  .flex-admin-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-admin-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-admin-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-admin-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-admin-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-admin-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-admin-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-admin-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-admin-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-admincontent-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-admincontent-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-admincontent-xxl-center {
    justify-content: center !important;
  }

  .justify-admincontent-xxl-between {
    justify-content: space-between !important;
  }

  .justify-admincontent-xxl-around {
    justify-content: space-around !important;
  }

  .justify-admincontent-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-adminitems-xxl-start {
    align-items: flex-start !important;
  }

  .align-adminitems-xxl-end {
    align-items: flex-end !important;
  }

  .align-adminitems-xxl-center {
    align-items: center !important;
  }

  .align-adminitems-xxl-baseline {
    align-items: baseline !important;
  }

  .align-adminitems-xxl-stretch {
    align-items: stretch !important;
  }

  .align-admincontent-xxl-start {
    align-content: flex-start !important;
  }

  .align-admincontent-xxl-end {
    align-content: flex-end !important;
  }

  .align-admincontent-xxl-center {
    align-content: center !important;
  }

  .align-admincontent-xxl-between {
    align-content: space-between !important;
  }

  .align-admincontent-xxl-around {
    align-content: space-around !important;
  }

  .align-admincontent-xxl-stretch {
    align-content: stretch !important;
  }

  .align-adminself-xxl-auto {
    align-self: auto !important;
  }

  .align-adminself-xxl-start {
    align-self: flex-start !important;
  }

  .align-adminself-xxl-end {
    align-self: flex-end !important;
  }

  .align-adminself-xxl-center {
    align-self: center !important;
  }

  .align-adminself-xxl-baseline {
    align-self: baseline !important;
  }

  .align-adminself-xxl-stretch {
    align-self: stretch !important;
  }

  .order-admin-xxl-first {
    order: -1 !important;
  }

  .order-admin-xxl-0 {
    order: 0 !important;
  }

  .order-admin-xxl-1 {
    order: 1 !important;
  }

  .order-admin-xxl-2 {
    order: 2 !important;
  }

  .order-admin-xxl-3 {
    order: 3 !important;
  }

  .order-admin-xxl-4 {
    order: 4 !important;
  }

  .order-admin-xxl-5 {
    order: 5 !important;
  }

  .order-admin-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 4rem !important;
  }

  .m-xxl-7 {
    margin: 6rem !important;
  }

  .m-xxl-8 {
    margin: 8rem !important;
  }

  .m-xxl-9 {
    margin: 10rem !important;
  }

  .m-xxl-10 {
    margin: 12rem !important;
  }

  .m-xxl-11 {
    margin: 14rem !important;
  }

  .m-xxl-12 {
    margin: 16rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }

  .mx-xxl-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-xxl-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xxl-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xxl-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 4rem !important;
  }

  .mt-xxl-7 {
    margin-top: 6rem !important;
  }

  .mt-xxl-8 {
    margin-top: 8rem !important;
  }

  .mt-xxl-9 {
    margin-top: 10rem !important;
  }

  .mt-xxl-10 {
    margin-top: 12rem !important;
  }

  .mt-xxl-11 {
    margin-top: 14rem !important;
  }

  .mt-xxl-12 {
    margin-top: 16rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 4rem !important;
  }

  .me-xxl-7 {
    margin-right: 6rem !important;
  }

  .me-xxl-8 {
    margin-right: 8rem !important;
  }

  .me-xxl-9 {
    margin-right: 10rem !important;
  }

  .me-xxl-10 {
    margin-right: 12rem !important;
  }

  .me-xxl-11 {
    margin-right: 14rem !important;
  }

  .me-xxl-12 {
    margin-right: 16rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 14rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 16rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 4rem !important;
  }

  .ms-xxl-7 {
    margin-left: 6rem !important;
  }

  .ms-xxl-8 {
    margin-left: 8rem !important;
  }

  .ms-xxl-9 {
    margin-left: 10rem !important;
  }

  .ms-xxl-10 {
    margin-left: 12rem !important;
  }

  .ms-xxl-11 {
    margin-left: 14rem !important;
  }

  .ms-xxl-12 {
    margin-left: 16rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .m-xxl-n6 {
    margin: -4rem !important;
  }

  .m-xxl-n7 {
    margin: -6rem !important;
  }

  .m-xxl-n8 {
    margin: -8rem !important;
  }

  .m-xxl-n9 {
    margin: -10rem !important;
  }

  .m-xxl-n10 {
    margin: -12rem !important;
  }

  .m-xxl-n11 {
    margin: -14rem !important;
  }

  .m-xxl-n12 {
    margin: -16rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xxl-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xxl-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xxl-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -10rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -12rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -14rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -16rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .me-xxl-n6 {
    margin-right: -4rem !important;
  }

  .me-xxl-n7 {
    margin-right: -6rem !important;
  }

  .me-xxl-n8 {
    margin-right: -8rem !important;
  }

  .me-xxl-n9 {
    margin-right: -10rem !important;
  }

  .me-xxl-n10 {
    margin-right: -12rem !important;
  }

  .me-xxl-n11 {
    margin-right: -14rem !important;
  }

  .me-xxl-n12 {
    margin-right: -16rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -10rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -12rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -14rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -16rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -10rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -12rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -14rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -16rem !important;
  }

  .p-admin-xxl-0 {
    padding: 0 !important;
  }

  .p-admin-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-admin-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-admin-xxl-3 {
    padding: 1rem !important;
  }

  .p-admin-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-admin-xxl-5 {
    padding: 3rem !important;
  }

  .p-admin-xxl-6 {
    padding: 4rem !important;
  }

  .p-admin-xxl-7 {
    padding: 6rem !important;
  }

  .p-admin-xxl-8 {
    padding: 8rem !important;
  }

  .p-admin-xxl-9 {
    padding: 10rem !important;
  }

  .p-admin-xxl-10 {
    padding: 12rem !important;
  }

  .p-admin-xxl-11 {
    padding: 14rem !important;
  }

  .p-admin-xxl-12 {
    padding: 16rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xxl-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-xxl-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-xxl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xxl-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xxl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 4rem !important;
  }

  .pt-xxl-7 {
    padding-top: 6rem !important;
  }

  .pt-xxl-8 {
    padding-top: 8rem !important;
  }

  .pt-xxl-9 {
    padding-top: 10rem !important;
  }

  .pt-xxl-10 {
    padding-top: 12rem !important;
  }

  .pt-xxl-11 {
    padding-top: 14rem !important;
  }

  .pt-xxl-12 {
    padding-top: 16rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 4rem !important;
  }

  .pe-xxl-7 {
    padding-right: 6rem !important;
  }

  .pe-xxl-8 {
    padding-right: 8rem !important;
  }

  .pe-xxl-9 {
    padding-right: 10rem !important;
  }

  .pe-xxl-10 {
    padding-right: 12rem !important;
  }

  .pe-xxl-11 {
    padding-right: 14rem !important;
  }

  .pe-xxl-12 {
    padding-right: 16rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 12rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 14rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 16rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 4rem !important;
  }

  .ps-xxl-7 {
    padding-left: 6rem !important;
  }

  .ps-xxl-8 {
    padding-left: 8rem !important;
  }

  .ps-xxl-9 {
    padding-left: 10rem !important;
  }

  .ps-xxl-10 {
    padding-left: 12rem !important;
  }

  .ps-xxl-11 {
    padding-left: 14rem !important;
  }

  .ps-xxl-12 {
    padding-left: 16rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 4rem !important;
  }

  .gap-xxl-7 {
    gap: 6rem !important;
  }

  .gap-xxl-8 {
    gap: 8rem !important;
  }

  .gap-xxl-9 {
    gap: 10rem !important;
  }

  .gap-xxl-10 {
    gap: 12rem !important;
  }

  .gap-xxl-11 {
    gap: 14rem !important;
  }

  .gap-xxl-12 {
    gap: 16rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .transform-scale-xxl-5 {
    transform: scale(0.5) !important;
  }

  .transform-scale-xxl-6 {
    transform: scale(0.6) !important;
  }

  .transform-scale-xxl-7 {
    transform: scale(0.7) !important;
  }

  .transform-scale-xxl-8 {
    transform: scale(0.8) !important;
  }

  .transform-scale-xxl-9 {
    transform: scale(0.9) !important;
  }

  .transform-scale-xxl-10 {
    transform: scale(1) !important;
  }

  .border-radius-top-start-xxl {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-xxl-0 {
    border-top-left-radius: 0 !important;
  }

  .border-radius-top-start-xxl-sm {
    border-top-left-radius: 0.25rem !important;
  }

  .border-radius-top-start-xxl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .border-radius-top-start-xxl-lg {
    border-top-left-radius: 0.75rem !important;
  }

  .border-radius-top-start-xxl-xl {
    border-top-left-radius: 1rem !important;
  }

  .border-radius-top-start-xxl-2xl {
    border-top-left-radius: 1.5rem !important;
  }

  .border-radius-top-start-xxl-circle {
    border-top-left-radius: 50% !important;
  }

  .border-radius-top-start-xxl-pill {
    border-top-left-radius: 50rem !important;
  }

  .border-radius-top-end-xxl {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-xxl-0 {
    border-top-right-radius: 0 !important;
  }

  .border-radius-top-end-xxl-sm {
    border-top-right-radius: 0.25rem !important;
  }

  .border-radius-top-end-xxl-md {
    border-top-right-radius: 0.375rem !important;
  }

  .border-radius-top-end-xxl-lg {
    border-top-right-radius: 0.75rem !important;
  }

  .border-radius-top-end-xxl-xl {
    border-top-right-radius: 1rem !important;
  }

  .border-radius-top-end-xxl-2xl {
    border-top-right-radius: 1.5rem !important;
  }

  .border-radius-top-end-xxl-circle {
    border-top-right-radius: 50% !important;
  }

  .border-radius-top-end-xxl-pill {
    border-top-right-radius: 50rem !important;
  }

  .border-radius-bottom-start-xxl {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-xxl-0 {
    border-bottom-left-radius: 0 !important;
  }

  .border-radius-bottom-start-xxl-sm {
    border-bottom-left-radius: 0.25rem !important;
  }

  .border-radius-bottom-start-xxl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .border-radius-bottom-start-xxl-lg {
    border-bottom-left-radius: 0.75rem !important;
  }

  .border-radius-bottom-start-xxl-xl {
    border-bottom-left-radius: 1rem !important;
  }

  .border-radius-bottom-start-xxl-2xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .border-radius-bottom-start-xxl-circle {
    border-bottom-left-radius: 50% !important;
  }

  .border-radius-bottom-start-xxl-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .border-radius-bottom-end-xxl {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-xxl-0 {
    border-bottom-right-radius: 0 !important;
  }

  .border-radius-bottom-end-xxl-sm {
    border-bottom-right-radius: 0.25rem !important;
  }

  .border-radius-bottom-end-xxl-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .border-radius-bottom-end-xxl-lg {
    border-bottom-right-radius: 0.75rem !important;
  }

  .border-radius-bottom-end-xxl-xl {
    border-bottom-right-radius: 1rem !important;
  }

  .border-radius-bottom-end-xxl-2xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .border-radius-bottom-end-xxl-circle {
    border-bottom-right-radius: 50% !important;
  }

  .border-radius-bottom-end-xxl-pill {
    border-bottom-right-radius: 50rem !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3rem !important;
  }

  .fs-2 {
    font-size: 2.25rem !important;
  }

  .fs-3 {
    font-size: 1.875rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/*!

=========================================================
* Argon Dashboard 2 - v2.0.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (site.license)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.alert-primary {
  background-image: linear-gradient(310deg, #5e72e4 0%, #673bde 100%);
}

.alert-secondary {
  background-image: linear-gradient(310deg, #627594 0%, #8ca1cb 100%);
}

.alert-success {
  background-image: linear-gradient(310deg, #2dce89 0%, #26adab 100%);
}

.alert-info {
  background-image: linear-gradient(310deg, #1171ef 0%, #0eadca 100%);
}

.alert-warning {
  background-image: linear-gradient(310deg, #fb6340 0%, #faa118 100%);
}

.alert-danger {
  background-image: linear-gradient(310deg, #f5365c 0%, #f3410f 100%);
}

.alert-light {
  background-image: linear-gradient(310deg, #CED4DA 0%, #d1dae6 100%);
}

.alert-dark {
  background-image: linear-gradient(310deg, #212229 0%, #0f1112 100%);
}

.btnadmin-close:focus {
  box-shadow: none;
}

.alert-dismissible .btnadmin-close {
  background-image: none;
}

.alert {
  z-index: 999;
}

.avatar {
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 1rem;
  height: 48px;
  width: 48px;
  transition: all .2s ease-in-out;
}

.avatar img {
  width: 100%;
}

.avatar+.avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}

.avatar.avatar-raised {
  margin-top: -24px;
}

.avatar.avatar-scale-up:hover {
  transform: scale(1.2);
}

.active .avatar.avatar-scale-up {
  transform: scale(1.2);
}

.avatar-xxl {
  width: 110px !important;
  height: 110px !important;
}

.avatar-xxl.avatar-raised {
  margin-top: -55px;
}

.avatar-xl {
  width: 74px !important;
  height: 74px !important;
}

.avatar-xl.avatar-raised {
  margin-top: -37px;
}

.avatar-lg {
  width: 58px !important;
  height: 58px !important;
  font-size: 0.875rem;
}

.avatar-lg.avatar-raised {
  margin-top: -29px;
}

.avatar-sm {
  width: 36px !important;
  height: 36px !important;
  font-size: 0.875rem;
}

.avatar-sm.avatar-raised {
  margin-top: -18px;
}

.avatar-xs {
  width: 24px !important;
  height: 24px !important;
  font-size: 0.75rem;
}

.avatar-xs.avatar-raised {
  margin-top: -12px;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #fff;
}

.avatar-group .avatar:hover {
  z-index: 3;
}

.avatar-group .avatar+.avatar {
  margin-left: -1rem;
}

.badge.bg-primary {
  background: #5e72e4;
}

.badge.bg-secondary {
  background: #8392ab;
}

.badge.bg-success {
  background: #2dce89;
}

.badge.bg-info {
  background: #11cdef;
}

.badge.bg-warning {
  background: #fb6340;
}

.badge.bg-danger {
  background: #f5365c;
}

.badge.bg-light {
  background: #e9ecef;
}

.badge.bg-dark {
  background: #344767;
}

.badge.bg-white {
  background: #fff;
}

.badge {
  text-transform: uppercase;
}

.btnadmin {
  margin-bottom: 1rem;
  letter-spacing: -0.025rem;
  text-transform: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btnadmin:not([class*="btnadmin-outline-"]) {
  border: 0;
}

.btnadmin:active,
.btnadmin:active:focus,
.btnadmin:active:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.btnadmin:hover:not(.btnadmin-icon-only) {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.btnadmin.bg-white:hover {
  color: #67748e;
}

.btnadmin.btnadmin-link {
  box-shadow: none;
  font-weight: 700;
}

.btnadmin.btnadmin-link:hover,
.btnadmin.btnadmin-link:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-round {
  border-radius: 1.875rem;
}

.btnadmin.btnadmin-xs {
  padding: 0.375rem 1rem;
  font-size: 0.75rem;
}

.btnadmin.btnadmin-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.5rem 0.7rem;
}

.btnadmin.btnadmin-sm.btnadmin-icon-only,
.btnadmin-group-sm>.btnadmin.btnadmin-icon-only {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.3rem 0.3rem;
}

.btnadmin.btnadmin-sm i,
.btnadmin-group-sm>.btnadmin i {
  font-size: 0.7rem;
}

.btnadmin.btnadmin-lg.btnadmin-icon-only,
.btnadmin-group-lg>.btnadmin.btnadmin-icon-only {
  width: 3.25rem;
  height: 3.25rem;
  padding: 1rem 1rem;
}

.btnadmin.btnadmin-lg i,
.btnadmin-group-lg>.btnadmin i {
  font-size: 1.2rem;
  position: relative;
  top: 2px;
}

.btnadmin.btnadmin-rounded {
  border-radius: 1.875rem;
}

.btnadmin-check:checked+.btnadmin svg .color-background {
  fill: #fff;
}

.btnadmin-check:checked+.btnadmin:hover svg .color-background {
  fill: #344767;
}

.icon-move-right i {
  transition: all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3);
}

.icon-move-right:hover i,
.icon-move-right:focus i {
  transform: translateX(5px);
}

.icon-move-left i {
  transition: all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3);
}

.icon-move-left:hover i,
.icon-move-left:focus i {
  transform: translateX(-5px);
}

.btnadmin-primary:hover,
.btnadmin.bg-gradient-primary:hover {
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btnadmin-primary .btnadmin.bg-outline-primary,
.btnadmin.bg-gradient-primary .btnadmin.bg-outline-primary {
  border: 1px solid #5e72e4;
}

.btnadmin-primary:not(:disabled):not(.disabled).active,
.btnadmin-primary:not(:disabled):not(.disabled):active,
.show>.btnadmin-primary.dropdownadmin-toggle,
.btnadmin.bg-gradient-primary:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-primary:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-primary.dropdownadmin-toggle {
  color: color-yiq(#5e72e4);
  background-color: #5e72e4;
}

.btnadmin-primary.focus,
.btnadmin-primary:focus,
.btnadmin.bg-gradient-primary.focus,
.btnadmin.bg-gradient-primary:focus {
  color: #fff;
}

.btnadmin-outline-primary {
  box-shadow: none;
}

.btnadmin-outline-primary:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #5e72e4;
}

.btnadmin-secondary:hover,
.btnadmin.bg-gradient-secondary:hover {
  background-color: #8392ab;
  border-color: #8392ab;
}

.btnadmin-secondary .btnadmin.bg-outline-secondary,
.btnadmin.bg-gradient-secondary .btnadmin.bg-outline-secondary {
  border: 1px solid #8392ab;
}

.btnadmin-secondary:not(:disabled):not(.disabled).active,
.btnadmin-secondary:not(:disabled):not(.disabled):active,
.show>.btnadmin-secondary.dropdownadmin-toggle,
.btnadmin.bg-gradient-secondary:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-secondary:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-secondary.dropdownadmin-toggle {
  color: color-yiq(#8392ab);
  background-color: #8392ab;
}

.btnadmin-secondary.focus,
.btnadmin-secondary:focus,
.btnadmin.bg-gradient-secondary.focus,
.btnadmin.bg-gradient-secondary:focus {
  color: #fff;
}

.btnadmin-outline-secondary {
  box-shadow: none;
}

.btnadmin-outline-secondary:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #8392ab;
}

.btnadmin-success:hover,
.btnadmin.bg-gradient-success:hover {
  background-color: #2dce89;
  border-color: #2dce89;
}

.btnadmin-success .btnadmin.bg-outline-success,
.btnadmin.bg-gradient-success .btnadmin.bg-outline-success {
  border: 1px solid #2dce89;
}

.btnadmin-success:not(:disabled):not(.disabled).active,
.btnadmin-success:not(:disabled):not(.disabled):active,
.show>.btnadmin-success.dropdownadmin-toggle,
.btnadmin.bg-gradient-success:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-success:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-success.dropdownadmin-toggle {
  color: color-yiq(#2dce89);
  background-color: #2dce89;
}

.btnadmin-success.focus,
.btnadmin-success:focus,
.btnadmin.bg-gradient-success.focus,
.btnadmin.bg-gradient-success:focus {
  color: #fff;
}

.btnadmin-outline-success {
  box-shadow: none;
}

.btnadmin-outline-success:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #2dce89;
}

.btnadmin-info:hover,
.btnadmin.bg-gradient-info:hover {
  background-color: #11cdef;
  border-color: #11cdef;
}

.btnadmin-info .btnadmin.bg-outline-info,
.btnadmin.bg-gradient-info .btnadmin.bg-outline-info {
  border: 1px solid #11cdef;
}

.btnadmin-info:not(:disabled):not(.disabled).active,
.btnadmin-info:not(:disabled):not(.disabled):active,
.show>.btnadmin-info.dropdownadmin-toggle,
.btnadmin.bg-gradient-info:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-info:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-info.dropdownadmin-toggle {
  color: color-yiq(#11cdef);
  background-color: #11cdef;
}

.btnadmin-info.focus,
.btnadmin-info:focus,
.btnadmin.bg-gradient-info.focus,
.btnadmin.bg-gradient-info:focus {
  color: #fff;
}

.btnadmin-outline-info {
  box-shadow: none;
}

.btnadmin-outline-info:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #11cdef;
}

.btnadmin-warning:hover,
.btnadmin.bg-gradient-warning:hover {
  background-color: #fb6340;
  border-color: #fb6340;
}

.btnadmin-warning .btnadmin.bg-outline-warning,
.btnadmin.bg-gradient-warning .btnadmin.bg-outline-warning {
  border: 1px solid #fb6340;
}

.btnadmin-warning:not(:disabled):not(.disabled).active,
.btnadmin-warning:not(:disabled):not(.disabled):active,
.show>.btnadmin-warning.dropdownadmin-toggle,
.btnadmin.bg-gradient-warning:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-warning:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-warning.dropdownadmin-toggle {
  color: color-yiq(#fb6340);
  background-color: #fb6340;
}

.btnadmin-warning.focus,
.btnadmin-warning:focus,
.btnadmin.bg-gradient-warning.focus,
.btnadmin.bg-gradient-warning:focus {
  color: #fff;
}

.btnadmin-outline-warning {
  box-shadow: none;
}

.btnadmin-outline-warning:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #fb6340;
}

.btnadmin-danger:hover,
.btnadmin.bg-gradient-danger:hover {
  background-color: #f5365c;
  border-color: #f5365c;
}

.btnadmin-danger .btnadmin.bg-outline-danger,
.btnadmin.bg-gradient-danger .btnadmin.bg-outline-danger {
  border: 1px solid #f5365c;
}

.btnadmin-danger:not(:disabled):not(.disabled).active,
.btnadmin-danger:not(:disabled):not(.disabled):active,
.show>.btnadmin-danger.dropdownadmin-toggle,
.btnadmin.bg-gradient-danger:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-danger:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-danger.dropdownadmin-toggle {
  color: color-yiq(#f5365c);
  background-color: #f5365c;
}

.btnadmin-danger.focus,
.btnadmin-danger:focus,
.btnadmin.bg-gradient-danger.focus,
.btnadmin.bg-gradient-danger:focus {
  color: #fff;
}

.btnadmin-outline-danger {
  box-shadow: none;
}

.btnadmin-outline-danger:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #f5365c;
}

.btnadmin-light:hover,
.btnadmin.bg-gradient-light:hover {
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btnadmin-light .btnadmin.bg-outline-light,
.btnadmin.bg-gradient-light .btnadmin.bg-outline-light {
  border: 1px solid #e9ecef;
}

.btnadmin-light:not(:disabled):not(.disabled).active,
.btnadmin-light:not(:disabled):not(.disabled):active,
.show>.btnadmin-light.dropdownadmin-toggle,
.btnadmin.bg-gradient-light:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-light:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-light.dropdownadmin-toggle {
  color: color-yiq(#e9ecef);
  background-color: #e9ecef;
}

.btnadmin-outline-light {
  box-shadow: none;
}

.btnadmin-outline-light:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #e9ecef;
}

.btnadmin-dark:hover,
.btnadmin.bg-gradient-dark:hover {
  background-color: #344767;
  border-color: #344767;
}

.btnadmin-dark .btnadmin.bg-outline-dark,
.btnadmin.bg-gradient-dark .btnadmin.bg-outline-dark {
  border: 1px solid #344767;
}

.btnadmin-dark:not(:disabled):not(.disabled).active,
.btnadmin-dark:not(:disabled):not(.disabled):active,
.show>.btnadmin-dark.dropdownadmin-toggle,
.btnadmin.bg-gradient-dark:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-dark:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-dark.dropdownadmin-toggle {
  color: color-yiq(#344767);
  background-color: #344767;
}

.btnadmin-dark.focus,
.btnadmin-dark:focus,
.btnadmin.bg-gradient-dark.focus,
.btnadmin.bg-gradient-dark:focus {
  color: #fff;
}

.btnadmin-outline-dark {
  box-shadow: none;
}

.btnadmin-outline-dark:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #344767;
}

.btnadmin-white:hover,
.btnadmin.bg-gradient-white:hover {
  background-color: #fff;
  border-color: #fff;
}

.btnadmin-white .btnadmin.bg-outline-white,
.btnadmin.bg-gradient-white .btnadmin.bg-outline-white {
  border: 1px solid #fff;
}

.btnadmin-white:not(:disabled):not(.disabled).active,
.btnadmin-white:not(:disabled):not(.disabled):active,
.show>.btnadmin-white.dropdownadmin-toggle,
.btnadmin.bg-gradient-white:not(:disabled):not(.disabled).active,
.btnadmin.bg-gradient-white:not(:disabled):not(.disabled):active,
.show>.btnadmin.bg-gradient-white.dropdownadmin-toggle {
  color: color-yiq(#fff);
  background-color: #fff;
}

.btnadmin-outline-white {
  box-shadow: none;
}

.btnadmin-outline-white:hover:not(.active) {
  background-color: transparent;
  opacity: .75;
  box-shadow: none;
  color: #fff;
}

.btnadmin-outline-white {
  border-color: rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.1);
}

.btnadmin-primary,
.btnadmin.bg-gradient-primary {
  color: #fff;
}

.btnadmin-primary:hover,
.btnadmin.bg-gradient-primary:hover {
  color: #fff;
}

.btnadmin-secondary,
.btnadmin.bg-gradient-secondary {
  color: #fff;
}

.btnadmin-secondary:hover,
.btnadmin.bg-gradient-secondary:hover {
  color: #fff;
}

.btnadmin-danger,
.btnadmin.bg-gradient-danger {
  color: #fff;
}

.btnadmin-danger:hover,
.btnadmin.bg-gradient-danger:hover {
  color: #fff;
}

.btnadmin-info,
.btnadmin.bg-gradient-info {
  color: #fff;
}

.btnadmin-info:hover,
.btnadmin.bg-gradient-info:hover {
  color: #fff;
}

.btnadmin-success,
.btnadmin.bg-gradient-success {
  color: #fff;
}

.btnadmin-success:hover,
.btnadmin.bg-gradient-success:hover {
  color: #fff;
}

.btnadmin-warning,
.btnadmin.bg-gradient-warning {
  color: #fff;
}

.btnadmin-warning:hover,
.btnadmin.bg-gradient-warning:hover {
  color: #fff;
}

.btnadmin-dark,
.btnadmin.bg-gradient-dark {
  color: #fff;
}

.btnadmin-dark:hover,
.btnadmin.bg-gradient-dark:hover {
  color: #fff;
}

.btnadmin-light,
.btnadmin.bg-gradient-light {
  color: #3A416F;
}

.btnadmin-light:hover,
.btnadmin.bg-gradient-light:hover {
  color: #3A416F;
}

.breadcrumb-item {
  font-size: 0.875rem;
}

.breadcrumb-item.text-white::before {
  color: #fff;
}

.breadcrumb-dark {
  background-color: #344767;
}

.breadcrumb-dark .breadcrumb-item {
  font-weight: 600;
}

.breadcrumb-dark .breadcrumb-item a {
  color: #f8f9fa;
}

.breadcrumb-dark .breadcrumb-item a:hover {
  color: #fff;
}

.breadcrumb-dark .breadcrumb-item+.breadcrumb-item::before {
  color: #adb5bd;
}

.breadcrumb-dark .breadcrumb-item.active {
  color: #dee2e6;
}

.breadcrumb-links {
  padding: 0;
  margin: 0;
  background: transparent;
}

.card {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}

.card .card-header {
  padding: 1.5rem;
}

.card .card-body {
  font-family: "Open Sans", sans-serif;
  padding: 1.5rem;
}

.card.card-plain {
  background-color: transparent;
  box-shadow: none !important;
}

.card .card-footer {
  padding: 1.5rem;
  background-color: transparent;
}

.author {
  display: flex;
}

.author .name>span {
  line-height: 1.571;
  font-weight: 600;
  font-size: 0.875rem;
  color: #3A416F;
}

.author .stats {
  font-size: 0.875rem;
  font-weight: 400;
}

.card.card-background {
  align-items: center;
}

.card.card-background .full-background {
  background-position: 50%;
  background-size: cover;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 1rem;
}

.card.card-background .card-body {
  color: #fff;
  position: relative;
  z-index: 2;
}

.card.card-background .card-body .content-center,
.card.card-background .card-body .content-left {
  min-height: 330px;
  max-width: 450px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.card.card-background .card-body .content-center {
  text-align: center;
}

.card.card-background .card-body.body-left {
  width: 90%;
}

.card.card-background .card-body .author .name span,
.card.card-background .card-body .author .name .stats {
  color: #fff;
}

.card.card-background:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: block;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
}

.card.card-background.card-background-mask-primary:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-primary:after {
  background-image: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-secondary:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-secondary:after {
  background-image: linear-gradient(310deg, #627594 0%, #a8b8d8 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-success:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-success:after {
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-info:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-info:after {
  background-image: linear-gradient(310deg, #1171ef 0%, #11cdef 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-warning:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-warning:after {
  background-image: linear-gradient(310deg, #fb6340 0%, #fbb140 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-danger:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-danger:after {
  background-image: linear-gradient(310deg, #f5365c 0%, #f56036 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-light:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-light:after {
  background-image: linear-gradient(310deg, #CED4DA 0%, #EBEFF4 100%);
  opacity: .85;
}

.card.card-background.card-background-mask-dark:before {
  background: rgba(0, 0, 0, 0.2);
}

.card.card-background.card-background-mask-dark:after {
  background-image: linear-gradient(310deg, #212229 0%, #212529 100%);
  opacity: .85;
}

.card.card-background .card-category {
  font-size: 0.875rem;
  font-weight: 600;
}

.card.card-background .card-description {
  margin-top: 24px;
  margin-bottom: 24px;
}

.card.card-carousel .carousel .carousel-control-prev {
  left: auto;
  right: 3rem;
}

.card.card-carousel .carousel .carousel-control-prev,
.card.card-carousel .carousel .carousel-control-next {
  bottom: auto;
  top: 2rem;
  z-index: 5;
}

.card.card-carousel .carousel .carousel-control-prev-icon,
.card.card-carousel .carousel .carousel-control-next-icon {
  width: 1rem;
  height: 1rem;
}

@media (min-width: 992px) {

  .dropdown .dropdownadmin-menu,
  .dropup .dropdownadmin-menu,
  .dropstart .dropdownadmin-menu,
  .dropend .dropdownadmin-menu {
    box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06);
    animation: hide-navbaradmin-dropdown 0.25s ease forwards;
    cursor: pointer;
    opacity: 0;
  }

  .dropdown .dropdownadmin-toggle:after,
  .dropup .dropdownadmin-toggle:after,
  .dropstart .dropdownadmin-toggle:after,
  .dropend .dropdownadmin-toggle:after {
    content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    border: none;
    vertical-align: middle;
    font-weight: 600;
  }

  .dropdown .dropdownadmin-toggle.show:after,
  .dropup .dropdownadmin-toggle.show:after,
  .dropstart .dropdownadmin-toggle.show:after,
  .dropend .dropdownadmin-toggle.show:after {
    transform: rotate(180deg);
  }

  .dropdown .dropdownadmin-toggle:after,
  .dropup .dropdownadmin-toggle:after,
  .dropstart .dropdownadmin-toggle:after,
  .dropend .dropdownadmin-toggle:after {
    transition: 0.3s ease;
  }

  .dropdown.dropdown-hover .dropdownadmin-menu,
  .dropdown .dropdownadmin-menu {
    display: block;
    opacity: 0;
    top: 0;
    pointer-events: none;
    animation: hide-navbaradmin-dropdown 0.25s ease forwards;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: animation, box-shadow;
  }

  .dropdown.dropdown-hover:hover>.dropdownadmin-menu .dropdown-hover:hover>.dropdownadmin-menu,
  .dropdown .dropdownadmin-menu.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    animation: show-navbaradmin-dropdown 0.25s ease forwards;
  }

  .dropdown.dropdown-hover:hover>.dropdownadmin-menu:before,
  .dropdown .dropdownadmin-menu.show:before {
    top: -20px;
  }

  .dropdown.dropdown-hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -24px;
    width: 100%;
    height: 100%;
  }

  .dropdown:not(.dropdown-hover) .dropdownadmin-menu {
    margin-top: 40px !important;
  }

  .dropdown .dropdownadmin-menu:before {
    font-family: "FontAwesome";
    content: "\f0d8";
    position: absolute;
    top: 0;
    left: 28px;
    right: auto;
    font-size: 22px;
    color: #fff;
    transition: top 0.35s ease;
  }

  .dropdown .dropdown-item .arrow {
    transform: rotate(-90deg);
  }

  .dropdown-item {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
}

@media (max-width: 991.98px) {
  .navbaradmin-toggler+.navbaradmin-collapse .dropdown:not(.nav-item) .dropdownadmin-menu {
    display: block;
    opacity: 0;
    top: 0;
    transform-origin: 50% 0;
    pointer-events: none;
    transform: perspective(999px) rotateX(-10deg) translateZ(0) translate3d(0px, 37px, 0px) !important;
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, box-shadow;
    box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06);
  }

  .navbaradmin-toggler+.navbaradmin-collapse .dropdown:not(.nav-item) .dropdownadmin-menu:before {
    font-family: "FontAwesome";
    content: "\f0d8";
    position: absolute;
    top: 0;
    left: 28px;
    right: auto;
    font-size: 22px;
    color: #fff;
    transition: top 0.35s ease;
  }

  .navbaradmin-toggler+.navbaradmin-collapse .dropdown:not(.nav-item):not(.dropdown-hover) .dropdownadmin-menu {
    margin-top: 40px !important;
  }

  .navbaradmin-toggler+.navbaradmin-collapse .dropdown:not(.nav-item) .dropdownadmin-menu.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 37px, 5px) !important;
  }

  .navbaradmin-toggler+.navbaradmin-collapse .dropdown:not(.nav-item) .dropdownadmin-menu.show:before {
    top: -20px;
  }

  .navbaradmin-toggler+.navbaradmin-collapse .dropdown.nav-item .dropdownadmin-menu {
    background-color: transparent;
    overflow: scroll;
    position: relative;
  }

  .dropdown .dropdownadmin-menu {
    opacity: 0;
    top: 0;
    pointer-events: none;
    animation: hide-navbaradmin-dropdown 0.25s ease forwards;
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: animation, box-shadow;
    box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06);
  }

  .dropdown .dropdownadmin-menu:before {
    font-family: "FontAwesome";
    content: "\f0d8";
    position: absolute;
    top: 0;
    left: 28px;
    right: auto;
    font-size: 22px;
    color: #fff;
    transition: top 0.35s ease;
  }

  .dropdown:not(.dropdown-hover) .dropdownadmin-menu {
    margin-top: 40px !important;
  }

  .dropdown .dropdownadmin-menu.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    animation: show-navbaradmin-dropdown 0.25s ease forwards;
  }

  .dropdown .dropdownadmin-menu.show:before {
    top: -20px;
  }

  .dropdown.nav-item .dropdownadmin-menu {
    position: absolute;
  }

  .dropdown.nav-item .dropdownadmin-menu-animation {
    display: block;
    height: 0;
    transition: all .35s ease;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    opacity: 0;
  }

  .dropdown.nav-item .dropdownadmin-menu-animation.show {
    height: 250px;
    opacity: 1;
  }
}

.dropdownadmin-menu li {
  position: relative;
}

.dropdown.dropdown-subitem:after {
  left: 100%;
  bottom: 0;
  width: 50%;
}

.dropdown .dropdownadmin-menu .dropdown-item+.dropdownadmin-menu:before {
  transform: rotate(-90deg);
  left: 0;
  top: 0;
  z-index: -1;
  transition: left .35s ease;
}

.dropdown .dropdownadmin-menu.dropdownadmin-menu-end {
  right: 0 !important;
  left: auto !important;
}

.dropdown .dropdownadmin-menu.dropdownadmin-menu-end:before {
  right: 28px;
  left: auto;
}

.dropdown.dropdown-subitem:hover .dropdown-item+.dropdownadmin-menu:before {
  left: -8px;
}

.dropdown>.dropdownadmin-menu .dropdown-item+.dropdownadmin-menu {
  transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 0px, 5px) !important;
}

.dropdown .dropdownadmin-menu .dropdown-item+.dropdownadmin-menu {
  right: -197px;
  left: auto;
  top: 0;
}

.dropdown-image {
  background-size: cover;
}

@media (min-width: 992px) {
  .dropdown-xl {
    min-width: 40rem;
  }

  .dropdown-lg {
    min-width: 23rem;
  }

  .dropdown-md {
    min-width: 15rem;
  }
}

@media (max-width: 1199.98px) {
  .dropdown-lg-responsive {
    min-width: 19rem;
  }
}

@keyframes show-navbaradmin-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hide-navbaradmin-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}

.dropup .dropdownadmin-menu {
  box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06);
  transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  cursor: pointer;
  top: auto !important;
  bottom: 100% !important;
  margin-bottom: 0.5rem !important;
  display: block;
  opacity: 0;
  transform-origin: bottom;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, box-shadow;
  animation: hide-navbaradmin-dropdown 0.25s ease forwards;
}

.dropup .dropdownadmin-menu.show {
  pointer-events: auto;
  opacity: 1;
  animation: show-navbaradmin-dropdown 0.25s ease forwards;
}

.dropup .dropdownadmin-menu.show:after {
  bottom: -20px;
}

.dropup .dropdownadmin-menu:after {
  font-family: "FontAwesome";
  content: "\f0d7";
  position: absolute;
  z-index: -1;
  bottom: 22px;
  left: 28px;
  right: auto;
  font-size: 22px;
  color: #fff;
  transition: bottom 0.35s ease;
}

.page-header {
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
}

.page-header .container {
  z-index: 1;
}

.oblique {
  transform: skewX(-10deg);
  overflow: hidden;
  width: 60%;
  right: -10rem;
  border-bottom-left-radius: 0.75rem;
}

.oblique .oblique-image {
  transform: skewX(10deg);
}

.fixed-plugin .fixed-plugin-button {
  background: #fff;
  border-radius: 50%;
  bottom: 30px;
  right: 30px;
  font-size: 1.25rem;
  z-index: 990;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.fixed-plugin .fixed-plugin-button i {
  pointer-events: none;
}

.fixed-plugin .card {
  position: fixed !important;
  right: -360px;
  top: 0;
  height: 100%;
  left: auto !important;
  transform: unset !important;
  width: 360px;
  border-radius: 0;
  padding: 0 10px;
  transition: .2s ease;
  z-index: 1020;
}

.fixed-plugin .badge {
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  transition: all 0.2s ease-in-out;
}

.fixed-plugin .badge:hover,
.fixed-plugin .badge.active {
  border-color: #344767;
}

.fixed-plugin .btnadmin.bg-gradient-primary:not(:disabled):not(.disabled) {
  border: 1px solid transparent;
}

.fixed-plugin .btnadmin.bg-gradient-primary:not(:disabled):not(.disabled):not(.active) {
  background-color: transparent;
  background-image: none;
  border: 1px solid #5e72e4;
  color: #5e72e4;
}

.fixed-plugin.show .card {
  right: 0;
}

.input-group {
  border-radius: 0.5rem;
}

.input-group,
.input-group .input-group-text {
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.input-group> :not(:first-child):not(.dropdownadmin-menu) {
  margin-left: 0;
}

.input-group .form-admin-control:focus {
  border-left: 1px solid #5e72e4 !important;
  border-right: 1px solid #5e72e4 !important;
}

.input-group .form-admin-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}

.input-group .form-admin-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}

.input-group .form-admin-control+.input-group-text {
  border-left: 0;
  border-right: 1px solid #d2d6da;
}

.input-group .input-group-text {
  border-right: 0;
}

.input-group> :not(:first-child):not(.dropdownadmin-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: #fff !important;
}

.input-group.focused .input-group-text {
  border-color: #5e72e4 !important;
}

.form-admin-control.form-admin-control-alternative {
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.form-admin-control.form-admin-control-alternative:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.form-group {
  margin-bottom: 1rem;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"],
.form-check:not(.form-switch) .form-check-input[type="radio"] {
  border: 1px solid #cbd3da;
  margin-top: 0.25rem;
  position: relative;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked,
.form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
  border: 0;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:after {
  transition: opacity 0.25s ease-in-out;
  font-family: "FontAwesome";
  content: "\f00c";
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.67rem;
  opacity: 0;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked:after {
  opacity: 1;
}

.form-check:not(.form-switch) .form-check-input[type="radio"] {
  transition: border 0s;
}

.form-check:not(.form-switch) .form-check-input[type="radio"]:after {
  transition: opacity 0.25s ease-in-out;
  content: "";
  position: absolute;
  width: 0.4375rem;
  height: 0.4375rem;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
}

.form-check:not(.form-switch) .form-check-input[type="radio"]:checked {
  padding: 6px;
}

.form-check:not(.form-switch) .form-check-input[type="radio"]:checked:after {
  opacity: 1;
}

.form-check-label-admin,
.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.form-check-label-admin {
  font-size: 0.875rem;
  font-weight: 400;
}

.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-switch {
  display: flex;
  align-items: center;
}

.form-switch .form-check-input {
  border: 1px solid #e9ecef;
  position: relative;
  background-color: rgba(33, 37, 41, 0.1);
  height: 1.25em;
}

.form-switch .form-check-input:after {
  transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  transform: translateX(1px);
  box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
  top: 1px;
}

.form-switch .form-check-input:checked:after {
  transform: translateX(21px);
}

.form-switch .form-check-input:checked {
  border-color: rgba(94, 114, 228, 0.95);
  background-color: rgba(94, 114, 228, 0.95);
}

.form-switch .form-check-label-admin {
  margin-bottom: 0;
  margin-left: .5rem;
}

.form-admin-select {
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

label-admin,
.form-label-admin {
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #344767;
  margin-left: 0.25rem;
}

.form-admin-control.is-invalid:focus {
  box-shadow: 0 0 0 2px rgba(253, 92, 112, 0.6);
}

.form-admin-control.is-valid:focus {
  box-shadow: 0 0 0 2px rgba(102, 212, 50, 0.65);
}

.footer .nav-admin-link {
  color: #344767;
  font-weight: 400;
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0.25rem;
}

.footer .nav-admin-link:hover {
  opacity: 1 !important;
  transition: opacity 0.3 ease;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
}

.bg-gradient-secondary {
  background-image: linear-gradient(310deg, #627594 0%, #a8b8d8 100%);
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
}

.bg-gradient-info {
  background-image: linear-gradient(310deg, #1171ef 0%, #11cdef 100%);
}

.bg-gradient-warning {
  background-image: linear-gradient(310deg, #fb6340 0%, #fbb140 100%);
}

.bg-gradient-danger {
  background-image: linear-gradient(310deg, #f5365c 0%, #f56036 100%);
}

.bg-gradient-light {
  background-image: linear-gradient(310deg, #CED4DA 0%, #EBEFF4 100%);
}

.bg-gradient-dark {
  background-image: linear-gradient(310deg, #212229 0%, #212529 100%);
}

.bg-gradient-faded-primary {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(94, 114, 228, 0.6) 0, #324cdd 100%);
}

.bg-gradient-faded-secondary {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(131, 146, 171, 0.6) 0, #657796 100%);
}

.bg-gradient-faded-success {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(45, 206, 137, 0.6) 0, #24a46d 100%);
}

.bg-gradient-faded-info {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(17, 205, 239, 0.6) 0, #0da5c0 100%);
}

.bg-gradient-faded-warning {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(251, 99, 64, 0.6) 0, #fa3a0e 100%);
}

.bg-gradient-faded-danger {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(245, 54, 92, 0.6) 0, #ec0c38 100%);
}

.bg-gradient-faded-light {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(233, 236, 239, 0.6) 0, #cbd3da 100%);
}

.bg-gradient-faded-dark {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(52, 71, 103, 0.6) 0, #233045 100%);
}

.bg-gradient-faded-white {
  background-image: radial-gradient(370px circle at 80% 50%, rgba(255, 255, 255, 0.6) 0, #e6e6e6 100%);
}

.bg-gradient-faded-primary-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(94, 114, 228, 0.3) 0, #5e72e4 100%);
}

.bg-gradient-faded-secondary-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(131, 146, 171, 0.3) 0, #8392ab 100%);
}

.bg-gradient-faded-success-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(45, 206, 137, 0.3) 0, #2dce89 100%);
}

.bg-gradient-faded-info-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(17, 205, 239, 0.3) 0, #11cdef 100%);
}

.bg-gradient-faded-warning-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(251, 99, 64, 0.3) 0, #fb6340 100%);
}

.bg-gradient-faded-danger-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(245, 54, 92, 0.3) 0, #f5365c 100%);
}

.bg-gradient-faded-light-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(233, 236, 239, 0.3) 0, #e9ecef 100%);
}

.bg-gradient-faded-dark-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(52, 71, 103, 0.3) 0, #344767 100%);
}

.bg-gradient-faded-white-vertical {
  background-image: radial-gradient(200px circle at 50% 70%, rgba(255, 255, 255, 0.3) 0, #fff 100%);
}

.icon-shape {
  width: 48px;
  height: 48px;
  background-position: center;
  border-radius: 0.75rem;
}

.icon-shape i {
  color: #fff;
  opacity: 0.8;
  top: 14px;
  position: relative;
}

.icon-xxs {
  width: 20px;
  height: 20px;
}

.icon-xxs i {
  top: -5px;
  font-size: .5rem;
}

.icon-xs {
  width: 24px;
  height: 24px;
}

.icon-xs i {
  top: -3px;
  font-size: .5rem;
}

.icon-sm {
  width: 32px;
  height: 32px;
}

.icon-sm i {
  top: 3px;
  font-size: .65rem;
}

.icon-md {
  width: 48px;
  height: 48px;
}

.icon-md i {
  top: 12px;
  font-size: 0.875rem;
}

.icon-md.icon-striped {
  background-position-x: 85px;
  background-position-y: 85px;
}

.icon-md.icon-striped i {
  top: 11%;
  margin-left: -10px;
  font-size: 0.875rem;
}

.icon-lg {
  width: 64px;
  height: 64px;
}

.icon-lg i {
  top: 20px;
  font-size: 1.25rem;
}

.icon-lg.icon-striped {
  background-position-x: 111px;
  background-position-y: 111px;
}

.icon-lg.icon-striped i {
  top: 21%;
  margin-left: -15px;
}

.icon-xl {
  width: 100px;
  height: 100px;
  border-radius: 0.75rem;
}

.icon-xl i {
  top: 33px;
  font-size: 2.2rem;
}

.icon-xl.icon-striped {
  background-position-x: 80px;
  background-position-y: 80px;
}

.icon-xl.icon-striped i {
  top: 30%;
  margin-left: -15px;
}

.info-horizontal {
  text-align: left !important;
}

.info-horizontal .icon {
  float: left;
}

.info-horizontal .description {
  overflow: hidden;
}

svg.text-primary .color-foreground {
  fill: #5e72e4;
}

svg.text-primary .color-background {
  fill: #825ee4;
}

svg.text-secondary .color-foreground {
  fill: #627594;
}

svg.text-secondary .color-background {
  fill: #a8b8d8;
}

svg.text-info .color-foreground {
  fill: #1171ef;
}

svg.text-info .color-background {
  fill: #11cdef;
}

svg.text-warning .color-foreground {
  fill: #fb6340;
}

svg.text-warning .color-background {
  fill: #fbb140;
}

svg.text-danger .color-foreground {
  fill: #f5365c;
}

svg.text-danger .color-background {
  fill: #f56036;
}

svg.text-success .color-foreground {
  fill: #2dce89;
}

svg.text-success .color-background {
  fill: #2dcecc;
}

svg.text-dark .color-foreground {
  fill: #212229;
}

svg.text-dark .color-background {
  fill: #212529;
}

html {
  border: none !important;
}

.blur {
  box-shadow: inset 0px 0px 2px #fefefed1;
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.blur.saturation-less {
  -webkit-backdrop-filter: saturate(20%) blur(30px);
  backdrop-filter: saturate(20%) blur(30px);
}

.blur.blur-rounded {
  border-radius: 40px;
}

.blur.blur-light {
  background-color: rgba(255, 255, 255, 0.4);
}

.blur.blur-dark {
  background-color: rgba(0, 0, 0, 0.3);
}

.shadow-blur {
  box-shadow: inset 0 0px 1px 1px rgba(254, 254, 254, 0.9), 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow-card {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.navbaradmin-blur {
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.58) !important;
}

.blur-section {
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
}

.blur-section.blur-gradient-primary {
  background-image: linear-gradient(310deg, rgba(94, 114, 228, 0.95) 0%, rgba(130, 94, 228, 0.95) 100%);
}

*.move-on-hover {
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  overflow: hidden;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: perspective(999px) rotateX(0deg) translate3d(0, 0, 0);
  transform: perspective(999px) rotateX(0deg) translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, box-shadow;
}

*.move-on-hover:hover {
  -webkit-transform: perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px);
  transform: perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px);
}

*.gradient-animation {
  background: linear-gradient(-45deg, #1171ef, #f5365c, #fb6340, #5e72e4, #344767);
  background-size: 400% 400% !important;
  animation: gradient 10s ease infinite;
}

hr {
  border-top: none;
  height: 1px;
}

hr.vertical {
  position: absolute;
  background-color: transparent;
  height: 100%;
  right: 0;
  top: 0;
  width: 1px;
}

hr.vertical.light {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0));
}

hr.vertical.dark {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

hr.vertical.gray-light {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

hr.horizontal {
  background-color: transparent;
}

hr.horizontal.light {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0));
}

hr.horizontal.dark {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

hr.horizontal.gray-light {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.lock-size {
  width: 1.7rem;
  height: 1.7rem;
}

.border-radius-xs {
  border-radius: 0.125rem;
}

.border-radius-sm {
  border-radius: 0.25rem;
}

.border-radius-md {
  border-radius: 0.5rem;
}

.border-radius-lg {
  border-radius: 0.75rem;
}

.border-radius-xl {
  border-radius: 1rem;
}

.border-radius-2xl {
  border-radius: 1.5rem;
}

.border-radius-section {
  border-radius: 10rem;
}

.border-bottom-end-radius-0 {
  border-bottom-right-radius: 0;
}

.border-top-end-radius-0 {
  border-top-right-radius: 0;
}

.border-bottom-start-radius-0 {
  border-bottom-left-radius: 0;
}

.border-top-start-radius-0 {
  border-top-left-radius: 0;
}

.border-dashed {
  border-style: dashed;
}

.z-index-sticky {
  z-index: 1020;
}

.waves {
  position: relative;
  width: 100%;
  height: 16vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.waves.waves-sm {
  height: 50px;
  min-height: 50px;
}

.waves.no-animation .moving-waves>use {
  animation: none;
}

.wave-rotate {
  transform: rotate(180deg);
}

/* Animation for the waves */
.moving-waves>use {
  animation: move-forever 40s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.moving-waves>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 11s;
}

.moving-waves>use:nth-child(2) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.moving-waves>use:nth-child(3) {
  animation-delay: -3s;
  animation-duration: 15s;
}

.moving-waves>use:nth-child(4) {
  animation-delay: -4s;
  animation-duration: 20s;
}

.moving-waves>use:nth-child(5) {
  animation-delay: -4s;
  animation-duration: 25s;
}

.moving-waves>use:nth-child(6) {
  animation-delay: -3s;
  animation-duration: 30s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 767.98px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  hr.horizontal {
    background-color: transparent;
  }

  hr.horizontal:not(.dark) {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0));
  }

  hr.horizontal.vertical {
    transform: rotate(90deg);
  }

  hr.horizontal.dark {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  }
}

.overflow-visible {
  overflow: visible !important;
}

.popover .popover-header {
  font-weight: 600;
}

.bg-cover {
  background-size: cover;
}

.mask {
  position: absolute;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.cursor-pointer {
  cursor: pointer;
}

.transform-translate-50 {
  transform: translate(0, -50%);
}

@media (min-width: 992px) {
  .virtual-reality .navside {
    margin-top: 1.5rem;
    animation-name: fadeInBottom;
    animation-fill-mode: both;
    animation-duration: 1.5s;
    transform: scale(0.6);
    background: #fff;
    left: 18% !important;
    position: absolute;
  }
}

.separator {
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 150px;
  transform: translateZ(0);
  pointer-events: none;
}

.separator.separator-bottom {
  top: auto !important;
  bottom: 0 !important;
}

.separator.separator-top {
  top: 0 !important;
  bottom: auto !important;
}

.separator.separator-skew {
  height: 70px;
}

.separator .fill-default {
  fill: #172b4d;
}

.bg-default {
  background-color: #172b4d;
}

.card.card-profile-bottom {
  margin-top: 15rem;
}

.navbaradmin {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
}

.navbaradmin .navbaradmin-brand {
  color: #344767;
  font-size: 0.875rem;
}

.navbaradmin .nav-admin-link {
  color: #344767;
  padding: 0.5rem 1rem;
  font-weight: 400;
  font-size: 0.875rem;
}

.navbaradmin.navbaradmin-absolute {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.navbaradmin.navbaradmin-transparent .nav-admin-link,
.navbaradmin.navbaradmin-transparent .nav-admin-link i {
  color: #fff;
}

.navbaradmin.navbaradmin-transparent .nav-admin-link:hover,
.navbaradmin.navbaradmin-transparent .nav-admin-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbaradmin.navbaradmin-transparent .navbaradmin-toggler .navbaradmin-toggler-icon .navbaradmin-toggler-bar {
  background: #fff;
}

.navbaradmin.navbaradmin-transparent .navbaradmin-collapse {
  border-radius: 1rem;
}

.navbaradmin.navbaradmin-dark .navbaradmin-collapse.show .dropdown-header.text-dark,
.navbaradmin.navbaradmin-dark .navbaradmin-collapse.collapsing .dropdown-header.text-dark {
  color: #fff !important;
}

.navbaradmin .toggler-inner {
  width: 18px;
}

.navbaradmin .navside-toggler-inner .navside-toggler-line {
  transition: all 0.15s ease;
  background: #67748e;
  border-radius: 0.125rem;
  position: relative;
  display: block;
  height: 2px;
}

.navbaradmin .navside-toggler-inner .navside-toggler-line:not(:last-child) {
  margin-bottom: 3px;
}

.g-navside-show.g-navside-pinned .navbaradmin .navside-toggler-inner .navside-toggler-line:first-child,
.g-navside-show.g-navside-pinned .navbaradmin .navside-toggler-inner .navside-toggler-line:last-child {
  width: 13px;
  transform: translateX(5px);
}

.navbaradmin-light {
  background-color: #fff !important;
}

.navbaradmin-light .navbaradmin-toggler {
  border: none;
}

.navbaradmin-light .navbaradmin-toggler:focus {
  box-shadow: none;
}

.navbaradmin-toggler .navbaradmin-toggler-icon {
  background-image: none;
}

.navbaradmin-toggler .navbaradmin-toggler-icon .navbaradmin-toggler-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: #6c757d;
  transition: all 0.2s;
  margin: 0 auto;
}

.navbaradmin-toggler .navbaradmin-toggler-icon .navbaradmin-toggler-bar.bar2,
.navbaradmin-toggler .navbaradmin-toggler-icon .navbaradmin-toggler-bar.bar3 {
  margin-top: 7px;
}

.navbaradmin-toggler[aria-expanded="true"] .navbaradmin-toggler-bar.bar1 {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  margin-top: 4px;
}

.navbaradmin-toggler[aria-expanded="true"] .navbaradmin-toggler-bar.bar2 {
  opacity: 0;
}

.navbaradmin-toggler[aria-expanded="true"] .navbaradmin-toggler-bar.bar3 {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  margin-top: 3px;
}

@media (min-width: 992px) {
  :not(.main-content) .navbaradmin .dropdown .dropdownadmin-menu {
    top: 2.25rem !important;
  }

  :not(.main-content) .navbaradmin .dropdown .dropdownadmin-menu .dropdown .dropdownadmin-menu {
    top: 0 !important;
  }
}

@media (max-width: 991.98px) {
  .navbaradmin.navbaradmin-transparent .navbaradmin-collapse {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .navbaradmin.navbaradmin-transparent .navbaradmin-collapse.collapsing {
    background: #fff;
  }

  .navbaradmin.navbaradmin-transparent .navbaradmin-collapse.show {
    background: #fff;
  }

  .navbaradmin.navbaradmin-transparent .navbaradmin-collapse.show .nav-admin-link,
  .navbaradmin.navbaradmin-transparent .navbaradmin-collapse.show i {
    color: #344767;
  }

  .g-navside-show .navbaradmin:not(.navside).navbaradmin-main .navbaradmin-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .g-navside-show .navbaradmin:not(.navside).navbaradmin-main .navbaradmin-nav {
    flex-direction: row;
  }

  .navbaradmin.navbaradmin-transparent .navbaradmin-collapse {
    box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06);
  }
}

@media (max-width: 767.98px) {
  .navbaradmin-collapse {
    position: relative;
  }

  .navbaradmin-collapse .navbaradmin-nav {
    width: 100%;
  }

  .navbaradmin-collapse .navbaradmin-nav .nav-item.dropdown {
    position: static;
  }

  .navbaradmin-collapse .navbaradmin-nav .nav-item.dropdown .dropdownadmin-menu {
    left: 0;
    right: 0;
  }

  .navbaradmin-collapse .navbaradmin-nav .nav-item.dropdown .dropdownadmin-menu.show:before {
    content: none;
  }
}

@media (max-width: 575.98px) {
  .navbaradmin-nav .nav-item.dropdown .dropdownadmin-menu {
    left: 0;
    right: auto;
  }


}

@use "sass:math";

.navbaradmin-vertical .navbaradmin-brand>img,
.navbaradmin-vertical .navbaradmin-brand-img {
  max-width: 100%;
  max-height: 2rem;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link {
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
  color: #67748e;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link>i {
  min-width: 1.8rem;
  font-size: 0.9375rem;
  line-height: 1.5rem;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link .dropdownadmin-menu {
  border: none;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link .dropdownadmin-menu .dropdownadmin-menu {
  margin-left: 0.5rem;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link .icon {
  padding: 10px;
}

.navbaradmin-vertical .navbaradmin-nav .nav-sm .nav-admin-link {
  font-size: 0.8125rem;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.navbaradmin-vertical .navbaradmin-heading {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}


.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-collapse {
  display: block;
  /* overflow: auto; */
  height: calc(100vh - 360px);
}

.navbaradmin-vertical.navbaradmin-expand-xs>[class*="container"] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .navbaradmin-vertical.navbaradmin-expand-xs>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

.navbaradmin-vertical.navbaradmin-expand-xs.fixed-start {
  left: 0;
}

.navbaradmin-vertical.navbaradmin-expand-xs.fixed-end {
  right: 0;
}

.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav .nav-admin-link {
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
  margin: 0 0.5rem;
}

.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav .nav-admin-link .nav-admin-link-text,
.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav .nav-admin-link .navside-mini-icon,
.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav .nav-admin-link .navside-normal,
.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav .nav-admin-link i {
  pointer-events: none;
}

.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav .nav-item {
  width: 100%;
}

.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav>.nav-item {
  margin-top: 0.125rem;
}

.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav>.nav-item .icon .ni {
  top: 0;
}

.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-background {
  fill: #212529;
}

.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-foreground {
  fill: #212229;
}

.navbaradmin-vertical.navbaradmin-expand-xs .lavalamp-object {
  width: calc(100% - 1rem) !important;
  background: theme-color("primary");
  color: color-yiq(#5e72e4);
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
}


.navbaradmin-vertical.navbaradmin-expand-xs .navbaradmin-nav .nav-admin.nav-admin-link>span.navside-normal {
  transition: all 0.1s ease 0s;
}

@media (min-width: 576px) {
  .navbaradmin-vertical.navbaradmin-expand-sm {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    z-index: 9999;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 360px);
  }

  .navbaradmin-vertical.navbaradmin-expand-sm>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 576px) and (-ms-high-contrast: none),
(min-width: 576px) and (-ms-high-contrast: active) {
  .navbaradmin-vertical.navbaradmin-expand-sm>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 576px) {
  .navbaradmin-vertical.navbaradmin-expand-sm.fixed-start {
    left: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm.fixed-end {
    right: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav .nav-admin-link {
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    margin: 0 0.5rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav .nav-admin-link .nav-admin-link-text,
  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav .nav-admin-link .navside-mini-icon,
  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav .nav-admin-link .navside-normal,
  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav .nav-admin-link i {
    pointer-events: none;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav .nav-item {
    width: 100%;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-background {
    fill: #212529;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-foreground {
    fill: #212229;
  }

  .navbaradmin-vertical.navbaradmin-expand-sm .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#5e72e4);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem;
  }

 

  .navbaradmin-vertical.navbaradmin-expand-sm .navbaradmin-nav .nav-admin.nav-admin-link>span.navside-normal {
    transition: all 0.1s ease 0s;
  }
}


@media (min-width: 768px) {
  .navbaradmin-vertical.navbaradmin-expand-md {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    z-index: 9999;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 360px);
  }

  .navbaradmin-vertical.navbaradmin-expand-md>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 768px) and (-ms-high-contrast: none),
(min-width: 768px) and (-ms-high-contrast: active) {
  .navbaradmin-vertical.navbaradmin-expand-md>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .navbaradmin-vertical.navbaradmin-expand-md.fixed-start {
    left: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-md.fixed-end {
    right: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav .nav-admin-link {
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    margin: 0 0.5rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav .nav-admin-link .nav-admin-link-text,
  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav .nav-admin-link .navside-mini-icon,
  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav .nav-admin-link .navside-normal,
  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav .nav-admin-link i {
    pointer-events: none;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav .nav-item {
    width: 100%;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-background {
    fill: #212529;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-foreground {
    fill: #212229;
  }

  .navbaradmin-vertical.navbaradmin-expand-md .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#5e72e4);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem;
  }

 
  .navbaradmin-vertical.navbaradmin-expand-md .navbaradmin-nav .nav-admin.nav-admin-link>span.navside-normal {
    transition: all 0.1s ease 0s;
  }

}
@media (min-width: 992px) {
  .navbaradmin-vertical.navbaradmin-expand-lg {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    z-index: 9999;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 360px);
  }

  .navbaradmin-vertical.navbaradmin-expand-lg>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 992px) and (-ms-high-contrast: none),
(min-width: 992px) and (-ms-high-contrast: active) {
  .navbaradmin-vertical.navbaradmin-expand-lg>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 992px) {
  .navbaradmin-vertical.navbaradmin-expand-lg.fixed-start {
    left: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg.fixed-end {
    right: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav .nav-admin-link {
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    margin: 0 0.5rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav .nav-admin-link .nav-admin-link-text,
  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav .nav-admin-link .navside-mini-icon,
  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav .nav-admin-link .navside-normal,
  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav .nav-admin-link i {
    pointer-events: none;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav .nav-item {
    width: 100%;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-background {
    fill: #212529;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-foreground {
    fill: #212229;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#5e72e4);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-lg .navbaradmin-nav .nav-admin.nav-admin-link>span.navside-normal {
    transition: all 0.1s ease 0s;
  }
}

@media (min-width: 1200px) {
  .navbaradmin-vertical.navbaradmin-expand-xl {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    z-index: 9999;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 360px);
  }

  .navbaradmin-vertical.navbaradmin-expand-xl>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 1200px) and (-ms-high-contrast: none),
(min-width: 1200px) and (-ms-high-contrast: active) {
  .navbaradmin-vertical.navbaradmin-expand-xl>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .navbaradmin-vertical.navbaradmin-expand-xl.fixed-start {
    left: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl.fixed-end {
    right: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav .nav-admin-link {
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    margin: 0 0.5rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav .nav-admin-link .nav-admin-link-text,
  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav .nav-admin-link .navside-mini-icon,
  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav .nav-admin-link .navside-normal,
  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav .nav-admin-link i {
    pointer-events: none;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav .nav-item {
    width: 100%;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-background {
    fill: #212529;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-foreground {
    fill: #212229;
  }

  .navbaradmin-vertical.navbaradmin-expand-xl .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#5e72e4);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem;
  }

 

  .navbaradmin-vertical.navbaradmin-expand-xl .navbaradmin-nav .nav-admin.nav-admin-link>span.navside-normal {
    transition: all 0.1s ease 0s;
  }
}

@media (min-width: 1400px) {
  .navbaradmin-vertical.navbaradmin-expand-xxl {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem !important;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    z-index: 9999;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-collapse {
    display: block;
    overflow: auto;
    height: calc(100vh - 360px);
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl>[class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 1400px) and (-ms-high-contrast: none),
(min-width: 1400px) and (-ms-high-contrast: active) {
  .navbaradmin-vertical.navbaradmin-expand-xxl>[class*="container"] {
    min-height: none;
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .navbaradmin-vertical.navbaradmin-expand-xxl.fixed-start {
    left: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl.fixed-end {
    right: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav .nav-admin-link {
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    margin: 0 0.5rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav .nav-admin-link .nav-admin-link-text,
  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav .nav-admin-link .navside-mini-icon,
  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav .nav-admin-link .navside-normal,
  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav .nav-admin-link i {
    pointer-events: none;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav .nav-item {
    width: 100%;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav>.nav-item {
    margin-top: 0.125rem;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav>.nav-item .icon .ni {
    top: 0;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-background {
    fill: #212529;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav>.nav-item>.nav-admin-link .icon svg .color-foreground {
    fill: #212229;
  }

  .navbaradmin-vertical.navbaradmin-expand-xxl .lavalamp-object {
    width: calc(100% - 1rem) !important;
    background: theme-color("primary");
    color: color-yiq(#5e72e4);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem;
  }


  .navbaradmin-vertical.navbaradmin-expand-xxl .navbaradmin-nav .nav-admin.nav-admin-link>span.navside-normal {
    transition: all 0.1s ease 0s;
  }
}

.navside[data-color="primary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #5e72e4 0%, #5e72e4 100%);
}

.navside[data-color="primary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="primary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="primary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="secondary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #8392ab 0%, #8392ab 100%);
}

.navside[data-color="secondary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="secondary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="secondary"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="success"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #2dce89 0%, #2dce89 100%);
}

.navside[data-color="success"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="success"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="success"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="info"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #11cdef 0%, #11cdef 100%);
}

.navside[data-color="info"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="info"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="info"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="warning"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #fb6340 0%, #fb6340 100%);
}

.navside[data-color="warning"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="warning"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="warning"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="danger"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #f5365c 0%, #f5365c 100%);
}

.navside[data-color="danger"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="danger"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="danger"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="light"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #e9ecef 0%, #e9ecef 100%);
}

.navside[data-color="light"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="light"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="light"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="dark"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #344767 0%, #344767 100%);
}

.navside[data-color="dark"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="dark"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="dark"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.navside[data-color="white"] .navbaradmin-nav>.nav-item>.nav-admin-link.active {
  background-image: linear-gradient(310deg, #fff 0%, #fff 100%);
}

.navside[data-color="white"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .icon i,
.navside[data-color="white"] .navbaradmin-nav>.nav-item>.nav-admin-link.active .nav-admin-link-text {
  color: #fff !important;
}

.navside[data-color="white"] .navbaradmin-nav>.nav-item>.nav-admin-link.active:after {
  color: #fff;
}

.main-content,
.navside {
  transition: all 0.2s ease-in-out;
}

.navside {
  z-index: 999;
}

.navside .navbaradmin-brand,
.navside .navbaradmin-heading {
  display: block;
}

@media (min-width: 1200px) {
  .navside:hover {
    max-width: 15.625rem;
  }

  .navside .navside-toggler {
    padding: 1.5rem;
  }

  .navside.fixed-start+.main-content {
    margin-left: 17.125rem;
  }

  .navside.fixed-end+.main-content {
    margin-right: 17.125rem;
  }
}

.navside .navbaradmin-heading .docs-mini {
  padding-left: 3px;
}

.navside .navbaradmin-heading {
  transition: all 0.1s ease;
}

.navside .navbaradmin-brand {
  padding: 1.5rem 2rem;
}

.navside-header {
  height: 4.875rem;
}

.navside-footer .card.card-background:after {
  opacity: 0.65;
}

.g-navside-show .navside .nav-item .collapse {
  height: auto;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .g-navside-show .navside .nav-item .collapse {
    transition: none;
  }
}

.g-navside-show .navside .nav-admin-link-text {
  transition: 0.3s ease;
  opacity: 1;
}

@media (max-width: 1199.98px) {
  .g-navside-show.rtl .navside {
    transform: translateX(17.125rem);
  }

  .g-navside-show:not(.rtl) .navside {
    transform: translateX(-17.125rem);
  }

  .g-navside-show .navside.fixed-start+.main-content {
    margin-left: 0 !important;
  }

  .g-navside-show.g-navside-pinned .navside {
    transform: translateX(0);
  }
}

.navbaradmin-vertical.bg-white {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}

.navbaradmin-vertical.bg-white .navbaradmin-nav>.nav-item .nav-admin-link.active {
  background-color: #f6f9fc;
  box-shadow: none;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link.active {
  font-weight: 600;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.5rem;
}

.navbaradmin-vertical .navbaradmin-nav>.nav-item .nav-admin-link.active {
  color: #344767;
  background-color: rgba(255, 255, 255, 0.13);
}

.navbaradmin-main {
  transition: box-shadow 0.25s ease-in, background-color 0.25s ease-in;
}

.navbaradmin-main.fixed-top {
  width: calc(100% - (15.625rem + 1.5rem * 3));
}

.navbaradmin-main.fixed-top+[class*="container"] {
  margin-top: 7.1875rem !important;
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link[data-bs-toggle="collapse"]:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  content: "\f107";
  margin-left: auto;
  color: rgba(33, 37, 41, 0.5);
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbaradmin-vertical .navbaradmin-nav .nav-admin-link[data-bs-toggle="collapse"]:after {
    transition: none;
  }
}

.navbaradmin-vertical .navbaradmin-nav .nav-admin-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  color: #212529;
  transform: rotate(180deg);
}

.navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav,
.navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin{
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav,
  .navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin{
    transition: none;
  }
}

.navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item .nav-admin-link,
.navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item .nav-admin-link {
  position: relative;
  background-color: transparent;
  box-shadow: none;
  color: rgba(33, 37, 41, 0.5);
  margin-left: 1.35rem;
}

.navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item .nav-admin-link.active,
.navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item .nav-admin-link.active {
  color: #212529;
}

.navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item.active .nav-admin-link,
.navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item.active .nav-admin-link {
  color: #212529;
}

.navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item .nav-item .nav-admin-link:before,
.navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item .nav-item .nav-admin-link:before {
  content: none;
}

.navbaradmin-vertical.blur .navbaradmin-nav>.nav-item .nav-admin-link {
  background-color: transparent;
  box-shadow: none;
}

.navbaradmin-vertical .navbaradmin-brand .navbaradmin-brand-img,
.navbaradmin-vertical .navbaradmin-brand span {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .navbaradmin-vertical .navbaradmin-brand .navbaradmin-brand-img,
  .navbaradmin-vertical .navbaradmin-brand span {
    transition: none;
  }
}

.navbaradmin-vertical .nav-item .nav-admin-link span.navside-mini-icon {
  transition: all 0.2s ease-in-out;
  text-align: center;
  width: 0;
}

@media (prefers-reduced-motion: reduce) {
  .navbaradmin-vertical .nav-item .nav-admin-link span.navside-mini-icon {
    transition: none;
  }
}

.navbaradmin-vertical .docs-info {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbaradmin-vertical .docs-info {
    transition: none;
  }
}

.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item .nav-admin-link {
  color: #fff;
}

.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item .nav-admin-link[data-bs-toggle="collapse"]:after {
  color: #fff;
}

.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item .nav-admin-link,
.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item .nav-admin-link {
  color: #fff;
  opacity: .8;
}

.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item.active .nav-admin-link.active,
.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item.active .nav-admin-link.active {
  color: #fff;
  opacity: 1;
}

.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item h6,
.navbaradmin-vertical.bg-default .navbaradmin-nav .nav-item .h6 {
  color: #fff;
}

.navbaradmin-vertical.bg-default .navside-header .navbaradmin-brand {
  color: #fff;
}

.navbaradmin-vertical.bg-default .navside-footer h6,
.navbaradmin-vertical.bg-default .navside-footer .h6 {
  color: #fff;
}

.navbaradmin-vertical.bg-default hr {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0)) !important;
}

.g-navside-show:not(.g-navside-hidden) .navbaradmin-vertical .nav-item .nav-admin-link span.navside-mini-icon {
  opacity: 0;
}

.g-navside-hidden .navside-footer {
  display: none;
}

@media (min-width: 1200px) {

  .g-navside-hidden.rtl .main-content {
    margin-right: 6rem !important;
  }

  .g-navside-hidden.rtl .main-content .navbaradmin-vertical:hover {
    max-width: 15.625rem !important;
  }

  .g-navside-hidden.rtl .navside:hover+.main-content {
    margin-right: 17.125rem !important;
  }

  .g-navside-hidden .navbaradmin-vertical {
    max-width: 6rem !important;
  }

  .g-navside-hidden .navbaradmin-vertical.fixed-start+.main-content {
    margin-left: 7.5rem;
  }

  .g-navside-hidden .navbaradmin-vertical .navbaradmin-brand img {
    width: auto !important;
  }

  .g-navside-hidden .navbaradmin-vertical .navbaradmin-brand span {
    opacity: 0;
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .nav-admin-link .icon {
    padding: 10px;
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .nav-admin-link .nav-admin-link-text,
  .g-navside-hidden .navbaradmin-vertical .nav-item .nav-admin-link .navside-normal {
    opacity: 0;
    width: 0;
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .nav-admin-link .navside-mini-icon {
    min-width: 1.8rem;
    margin-left: 0.15rem;
    opacity: 1;
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .nav-admin-link[data-bs-toggle="collapse"]:after {
    content: "";
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .collapse .nav-admin{
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .collapse .nav-admin.nav-item .nav-admin-link {
    margin-left: 0.5rem;
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .collapse .nav-admin.nav-item .nav-admin-link:before {
    content: none;
  }

  .g-navside-hidden .navbaradmin-vertical .nav-item .collapse .nav-admin.nav-item .nav-admin-link[data-bs-toggle="collapse"]:after {
    content: "\f107";
  }

  .g-navside-hidden .navbaradmin-vertical .card.card-background .icon-shape {
    margin-bottom: 0 !important;
  }

  .g-navside-hidden .navbaradmin-vertical .card.card-background .docs-info {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .g-navside-hidden .navbaradmin-vertical:hover {
    max-width: 15.625rem !important;
  }

  .g-navside-hidden .navbaradmin-vertical:hover.fixed-start+.main-content {
    margin-left: 17.125rem;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .navbaradmin-brand span {
    opacity: 1;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .nav-admin-link .nav-admin-link-text,
  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .nav-admin-link .navside-normal {
    opacity: 1;
    width: auto;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .nav-admin-link .navside-mini-icon {
    opacity: 0;
    width: 0;
    min-width: 0;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .nav-admin-link[data-bs-toggle="collapse"]:after {
    content: "\f107";
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .collapse .nav-admin{
    margin-left: 1.5rem !important;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .collapse .nav-admin.nav-item .nav-admin-link {
    margin-left: 1.35rem;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .collapse .nav-admin.nav-item .nav-admin-link:before {
    content: "";
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .collapse .nav-admin.nav-item .collapse .nav,
  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .collapse .nav-admin.nav-item .collapsing .nav-admin{
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .collapse .nav-admin.nav-item .collapse .nav-admin.nav-item .nav-admin-link:before,
  .g-navside-hidden .navbaradmin-vertical:hover .nav-item .collapse .nav-admin.nav-item .collapsing .nav-admin.nav-item .nav-admin-link:before {
    content: none;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .card.card-background .icon-shape {
    margin-bottom: 1rem !important;
  }

  .g-navside-hidden .navbaradmin-vertical:hover .card.card-background .docs-info {
    opacity: 1;
    width: auto;
    height: auto;
  }
}

.nav.nav-pills {
  background: #f8f9fa;
  border-radius: 0.75rem;
  position: relative;
}

.nav.nav-pills.nav-pills-vertical {
  border-radius: 1.1875rem;
}

.nav.nav-pills.nav-pills-vertical .nav-admin-link.active {
  border-radius: 0.875rem;
}

.nav.nav-pills .nav-admin-link {
  z-index: 3;
  color: #344767;
  border-radius: 0.5rem;
  background-color: inherit;
}

.nav.nav-pills .nav-admin-link.active {
  animation: 0.2s ease;
}

.nav.nav-pills .nav-admin-link:hover:not(.active) {
  color: #344767;
}

.nav.nav-pills.nav-pills-primary {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-primary .nav-admin-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-primary .moving-tab .nav-admin-link.active {
  background: #5e72e4;
  color: #5e72e4;
}

.nav.nav-pills.nav-pills-info {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-info .nav-admin-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-info .moving-tab .nav-admin-link.active {
  background: #1171ef;
  color: #1171ef;
}

.nav.nav-pills.nav-pills-success {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-success .nav-admin-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-success .moving-tab .nav-admin-link.active {
  background: #2dce89;
  color: #2dce89;
}

.nav.nav-pills.nav-pills-warning {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-warning .nav-admin-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-warning .moving-tab .nav-admin-link.active {
  background: #fb6340;
  color: #fb6340;
}

.nav.nav-pills.nav-pills-danger {
  background: #fff;
  color: #fff;
}

.nav.nav-pills.nav-pills-danger .nav-admin-link.active {
  color: #fff;
}

.nav.nav-pills.nav-pills-danger .moving-tab .nav-admin-link.active {
  background: #f5365c;
  color: #f5365c;
}

.nav.nav-pills .nav-item {
  z-index: 3;
}

.moving-tab {
  z-index: 1 !important;
}

.moving-tab .nav-admin-link {
  color: #fff;
  transition: .2s ease;
  border-radius: 0.5rem;
}

.moving-tab .nav-admin-link.active {
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 1px 5px 1px #ddd;
  animation: 0.2s ease;
  background: #fff;
}

.moving-tab .nav-admin-link:hover:not(.active) {
  color: #344767;
}

.page-item.active .page-link {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.page-item .page-link,
.page-item span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8392ab;
  padding: 0;
  margin: 0 3px;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  font-size: 0.875rem;
}

.pagination-lg .page-item .page-link,
.pagination-lg .page-item span {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.pagination-sm .page-item .page-link,
.pagination-sm .page-item span {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.pagination.pagination-primary .page-item.active>.page-link,
.pagination.pagination-primary .page-item.active>.page-link:focus,
.pagination.pagination-primary .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
  border: none;
}

.pagination.pagination-secondary .page-item.active>.page-link,
.pagination.pagination-secondary .page-item.active>.page-link:focus,
.pagination.pagination-secondary .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #627594 0%, #a8b8d8 100%);
  border: none;
}

.pagination.pagination-success .page-item.active>.page-link,
.pagination.pagination-success .page-item.active>.page-link:focus,
.pagination.pagination-success .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
  border: none;
}

.pagination.pagination-info .page-item.active>.page-link,
.pagination.pagination-info .page-item.active>.page-link:focus,
.pagination.pagination-info .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #1171ef 0%, #11cdef 100%);
  border: none;
}

.pagination.pagination-warning .page-item.active>.page-link,
.pagination.pagination-warning .page-item.active>.page-link:focus,
.pagination.pagination-warning .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #fb6340 0%, #fbb140 100%);
  border: none;
}

.pagination.pagination-danger .page-item.active>.page-link,
.pagination.pagination-danger .page-item.active>.page-link:focus,
.pagination.pagination-danger .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #f5365c 0%, #f56036 100%);
  border: none;
}

.pagination.pagination-light .page-item.active>.page-link,
.pagination.pagination-light .page-item.active>.page-link:focus,
.pagination.pagination-light .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #CED4DA 0%, #EBEFF4 100%);
  border: none;
}

.pagination.pagination-dark .page-item.active>.page-link,
.pagination.pagination-dark .page-item.active>.page-link:focus,
.pagination.pagination-dark .page-item.active>.page-link:hover {
  background-image: linear-gradient(310deg, #212229 0%, #212529 100%);
  border: none;
}

.popover {
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
}

.popover .popover-header {
  font-weight: 600;
}

.progress-bar {
  height: auto;
  border-radius: 0.375rem;
}

.progress {
  overflow: visible;
}

.progress.progress-xs {
  height: 3px;
}

.progress.progress-sm {
  height: 4px;
}

.progress.progress-lg {
  height: 20px;
}

.rtl .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  float: right;
  padding-left: 0.5rem;
  padding-right: 0;
}

.rtl .navside .navbaradmin-nav {
  width: 100%;
  padding-right: 0;
}

.rtl .fixed-plugin .fixed-plugin-button {
  left: 30px;
  right: auto;
}

.rtl .fixed-plugin .card {
  left: -360px !important;
  right: auto;
}

.rtl .fixed-plugin.show .card {
  right: auto;
  left: 0 !important;
}

.rtl .timeline .timeline-content {
  margin-right: 45px;
  margin-left: 0;
}

.rtl .timeline .timeline-step {
  transform: translateX(50%);
}

.rtl .timeline.timeline-one-side:before {
  right: 1rem;
}

.rtl .timeline.timeline-one-side .timeline-step {
  right: 1rem;
}

.rtl .form-check.form-switch .form-check-input:after {
  transform: translateX(-1px);
}

.rtl .form-check.form-switch .form-check-input:checked:after {
  transform: translateX(-21px);
}

.rtl .avatar-group .avatar+.avatar {
  margin-left: 0;
  margin-right: -1rem;
}

.rtl .dropdown .dropdownadmin-menu {
  left: 0;
}

.rtl .input-group .input-group-text {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: 1px solid #d2d6da;
}

.rtl .input-group> :not(:first-child):not(.dropdownadmin-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-right: 0;
  border-left: 1px solid #d2d6da;
}

.rtl .input-group:not(.has-validation)>.dropdownadmin-toggle:nth-last-child(n + 3),
.rtl .input-group:not(.has-validation)> :not(:last-child):not(.dropdownadmin-toggle):not(.dropdownadmin-menu) {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.btnadmin.btnadmin-facebook {
  background-color: #3b5998;
  color: #fff;
}

.btnadmin.btnadmin-facebook:focus,
.btnadmin.btnadmin-facebook:hover {
  background-color: #344e86;
  color: #fff;
}

.btnadmin.btnadmin-facebook:active,
.btnadmin.btnadmin-facebook:focus,
.btnadmin.btnadmin-facebook:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-facebook.btnadmin-simple {
  color: #344e86;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-facebook.btnadmin-simple:hover,
.btnadmin.btnadmin-facebook.btnadmin-simple:focus,
.btnadmin.btnadmin-facebook.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-facebook.btnadmin-simple:active,
.btnadmin.btnadmin-facebook.btnadmin-simple:hover:focus:active {
  color: #344e86;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-facebook.btnadmin-neutral {
  color: #3b5998;
  background-color: #fff;
}

.btnadmin.btnadmin-facebook.btnadmin-neutral:hover,
.btnadmin.btnadmin-facebook.btnadmin-neutral:focus,
.btnadmin.btnadmin-facebook.btnadmin-neutral:active {
  color: #344e86;
}

.btnadmin.btnadmin-twitter {
  background-color: #55acee;
  color: #fff;
}

.btnadmin.btnadmin-twitter:focus,
.btnadmin.btnadmin-twitter:hover {
  background-color: #3ea1ec;
  color: #fff;
}

.btnadmin.btnadmin-twitter:active,
.btnadmin.btnadmin-twitter:focus,
.btnadmin.btnadmin-twitter:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-twitter.btnadmin-simple {
  color: #3ea1ec;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-twitter.btnadmin-simple:hover,
.btnadmin.btnadmin-twitter.btnadmin-simple:focus,
.btnadmin.btnadmin-twitter.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-twitter.btnadmin-simple:active,
.btnadmin.btnadmin-twitter.btnadmin-simple:hover:focus:active {
  color: #3ea1ec;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-twitter.btnadmin-neutral {
  color: #55acee;
  background-color: #fff;
}

.btnadmin.btnadmin-twitter.btnadmin-neutral:hover,
.btnadmin.btnadmin-twitter.btnadmin-neutral:focus,
.btnadmin.btnadmin-twitter.btnadmin-neutral:active {
  color: #3ea1ec;
}

.btnadmin.btnadmin-pinterest {
  background-color: #cc2127;
  color: #fff;
}

.btnadmin.btnadmin-pinterest:focus,
.btnadmin.btnadmin-pinterest:hover {
  background-color: #b21d22;
  color: #fff;
}

.btnadmin.btnadmin-pinterest:active,
.btnadmin.btnadmin-pinterest:focus,
.btnadmin.btnadmin-pinterest:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-pinterest.btnadmin-simple {
  color: #b21d22;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-pinterest.btnadmin-simple:hover,
.btnadmin.btnadmin-pinterest.btnadmin-simple:focus,
.btnadmin.btnadmin-pinterest.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-pinterest.btnadmin-simple:active,
.btnadmin.btnadmin-pinterest.btnadmin-simple:hover:focus:active {
  color: #b21d22;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-pinterest.btnadmin-neutral {
  color: #cc2127;
  background-color: #fff;
}

.btnadmin.btnadmin-pinterest.btnadmin-neutral:hover,
.btnadmin.btnadmin-pinterest.btnadmin-neutral:focus,
.btnadmin.btnadmin-pinterest.btnadmin-neutral:active {
  color: #b21d22;
}

.btnadmin.btnadmin-linkedin {
  background-color: #0077B5;
  color: #fff;
}

.btnadmin.btnadmin-linkedin:focus,
.btnadmin.btnadmin-linkedin:hover {
  background-color: #00669c;
  color: #fff;
}

.btnadmin.btnadmin-linkedin:active,
.btnadmin.btnadmin-linkedin:focus,
.btnadmin.btnadmin-linkedin:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-linkedin.btnadmin-simple {
  color: #00669c;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-linkedin.btnadmin-simple:hover,
.btnadmin.btnadmin-linkedin.btnadmin-simple:focus,
.btnadmin.btnadmin-linkedin.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-linkedin.btnadmin-simple:active,
.btnadmin.btnadmin-linkedin.btnadmin-simple:hover:focus:active {
  color: #00669c;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-linkedin.btnadmin-neutral {
  color: #0077B5;
  background-color: #fff;
}

.btnadmin.btnadmin-linkedin.btnadmin-neutral:hover,
.btnadmin.btnadmin-linkedin.btnadmin-neutral:focus,
.btnadmin.btnadmin-linkedin.btnadmin-neutral:active {
  color: #00669c;
}

.btnadmin.btnadmin-dribbble {
  background-color: #ea4c89;
  color: #fff;
}

.btnadmin.btnadmin-dribbble:focus,
.btnadmin.btnadmin-dribbble:hover {
  background-color: #e73177;
  color: #fff;
}

.btnadmin.btnadmin-dribbble:active,
.btnadmin.btnadmin-dribbble:focus,
.btnadmin.btnadmin-dribbble:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-dribbble.btnadmin-simple {
  color: #e73177;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-dribbble.btnadmin-simple:hover,
.btnadmin.btnadmin-dribbble.btnadmin-simple:focus,
.btnadmin.btnadmin-dribbble.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-dribbble.btnadmin-simple:active,
.btnadmin.btnadmin-dribbble.btnadmin-simple:hover:focus:active {
  color: #e73177;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-dribbble.btnadmin-neutral {
  color: #ea4c89;
  background-color: #fff;
}

.btnadmin.btnadmin-dribbble.btnadmin-neutral:hover,
.btnadmin.btnadmin-dribbble.btnadmin-neutral:focus,
.btnadmin.btnadmin-dribbble.btnadmin-neutral:active {
  color: #e73177;
}

.btnadmin.btnadmin-github {
  background-color: #24292E;
  color: #fff;
}

.btnadmin.btnadmin-github:focus,
.btnadmin.btnadmin-github:hover {
  background-color: #171a1d;
  color: #fff;
}

.btnadmin.btnadmin-github:active,
.btnadmin.btnadmin-github:focus,
.btnadmin.btnadmin-github:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-github.btnadmin-simple {
  color: #171a1d;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-github.btnadmin-simple:hover,
.btnadmin.btnadmin-github.btnadmin-simple:focus,
.btnadmin.btnadmin-github.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-github.btnadmin-simple:active,
.btnadmin.btnadmin-github.btnadmin-simple:hover:focus:active {
  color: #171a1d;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-github.btnadmin-neutral {
  color: #24292E;
  background-color: #fff;
}

.btnadmin.btnadmin-github.btnadmin-neutral:hover,
.btnadmin.btnadmin-github.btnadmin-neutral:focus,
.btnadmin.btnadmin-github.btnadmin-neutral:active {
  color: #171a1d;
}

.btnadmin.btnadmin-youtube {
  background-color: #e52d27;
  color: #fff;
}

.btnadmin.btnadmin-youtube:focus,
.btnadmin.btnadmin-youtube:hover {
  background-color: #d41f1a;
  color: #fff;
}

.btnadmin.btnadmin-youtube:active,
.btnadmin.btnadmin-youtube:focus,
.btnadmin.btnadmin-youtube:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-youtube.btnadmin-simple {
  color: #d41f1a;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-youtube.btnadmin-simple:hover,
.btnadmin.btnadmin-youtube.btnadmin-simple:focus,
.btnadmin.btnadmin-youtube.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-youtube.btnadmin-simple:active,
.btnadmin.btnadmin-youtube.btnadmin-simple:hover:focus:active {
  color: #d41f1a;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-youtube.btnadmin-neutral {
  color: #e52d27;
  background-color: #fff;
}

.btnadmin.btnadmin-youtube.btnadmin-neutral:hover,
.btnadmin.btnadmin-youtube.btnadmin-neutral:focus,
.btnadmin.btnadmin-youtube.btnadmin-neutral:active {
  color: #d41f1a;
}

.btnadmin.btnadmin-instagram {
  background-color: #125688;
  color: #fff;
}

.btnadmin.btnadmin-instagram:focus,
.btnadmin.btnadmin-instagram:hover {
  background-color: #0e456d;
  color: #fff;
}

.btnadmin.btnadmin-instagram:active,
.btnadmin.btnadmin-instagram:focus,
.btnadmin.btnadmin-instagram:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-instagram.btnadmin-simple {
  color: #0e456d;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-instagram.btnadmin-simple:hover,
.btnadmin.btnadmin-instagram.btnadmin-simple:focus,
.btnadmin.btnadmin-instagram.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-instagram.btnadmin-simple:active,
.btnadmin.btnadmin-instagram.btnadmin-simple:hover:focus:active {
  color: #0e456d;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-instagram.btnadmin-neutral {
  color: #125688;
  background-color: #fff;
}

.btnadmin.btnadmin-instagram.btnadmin-neutral:hover,
.btnadmin.btnadmin-instagram.btnadmin-neutral:focus,
.btnadmin.btnadmin-instagram.btnadmin-neutral:active {
  color: #0e456d;
}

.btnadmin.btnadmin-reddit {
  background-color: #ff4500;
  color: #fff;
}

.btnadmin.btnadmin-reddit:focus,
.btnadmin.btnadmin-reddit:hover {
  background-color: #e03d00;
  color: #fff;
}

.btnadmin.btnadmin-reddit:active,
.btnadmin.btnadmin-reddit:focus,
.btnadmin.btnadmin-reddit:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-reddit.btnadmin-simple {
  color: #e03d00;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-reddit.btnadmin-simple:hover,
.btnadmin.btnadmin-reddit.btnadmin-simple:focus,
.btnadmin.btnadmin-reddit.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-reddit.btnadmin-simple:active,
.btnadmin.btnadmin-reddit.btnadmin-simple:hover:focus:active {
  color: #e03d00;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-reddit.btnadmin-neutral {
  color: #ff4500;
  background-color: #fff;
}

.btnadmin.btnadmin-reddit.btnadmin-neutral:hover,
.btnadmin.btnadmin-reddit.btnadmin-neutral:focus,
.btnadmin.btnadmin-reddit.btnadmin-neutral:active {
  color: #e03d00;
}

.btnadmin.btnadmin-tumblr {
  background-color: #35465c;
  color: #fff;
}

.btnadmin.btnadmin-tumblr:focus,
.btnadmin.btnadmin-tumblr:hover {
  background-color: #2a3749;
  color: #fff;
}

.btnadmin.btnadmin-tumblr:active,
.btnadmin.btnadmin-tumblr:focus,
.btnadmin.btnadmin-tumblr:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-tumblr.btnadmin-simple {
  color: #2a3749;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-tumblr.btnadmin-simple:hover,
.btnadmin.btnadmin-tumblr.btnadmin-simple:focus,
.btnadmin.btnadmin-tumblr.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-tumblr.btnadmin-simple:active,
.btnadmin.btnadmin-tumblr.btnadmin-simple:hover:focus:active {
  color: #2a3749;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-tumblr.btnadmin-neutral {
  color: #35465c;
  background-color: #fff;
}

.btnadmin.btnadmin-tumblr.btnadmin-neutral:hover,
.btnadmin.btnadmin-tumblr.btnadmin-neutral:focus,
.btnadmin.btnadmin-tumblr.btnadmin-neutral:active {
  color: #2a3749;
}

.btnadmin.btnadmin-behance {
  background-color: #1769ff;
  color: #fff;
}

.btnadmin.btnadmin-behance:focus,
.btnadmin.btnadmin-behance:hover {
  background-color: #0057f7;
  color: #fff;
}

.btnadmin.btnadmin-behance:active,
.btnadmin.btnadmin-behance:focus,
.btnadmin.btnadmin-behance:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-behance.btnadmin-simple {
  color: #0057f7;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-behance.btnadmin-simple:hover,
.btnadmin.btnadmin-behance.btnadmin-simple:focus,
.btnadmin.btnadmin-behance.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-behance.btnadmin-simple:active,
.btnadmin.btnadmin-behance.btnadmin-simple:hover:focus:active {
  color: #0057f7;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-behance.btnadmin-neutral {
  color: #1769ff;
  background-color: #fff;
}

.btnadmin.btnadmin-behance.btnadmin-neutral:hover,
.btnadmin.btnadmin-behance.btnadmin-neutral:focus,
.btnadmin.btnadmin-behance.btnadmin-neutral:active {
  color: #0057f7;
}

.btnadmin.btnadmin-vimeo {
  background-color: #1AB7EA;
  color: #fff;
}

.btnadmin.btnadmin-vimeo:focus,
.btnadmin.btnadmin-vimeo:hover {
  background-color: #13a3d2;
  color: #fff;
}

.btnadmin.btnadmin-vimeo:active,
.btnadmin.btnadmin-vimeo:focus,
.btnadmin.btnadmin-vimeo:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-vimeo.btnadmin-simple {
  color: #13a3d2;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-vimeo.btnadmin-simple:hover,
.btnadmin.btnadmin-vimeo.btnadmin-simple:focus,
.btnadmin.btnadmin-vimeo.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-vimeo.btnadmin-simple:active,
.btnadmin.btnadmin-vimeo.btnadmin-simple:hover:focus:active {
  color: #13a3d2;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-vimeo.btnadmin-neutral {
  color: #1AB7EA;
  background-color: #fff;
}

.btnadmin.btnadmin-vimeo.btnadmin-neutral:hover,
.btnadmin.btnadmin-vimeo.btnadmin-neutral:focus,
.btnadmin.btnadmin-vimeo.btnadmin-neutral:active {
  color: #13a3d2;
}

.btnadmin.btnadmin-slack {
  background-color: #3aaf85;
  color: #fff;
}

.btnadmin.btnadmin-slack:focus,
.btnadmin.btnadmin-slack:hover {
  background-color: #329874;
  color: #fff;
}

.btnadmin.btnadmin-slack:active,
.btnadmin.btnadmin-slack:focus,
.btnadmin.btnadmin-slack:active:focus {
  box-shadow: none;
}

.btnadmin.btnadmin-slack.btnadmin-simple {
  color: #329874;
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
  border: none;
}

.btnadmin.btnadmin-slack.btnadmin-simple:hover,
.btnadmin.btnadmin-slack.btnadmin-simple:focus,
.btnadmin.btnadmin-slack.btnadmin-simple:hover:focus,
.btnadmin.btnadmin-slack.btnadmin-simple:active,
.btnadmin.btnadmin-slack.btnadmin-simple:hover:focus:active {
  color: #329874;
  background: transparent !important;
  box-shadow: none !important;
}

.btnadmin.btnadmin-slack.btnadmin-neutral {
  color: #3aaf85;
  background-color: #fff;
}

.btnadmin.btnadmin-slack.btnadmin-neutral:hover,
.btnadmin.btnadmin-slack.btnadmin-neutral:focus,
.btnadmin.btnadmin-slack.btnadmin-neutral:active {
  color: #329874;
}

.table thead th {
  padding: 0.75rem 1.5rem;
  text-transform: capitalize;
  letter-spacing: 0px;
  border-bottom: 1px solid #e9ecef;
}

.table th {
  font-weight: 600;
}

.table td .progress {
  height: 3px;
  width: 120px;
  margin: 0;
}

.table td,
.table th {
  white-space: nowrap;
}

.table.align-adminitems-center td,
.table.align-adminitems-center th {
  vertical-align: middle;
}

.table tbody tr:last-child td {
  border-width: 0;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: #e9ecef;
}

.table> :not(:first-child) {
  border-top: 1px solid currentColor;
}

.timeline {
  position: relative;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  border-right: 2px solid #dee2e6;
}

.timeline-block {
  position: relative;
}

.timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-block:first-child {
  margin-top: 0;
}

.timeline-block:last-child {
  margin-bottom: 0;
}

.timeline-step {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: 600;
  z-index: 1;
}

.timeline-step svg,
.timeline-step i {
  line-height: 1.4;
}

.timeline-content {
  position: relative;
  margin-left: 45px;
  padding-top: 0.35rem;
  position: relative;
  top: -6px;
}

.timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 992px) {
  .timeline:before {
    left: 50%;
    margin-left: -1px;
  }

  .timeline-step {
    left: 50%;
  }

  .timeline-content {
    width: 38%;
  }

  .timeline-block:nth-child(even) .timeline-content {
    float: right;
  }
}

.timeline-one-side:before {
  left: 1rem;
}

.timeline-one-side .timeline-step {
  left: 1rem;
}

.timeline-one-side .timeline-content {
  width: auto;
}

@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 30rem;
  }
}

.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
  float: none;
}

.tilt {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.tilt .up {
  -webkit-transform: translateZ(50px) scale(0.7);
  transform: translateZ(50px) scale(0.7) !important;
  transition: all 0.5s;
}

.bs-tooltip-auto[x-placement^=right] .tooltip-arrow,
.bs-tooltip-right .tooltip-arrow {
  left: 1px;
}

.bs-tooltip-auto[x-placement^=left] .tooltip-arrow,
.bs-tooltip-left .tooltip-arrow {
  right: 1px;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-weight: 400;
  line-height: 1.6;
}

h1,
.h1,
.h1 {
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: 0.025rem;
}

@media (max-width: 575.98px) {

  h1,
  .h1,
  .h1 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

h2,
.h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.3;
  letter-spacing: 0.05rem;
}

@media (max-width: 575.98px) {

  h2,
  .h2,
  .h2 {
    font-size: calc(1.35rem + 1.2vw);
  }
}

h3,
.h3,
.h3 {
  font-size: 1.875rem;
  line-height: 1.375;
}

@media (max-width: 575.98px) {

  h3,
  .h3,
  .h3 {
    font-size: calc(1.3125rem + 0.75vw);
  }
}

h4,
.h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.375;
}

@media (max-width: 575.98px) {

  h4,
  .h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5,
.h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.375;
}

@media (max-width: 575.98px) {

  h5,
  .h5,
  .h5 {
    font-size: 1.25rem;
  }
}

h6,
.h6,
.h6 {
  font-size: 1rem;
  line-height: 1.625;
}

p,
.p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3 {
  font-weight: 700;
}

h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  font-weight: 600;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4 {
  letter-spacing: -0.05rem;
}

a {
  letter-spacing: -0.025rem;
  color: #344767;
}

.text-sm {
  line-height: 1.5;
}

.text-xs {
  line-height: 1.25;
}

p,
.p {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-xxs {
  font-size: 0.65rem !important;
}

p {
  line-height: 1.625;
  font-weight: 400;
}

.text-sans-serif {
  font-family: "Open Sans", sans-serif !important;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
}

.text-gradient.text-primary {
  background-image: linear-gradient(310deg, #5e72e4, #825ee4);
}

.text-gradient.text-info {
  background-image: linear-gradient(310deg, #1171ef, #11cdef);
}

.text-gradient.text-success {
  background-image: linear-gradient(310deg, #2dce89, #2dcecc);
}

.text-gradient.text-warning {
  background-image: linear-gradient(310deg, #fb6340, #fbb140);
}

.text-gradient.text-danger {
  background-image: linear-gradient(310deg, #f5365c, #f56036);
}

/* .text-gradient.text-dark {
  background-image: linear-gradient(310deg, #212229, #212529);
} */

.blockquote {
  border-left: 3px solid #6c757d;
}

.blockquote>span {
  font-style: italic;
}

.text-muted {
  color: #67748e !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.letter-wider {
  letter-spacing: 0.05rem;
}

.letter-normal {
  letter-spacing: 0rem;
}

.letter-tighter {
  letter-spacing: -0.05rem;
}

.text-lighter {
  font-weight: lighter;
}

.text-light {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

.text-bold {
  font-weight: 600;
}

.text-bolder {
  font-weight: 700;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2rem;
}

.text-5xl {
  font-size: 2.25rem;
}

.text-6xl {
  font-size: 3rem;
}

.text-7xl {
  font-size: 3.75rem;
}

.text-8xl {
  font-size: 4rem;
}

.text-9xl {
  font-size: 5rem;
}

.dark-version {
  background-color: #051139 !important;
}

.dark-version>div[class*='bg-'] {
  background-color: #051139 !important;
}

.dark-version .navside {
  background: #111C44 !important;
}

.dark-version .navside.bg-transparent {
  background: transparent !important;
}

.dark-version .navside.bg-transparent .navbaradmin-nav .nav-admin-link {
  color: #fff !important;
}

.dark-version .navside.bg-transparent .nav-admin.nav-admin-link {
  color: #fff !important;
}

.dark-version .navside.bg-white {
  background: #fff !important;
}

.dark-version .navside.bg-white .navbaradmin-nav .nav-admin-link.active:after {
  color: rgba(206, 212, 218, 0.7);
}

.dark-version .navside.bg-white .collapse .nav-item .nav-admin-link:not(.active) i {
  color: #344767 !important;
}

.dark-version .navside.bg-white .collapse .nav-item h6,
.dark-version .navside.bg-white .collapse .nav-item .h6 {
  color: #344767 !important;
}

.dark-version .fixed-plugin .btnadmin.bg-gradient-dark,
.dark-version .fixed-plugin .btnadmin.btnadmin-outline-dark {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.dark-version .fixed-plugin .btnadmin.active {
  background: #fff !important;
  color: #344767 !important;
}

.dark-version .bg-gradient-dark {
  background-image: linear-gradient(195deg, #323a54, #1a2035);
}

.dark-version .card,
.dark-version .swal2-popup,
.dark-version .dropdown .dropdownadmin-menu,
.dark-version .kanban-board {
  background: #111C44;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dark-version .card .card-header,
.dark-version .swal2-popup .card-header,
.dark-version .dropdown .dropdownadmin-menu .card-header,
.dark-version .kanban-board .card-header {
  background: transparent;
}

.dark-version .card p,
.dark-version .swal2-popup p,
.dark-version .dropdown .dropdownadmin-menu p,
.dark-version .kanban-board p {
  color: #fff !important;
  opacity: .6;
}

.dark-version .kanban-item {
  background: transparent !important;
  border: 1px solid;
}

.dark-version .swal2-html-container {
  color: #fff !important;
  opacity: .6;
}

.dark-version h1,
.dark-version .h1,
.dark-version .h1,
.dark-version h2,
.dark-version .h2,
.dark-version .h2,
.dark-version h3,
.dark-version .h3,
.dark-version .h3,
.dark-version h4,
.dark-version .h4,
.dark-version .h4,
.dark-version h5,
.dark-version .h5,
.dark-version .h5,
.dark-version h6,
.dark-version .h6,
.dark-version .h6,
.dark-version a:not(.dropdown-item):not(.choices__item):not(.leaflet-control-zoom-in):not(.leaflet-control-zoom-out):not(.btnadmin):not(.nav-admin-link):not(.fixed-plugin-button),
.dark-version .table thead tr th,
.dark-version .kanban-title-board {
  color: #fff !important;
}

.dark-version .input-group.input-group-dynamic .form-admin-control,
.dark-version .input-group.input-group-static .form-admin-control {
  background-image: linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, rgba(210, 210, 210, 0.6) 1px, rgba(209, 209, 209, 0) 0) !important;
  background-size: 0 100%, 100% 100%;
}

.dark-version .input-group.input-group-dynamic .form-admin-control:focus,
.dark-version .input-group.input-group-static .form-admin-control:focus {
  background-size: 100% 100%, 100% 100%;
}

.dark-version .input-group.input-group-outline .form-admin-control {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.dark-version .input-group .is-valid,
.dark-version .input-group .is-invalid {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.dark-version .accordion .accordion-button {
  border-color: rgba(255, 255, 255, 0.4) !important;
  color: #fff;
  opacity: .8;
}

.dark-version .table> :not(caption)>*>* {
  border-color: rgba(255, 255, 255, 0.4) !important;
  color: rgba(255, 255, 255, 0.6) !important;
}

.dark-version label-admin {
  color: rgba(255, 255, 255, 0.8) !important;
}

.dark-version .list-group-item,
.dark-version .multisteps-form__panel {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.dark-version .nav.bg-white {
  background-color: #111C44 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dark-version .nav-admin.nav-admin-link[data-scroll]:hover {
  color: #344767 !important;
}

.dark-version .toast {
  background-color: #111C44 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dark-version .toast .toast-header {
  background: transparent;
}

.dark-version .toast span {
  color: #fff;
}

.dark-version .toast p {
  color: #fff !important;
  opacity: .6;
}

.dark-version .choices .choices__input {
  background-color: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
}

.dark-version .choices .choices__list.choices__list--dropdown {
  background: #111C44;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dark-version .fc-theme-standard td,
.dark-version .fc-theme-standard th {
  border-color: rgba(131, 146, 171, 0.3);
}

.dark-version .dataTable-sorter::after {
  border-bottom-color: #fff;
}

.dark-version .dataTable-sorter::before {
  border-top-color: #fff;
}

.dark-version .ql-snow .ql-stroke {
  stroke: #e9ecef;
}

.dark-version .ql-snow .ql-fill,
.dark-version .ql-snow .ql-stroke.ql-fill {
  fill: #e9ecef;
}

.dark-version .ql-toolbar.ql-snow .ql-picker-label-admin {
  color: #e9ecef;
}

.dark-version .navbaradmin-vertical .navbaradmin-nav .nav-item .nav-admin-link {
  color: #fff;
  opacity: .8;
}

.dark-version .navbaradmin-vertical .navbaradmin-nav .nav-item .nav-admin-link[data-bs-toggle="collapse"]:after {
  color: #fff;
}

.dark-version .navbaradmin-vertical .navbaradmin-nav .nav-item .nav-admin-link.active {
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
}

.dark-version .navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item .nav-admin-link,
.dark-version .navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item .nav-admin-link {
  color: #fff;
  opacity: .6;
}

.dark-version .navbaradmin-vertical .navbaradmin-nav .nav-item .collapse .nav-admin.nav-item .nav-admin-link.active,
.dark-version .navbaradmin-vertical .navbaradmin-nav .nav-item .collapsing .nav-admin.nav-item .nav-admin-link.active {
  opacity: 1;
}

.dark-version .dropdown .dropdownadmin-menu:before {
  color: #111C44;
}

.dark-version .dropdown .dropdownadmin-menu .dropdown-item:hover,
.dark-version .dropdown .dropdownadmin-menu .dropdown-item:focus {
  background-color: #051139 !important;
}

.dark-version .dropdown .dropdownadmin-menu .dropdown-item i,
.dark-version .dropdown .dropdownadmin-menu .dropdown-item .material-icons {
  color: #fff;
}

body.dark-version {
  color: rgba(255, 255, 255, 0.8) !important;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 0 8px 26px -4px rgba(20, 20, 20, 0.15), 0 8px 9px -5px rgba(20, 20, 20, 0.06);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer+.dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.start-adminRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.start-adminRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.start-adminRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.start-adminRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.start-adminRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.start-adminRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.start-adminRange,
.flatpickr-day.start-adminRange.start-adminRange,
.flatpickr-day.endRange.start-adminRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.start-adminRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.start-adminRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.start-adminRange.start-adminRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.start-adminRange+.endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.start-adminRange.endRange,
.flatpickr-day.start-adminRange.start-adminRange.endRange,
.flatpickr-day.endRange.start-adminRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.datepicker.flatpickr-input {
  background-color: #fff;
}

.flatpickr-calendar.open {
  margin-left: -38px;
  margin-top: 4px;
}

.flatpickr-calendar.arrowBottom {
  margin-top: -20px;
}

.flatpickr-calendar .flatpickr-innerContainer {
  margin-top: 15px !important;
}

.flatpickr-calendar .numInputWrapper span {
  border: none;
  border-bottom: 1px solid rgba(57, 57, 57, 0.15);
}

.flatpickr-calendar .numInputWrapper:hover .arrowUp,
.flatpickr-calendar .numInputWrapper:hover .arrowDown {
  margin-top: 3px;
}

.flatpickr-calendar .flatpickr-day.today,
.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.start-adminRange,
.flatpickr-calendar .flatpickr-day.endRange {
  background: #5e72e4 !important;
  color: #fff;
  border: none;
}

.flatpickr-calendar .flatpickr-day.inRange {
  background: rgba(94, 114, 228, 0.28);
  border: none;
  -webkit-box-shadow: -5px 0 0 #D7DCF8, 5px 0 0 #D7DCF8;
  box-shadow: -5px 0 0 #D7DCF8, 5px 0 0 #D7DCF8;
}

.flatpickr-calendar .flatpickr-day:not(.selected):hover,
.flatpickr-calendar .flatpickr-day:not(.selected):focus {
  background: rgba(94, 114, 228, 0.28);
  border: none;
}

.flatpickr-calendar .flatpickr-time input:hover,
.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-calendar .flatpickr-time input:focus,
.flatpickr-calendar .flatpickr-time .flatpickr-am-pm:focus {
  background: rgba(94, 114, 228, 0.28);
}

.flatpickr.form-admin-control {
  background: #fff;
}

.flatpickr-day.endRange.start-adminRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.selected.start-adminRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.start-adminRange.start-adminRange+.endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #5e72e4;
}

/*! nouislider - 15.5.0 - 11/19/2020 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
*/
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

/* Offset direction
*/
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
* connect elements.
*/
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
*/
.noUi-horizontal {
  height: 5px;
}

.noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  right: -10px;
  top: -5px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 15px;
  height: 15px;
  right: -5px;
  bottom: -10px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

/* Styling;
* Giving the connect element a border radius causes issues with using transform: scale
*/
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #5e72e4;
}

/* Handles and cursors;
*/
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border-radius: 100%;
  background: #5e72e4;
  cursor: default;
  webkit-transition: .3s ease 0s;
  -moz-transition: .3s ease 0s;
  -ms-transition: .3s ease 0s;
  -o-transform: .3s ease 0s;
  transition: .3s ease 0s;
}

/* Disabled state;
*/
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
*
*/
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
*
*/
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
*
*/
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
*
*/
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
*
*/
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin>.noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin>.noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

/* PrismJS 1.23.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {

  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  overflow: auto;
  border-radius: .75rem;
}

:not(pre)>code[class*="language-"],
pre[class*="language-"] {
  background: #f8f9fa;
}

/* Inline code */
:not(pre)>code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  /* This background color was intended by the author of this theme. */
  background: rgba(255, 255, 255, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y,
.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */


/* ....My css .....*/


/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: '#9D5C9F';
}

/* Side-Bar css */
.sidebar-first {
  /* display: block; */
  /* position: fixed;
  top: 0;
  bottom: 0; */
  /* width: 100%; */
  /* min-width: 15.625rem; */
  max-width: 17.625rem !important;

  overflow-y: auto;
  padding: 0;
  box-shadow: none;
  height: 85vh;
  /* z-index: 9999; */
  background-color: whitesmoke;
  transition: 0.5s;
 }

.admin-sidebar-false{
  min-width: 15.625rem;
}
 
.admin-users-table-false{
  width: 100%;
}
/* Table Adjust */
@media screen and (max-width: 1112px) {
  .admin-users-table-false{
    width: 100%;
    overflow-x: scroll;
  }

}

.admin_search_bar{
  max-width: 350px;
  height: 40px;
}


