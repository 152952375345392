.body {
  background-color: #fff;
}
.Navfooter .nav-link {
  margin-top: 10px;
}
.MainContact {
  position: relative;
  width: 98%;
  height: fit-content;
  background-color: #d1d1d1;
  margin-left: 1%;
  padding-bottom: 50px;
  border-radius: 8px;
}
.Contactheading {
  height: 48px;
  background-color: #ececec;
  border-radius: 8px 8px 0px 0px;
}
.Contactheading p {
  color: #6148a1;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
}
/* map start */
.map-container-section {
  height: 185px;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}
.map-container-section iframe {
  width: 739px;
  border: 1px solid #6148a1;
  border-radius: 8px;
}
/* input card start */
.contact-card-main {
  display: flex;
  justify-content: center;
}
.Contact-card-customize {
  height: fit-content;
  width: 362px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0px 10px 64px rgba(21, 48, 89, 0.3);
}
.Contact-LoginHeading {
  font-size: 16px;
  font-weight: bolder;
  color: black;
  text-align: center;
  margin-left: 35%;
}
.contact-p {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: black;
  padding: 14px 12px 6px 12px;
}
.Contact-input-btn {
  width: 330px;
  background: #ececec;
  border-radius: 4px;
  margin-left: 16px;
  padding-left: 30px;
  font-size: 14px;
}
.contact-Labelsignup {
  font-size: 11px;
  font-weight: 400;
  margin-left: 16px;
}
.contactMdinfo {
  position: absolute;
  top: 32px;
  left: 22px;
}
.contact-btn-submit {
  width: 330px;
  height: 48px;
  background: #6148a1;
  border-radius: 8px;
  margin: 15px 0px 15px 16px;
}
.contact-btn-submit:hover {
  background-color: #6148a1;
  z-index: 10;
  box-shadow: 2px 1px 1px rgba(21, 48, 89, 0.3);
}
.contact-btn-submit::marker {
  background-color: #6148a1;
}
.location-Main {
  margin-left: 15px;
}
.contact-Main-Location {
  padding-top: 53px;
  padding-bottom: 66px;
}
.Contact-Location {
  text-align: center;
}
.Contact-Location h6 {
  font-size: 13px;
  font-weight: 500;
  color: black;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1877px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1320px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 912px) {
}
@media screen and (max-width: 820px) {
}

@media screen and (max-width: 768px) {
  .contact-card-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .location-Main {
    margin-left: 0;
  }
}

@media screen and (max-width: 540px) {
}

@media screen and (max-width: 428px) {
}

@media screen and (max-width: 414px) {
}

@media screen and (max-width: 412px) {
}

@media screen and (max-width: 393px) {
}

@media screen and (max-width: 390px) {
  .Contact-card-customize {
    height: fit-content;
    width: 332px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0px 10px 64px rgba(21, 48, 89, 0.3);
  }
  .Contact-input-btn {
    width: 300px;
    background: #ececec;
    border-radius: 4px;
    margin-left: 16px;
    padding-left: 30px;
    font-size: 14px;
  }
  .contact-btn-submit {
    width: 300px;
    height: 48px;
    background: #6148a1;
    border-radius: 8px;
    margin: 15px 0px 15px 16px;
  }
}

@media screen and (max-width: 375px) {
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 320px) {
}

@media screen and (max-width: 281px) {
  .map-container-section {
    height: 185px;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  .map-container-section iframe {
    width: 270px;
    border: 1px solid #6148a1;
    border-radius: 8px;
  }
  .Contact-card-customize {
    height: fit-content;
    width: 270px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0px 10px 64px rgba(21, 48, 89, 0.3);
  }
  .Contact-input-btn {
    width: 240px;
    background: #ececec;
    border-radius: 4px;
    margin-left: 16px;
    padding-left: 30px;
    font-size: 14px;
  }
  .contact-btn-submit {
    width: 240px;
    height: 48px;
    background: #6148a1;
    border-radius: 8px;
    margin: 15px 0px 15px 16px;
  }
}
