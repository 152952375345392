/* Main Settings Styles */
.s-inner-main {
  max-width: 360px;
  overflow: hidden;
  margin: auto;
}
/* @media only screen and (max-width: 370px) {
  .s-inner-main {
    width: 260px;
    margin: auto;
  }
} */

.s-header {
  background-color: #ececec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.s-body {
  background-color: #d1d1d1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.s-header span {
  color: #6148a1;
}

.s-inner-header {
  background-color: #8e73d3;
  margin-bottom: -40px;
}
.Settingcover{
  width: 100%;
  height: 107px;
  object-fit: cover;
}

.ms-img {
  border-radius: 50%;
  margin-left: 17px;
  width:124px ;
  height: 124px;
  object-fit: fill;
}

.s-camera {
  color: white;
  background-color: #9f47a0;
  font-size: 38px;
  margin-left: -28px;
  margin-top: 40px;
  cursor: pointer;
}

.s-icons {
  background-color: #fea115;
  cursor: pointer;
}

.s-buttons button:nth-child(1) {
  background-color: #6148a1;
  color: white;
}

.s-buttons button:nth-child(1):hover {
  background-color: #4c338c;
  color: white;
}

.s-buttons button:nth-child(2) {
  background-color: #fea115;
  color: white;
}

.s-buttons button:nth-child(2):hover {
  background-color: #df8b0c;
  color: white;
}

.setting-about {
  background-color: #ececec;
  font-size: 12px;
  overflow: auto;
}

/* Scrol Styles */

.setting-about-scroll {
  height: auto;
  max-height: 70px;
  overflow: auto;
}
.aboutfield {
  background-color: #ececec;
  border: none;
  font-size: 14px;
}
.btnInterests {
  background-color: #4c338c;
  text-align: end;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.Phoneinput{
  width: auto;
}
.textInterests {
  color: #fff;
  font-size: 14px;
  padding: 8px 5px 8px 0px;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d1d1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8461b9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c7a8f6;
}

.setting-interest {
  background-color: #ececec;
  font-size: 12px;
  overflow: auto;
}

.setting-interest button {
  background-color: #6148a1;
  font-size: 12px;
  font-weight: 700;
  color: white;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.setting-interest button:hover {
  background-color: #4c338c;
  font-size: 12px;
  font-weight: 700;
  color: white;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

/* ^ Main Settings Styles */
/* Profile Setting Styles */

/* ^ Profile Setting Styles */

/* Privacy Setting Styles */
.ps-title {
  font-weight: 800;
  font-size: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  top: 2px;
  left: 2px;
  border-radius: 30px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #18d989;
}

input:checked + .slider:before {
  border-radius: 50%;
  background-color: #fff;
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.privacy-label {
  color: black;
  font-size: 13px;
  font-weight: 500;
}

/* ^ Privacy Setting Styles */

/* Notifications Setting Styles */

/* ^ Notifications Setting Styles */

/* Account General Setting Styles */

.main_btns {
  width: 100%;
}
.top-Btns {
  width: 45.5%;
  border: none;
  height: 50px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.top-Btns-B{
  width: 30%;
  border: none;
  height: 50px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.genderGA {
  height: 43px;
  background-color: #ececec;
  border: 1px solid #ced4da;
  background-image: url(../../../../public/img/icon.png);
  background-size: 36px 40px;
  background-position: right;
}
.info-icon {
  position: relative;
}

.eye-icon {
  position: relative;
  margin-left: -30px;
}

.form-setting {
  color: #ececec;
  background-color: #ececec;
  margin-left: -30px;
  outline: none;
}

.general-body Form.Control:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.acc-title {
  font-weight: 800;
  font-size: 16px;
}

.map-wrap-s {
  position: relative;
  max-width: 304px;
  height: 258px; /* calculate height of the screen minus the heading */
  display: flex;
  justify-content: center;
}

.map-s {
  position: absolute;
  margin-left: 6px;
  max-width: 300px;
  height: 250px;
  border: 1px solid #6148a1;
  border-radius: 8px;
}


/* ^ Account General Setting Styles */
.rti--container .rti--input{
  order: 1;
  width: 100%;
}
.rti--container .rti--tag{
  order: 2;
  background-color: #6148a1;
  color: white;
  font-size: 14px;
}
.rti--container .rti--tag button{
  color: white;
}
 /* @Samcore tagInput */
 .___STag_1pyys_gg_.__editable_1pyys_gg_{
  background-color: #6148a1 !important;
  color: #fff !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  
}
.___STag_1pyys_gg_._size_l_1pyys_gg_{
  background-color: #6148a1 !important;
  color: #fff !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  
 }