.Mission-card-main {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.Mission-card-customize {
  position: relative;
  width: 941px;
  height: fit-content;
  background: #ffffff;
  border-radius: 8px;
}
.Mission-border_top {
  border-top: 4px solid #8a438b;
  margin: 0 340px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.Mission-img {
  height: 211px;
  width: 909px;
  border-radius: 6px;
  margin: 15px;
}
.Mission-LoginHeading{
  font-size: 16px;
  font-weight: bolder;
  color: black;
  margin-left: 40%;
}
.Mission-p{
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: black;
  padding: 14px 27px 6px 27px;
}
.Mission-footer-p{
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: black;
}

@media screen and (max-width: 2560px) {
}

@media screen and (max-width: 1877px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1320px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 960px) {
  .Mission-card-customize {
    position: relative;
    width: 900px;
    height: fit-content;
    background: #ffffff;
    border-radius: 8px;
  }
  .Mission-border_top {
    border-top: 4px solid #8a438b;
    margin: 0 340px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .Mission-img {
    height: 211px;
    width: 820px;
    border-radius: 6px;
    margin: 2%;
  }
}
@media screen and (max-width: 912px) {
  .Mission-card-customize {
    position: relative;
    width: 820px;
    height: fit-content;
    background: #ffffff;
    border-radius: 8px;
  }
  .Mission-border_top {
    border-top: 4px solid #8a438b;
    margin: 0 340px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .Mission-img {
    height: 211px;
    width: 770px;
    border-radius: 6px;
    margin: 2%;
  }
}
@media screen and (max-width: 820px) {
  .Mission-card-customize {
    position: relative;
    width: 760px;
    height: fit-content;
    background: #ffffff;
    border-radius: 8px;
  }
  .Mission-border_top {
    border-top: 4px solid #8a438b;
    margin: 0 240px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .Mission-img {
    height: 211px;
    width: 710px;
    border-radius: 6px;
    margin: 2%;
  }
}

@media screen and (max-width: 768px) {
  .Mission-card-customize {
    position: relative;
    width: 520px;
    height: fit-content;
    background: #ffffff;
    border-radius: 8px;
  }
  .Mission-border_top {
    border-top: 4px solid #8a438b;
    margin: 0 150px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .Mission-img {
    height: 211px;
    width: 470px;
    border-radius: 6px;
    margin-left: 4%;
  }
}
@media screen and (max-width: 540px) {
  .Mission-card-customize {
    position: relative;
    width: 320px;
    height: fit-content;
    background: #ffffff;
    border-radius: 8px;
  }
  .Mission-border_top {
    border-top: 4px solid #8a438b;
    margin: 0 40px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .Mission-img {
    height: 151px;
    width: 270px;
    border-radius: 6px;
    margin-left: 6%;
  }
  .Mission-LoginHeading{
    font-size: 16px;
    font-weight: bolder;
    color: black;
    margin-left: 30%;
  }
}
@media screen and (max-width: 428px) {
}
@media screen and (max-width: 414px) {
}

@media screen and (max-width: 411px) {
}
@media screen and (max-width: 390px) {
}

@media screen and (max-width: 375px) {
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 320px) {
}
@media screen and (max-width: 280px) {
}
