.dashboard-row {
  background-color: #d1d1d1;
  position: relative;
}

/* Global Newsfeed Styles */
.section {
  background-color: white;
  height: 600px;
  /* height: 70.5vh */
}

.search-newsfeed {
  color: #ececec;
  font-style: italic;
  font-size: 14px;
  background-color: #ececec;
  /* margin-left:0px; */
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.search-newsfeed:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}
.main-posts {
  height: 460px;
  /* height: 75%; */
  overflow-y: auto;
}

.form-div {
  background-color: #ececec;
}

.search_form {
  font-size: 14px;
  border: 0;
  outline: none;
  background-color: #ececec;
}

.search_form:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.posts {
  background-color: #eeeeee;
  height: 200px;
}

.posts-text {
  font-size: 12px;
}

.post-btn {
  background-color: #6148a1;
  color: white;
  border: none;
  border-radius: 5px;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
  border-color: #6148a1;
  width: 90px;
}

.post-btn:hover {
  background-color: #4c338c;
  border-color: #4c338c;
  color: white;
  width: 90px;
}

.post-btn:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.person-btn {
  background-color: #ececec;
}

.person-btn:hover {
  background-color: #d1d1d1;
}

.group-btn {
  background-color: #6148a1;
}

.group-btn:hover {
  background-color: #4c338c;
}

.person-icon {
  color: #6148a1;
  font-size: 20px;
}

.group-icon {
  color: white;
  font-size: 20px;
}

.group-icon:active {
  color: #6148a1;
  font-size: 20px;
}
.DashImage{
  width: 95%;
  height: 50%;
}
/* ^ Global Newsfeed Styles */
/* Friends Styles */
.friends-btn1 {
  color: white;
  height: 50px;
  width: 100px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  background-color: #fea115;
  font-size: 14px;
  margin-right: 10px;
}

.friends-btn1:hover {
  color: white;
  font-weight: 600;
  background-color: #df8b0c;
  font-size: 14px;
}

.friends-btn2 {
  color: white;
  font-weight: 600;
  font-size: 14px;
  background-color: #6148a1;
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 5px
}

.friends-btn2:hover {
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #4c338c;
}

.d-friends {
  background-color: white;
}

.d-view {
  font-size: 14px;
  font-weight: 400;
}

/* ^ Friends Styles */
/* Map Styles */
.down-btn {
  background-color: #6148a1;
  border-radius: 5px;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
  color: white;
  border-color: #6148a1;
}

.down-btn:hover {
  background-color: #4c338c;
  color: white;
  border-color: #4c338c;
}
.down-btn:visited {
  background-color: #4c338c;
  color: white;
  border-color: #4c338c;
}

.down-btn:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}
.timeinpt {
  width: 159px;
  height: 36px;
  background-color: #ececec;
  border-radius: 4px;
  padding-left: 14px;
}

.map-form {
  color: #000000;
  height: 38px;
  font-size: 14px;
  font-weight: 500;
  background-color: #ececec;
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: url(../../../../public/img/icon.png);
  background-size: 36px 37px;
  background-position: right;
}

.map-form:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}
.side-bar {
  padding-bottom: 10px;
  background-color: white;
}

.arrow-down {
  font-size: 28px;
  font-weight: 400;
}

.edit-btn {
  font-size: 14px;
  font-weight: 500;
}

.edit-btn * {
  display: inline-block;
}

.pencil {
  font-size: 16px;
  font-weight: 500;
}

/* ^ Map Styles */
/* Events Styles */
.events-header {
  background-color: #984399;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}

.event-name {
  font-size: 14px;
  font-weight: 800;
}

.add-eb {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-right: 15px;
  color: white;
  background-color: #984399;
}

.arrow-send {
  font-size: 18px;
}

.events-body {
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.events-body div div div {
  overflow: hidden;
}

.send-eb {
  background-color: #984399;
  color: white;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.send-eb:hover {
  background-color: #984399;
  color: white;
}

/* Scrol Styles */
.events-scrol {
  height: 191px;
  overflow-y: auto;
}

/* width */
/* ::-webkit-scrollbar {
  width: 4px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #d1d1d1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #8461b9;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #c7a8f6;
} */

/* ^ Events Styles */
/* Common Styles */

.add {
  font-size: 13px;
  font-weight: 700;
}

.plus-btn {
  font-size: 14px;
  font-weight: 400;
}
.eventsHover:hover {
  background-color: #984399;
  color: #fff;
}
.microHover:hover {
  background-color: #e40090;
  color: #fff;
}

.box-img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

.box-span1 {
  position: relative;
  top: 2px;
  font-size: 12px;
}

.box-span2 {
  position: relative;
  top: -7px;
  font-size: 12px;
}

/* ^ Common Styles */

/* Microblogs Styles */
.microblogs {
  overflow: hidden;
}

.mb-name {
  font-size: 14px;
  font-weight: 800;
}

.microblogs-body {
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.microblogs-body div div div {
  overflow: hidden;
}

.microblogs-header {
  border-color: transparent;
  background-color: #e40090;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}

/* Scrol Styles */
.microblogs-scrol {
  height: 191px;
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d1d1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #942d6e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6215d4;
}

.add-mb {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-right: 15px;
  color: white;
  background-color: #e40090;
}

.add-mb:hover {
  color: white;
  background-color: #e40090;
}

.send-mb {
  background-color: #e40090;
  color: white;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.send-mb:hover {
  background-color: #e40090;
  color: white;
}

/* ^ Microblogs Styles */
.map-cities {
  margin-left: 15px;
  width: 92%;
  height: 206px;
}

/* Top Cities Styles */
.topCities-Main {
  background-color:rgba(85, 71, 119, 0.85);;
  backdrop-filter: blur(1px);
  height: 100%;
  border-radius: 10px;
  z-index: 10;
  position: absolute;
  top: 0px;
}
.cities-main {
  width: 463px;
  height: fit-content;
  overflow: auto;
  margin: auto;
  background-color: #fff;
  margin-top: 8%;
}

.cities-map {
  height: 205px;
}

.cities-header {
  background-color: #6148a1;
  color: white;
  font-size: 14px;
}

.tick-btn {
  color: white;
  background-color: #6148a1;
  font-size: 14px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.tick-btn:hover {
  background-color: #4c338c;
  color: white;
}

.save-btn {
  background-color: #6148a1;
  color: white;
}

.save-btn:hover {
  background-color: #4c338c;
  color: white;
}

.search-cities {
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #ececec;
  font-size: 14px;
  background-color: #ececec;
}

.search-cities:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.search-icon {
  position: absolute;
}

.tick-icon {
  font-size: 24px;
}

.cities-body p {
  font-size: 12px;
  font-weight: 500;
}

.main-city {
  font-size: 12px;
}

.city {
  height: 110px;
  width: 130px;
  box-shadow: 0px 3px 5px rgba(21, 48, 89, 0.3);
}

/* ^ Top Cities Styles */
/* Add Event Styles */
.main-event-bg {
  width: 100%;
  height: calc(100vh - 120px);
  background-color: rgba(85, 71, 119, 0.5);
  backdrop-filter: blur(2px);
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 200;
}

@media only screen and (max-width: 430px) {
  .main-event-bg{
    top: -70px;
}
}

.main-event-bg-dash {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0px;
  background-color: rgba(85, 71, 119, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  z-index: 1000;
  border-radius: 8px;
}

.event-step {
  font-size: 14px;
}
.eventlabel {
  margin-left: 24px;
  font-size: 12px;
  color: black;
}
.map-wrap-event {
  position: relative;
  max-width: 332;
  height: 330; /* calculate height of the screen minus the heading */
}

.map-event {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 348px;
  height: 320px;
  border: 1px solid #6148a1;
  border-radius: 8px;
}
.map-wrap-microblog {
  position: relative;
  max-width: 451px;
  height: 206px; /* calculate height of the screen minus the heading */
}

.map-microblog {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 451px;
  height: 206px;
  border: 1px solid #fea115;
  border-radius: 8px;
}
.createBtnMicroblog {
  padding: 0px 15px 15px 15px;
}
.Microblog-location-btn {
  height: 48px;
  background-color: #6148a1;
  border-radius: 8px;
  color: white;
}

.Microblog-location-btn:hover {
  background-color: #4c338c;
  color: white;
}
.geocoder {
  width: 90%;
  height: 36px;
  margin-top: 0px;
  margin-left: 20px;
  border: none;
  border-radius: 4px;
  background-color: #ececec;
  padding-left: 10px;
  background-image: url(../../../../public/img/search.png);
  background-size: 35px 35px;
  background-repeat: no-repeat;
  background-position: right;
}
.geocoder-microblog {
  width: 93%;
  height: 36px;
  margin-top: 0px;
  margin-left: 16px;
  border: none;
  border-radius: 4px;
  background-color: #ececec;
  padding-left: 10px;
  background-image: url(../../../../public/img/search.png);
  background-size: 35px 35px;
  background-repeat: no-repeat;
  background-position: right;
}
.evenntBtnBack {
  height: 48px;
  width: 116px;
  margin-left: 20px;
  margin-right: 24px;
  border-radius: 8px;
  padding: 0px 8px 0px 0px;
  background-color: #fea115;
  color: white;
  border: none;
}
.backEventIcon {
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 24px;
  color: white;
}
.eventCreateBtn {
  height: 48px;
  width: 220px;
  color: white;
  border: none;
  border-radius: 8px;
  background-color: #6148a1;
  border-color: #6148a1;
}

.event-container {
  max-width: 410px;
  height: calc(100vh - 210px);
  margin: auto;
  z-index: 100;
  position: absolute;
  top: 8%;
}

.event-main {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
}
/* @media only screen and (min-height: 840px) {
  .event-main {
    width: 390px;
    height: fit-content;
    margin-bottom: 20px;
  }
} */
.event-header {
  background-color: #984399;
  font-size: 14px;
}

.event-name{
  text-shadow: 1px 1px 2px black;
}

.event-body {
  background-color: white;
}

.event-location-btn {
  background-color: #6148a1;
  color: white;
  font-weight: bolder;
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 6px;
}

.event-location-btn:hover {
  background-color: #4c338c;
  color: white;
}

.event-body p {
  font-size: 12px;
  font-weight: 400;
}

.search-f1 {
  width: 100%;
  height: 36px;
  background-color: #ececec;
  font-size: 14px;
}
.search-f2,
.search-f3 {
  outline: none;
  color: #ececec;
  font-size: 14px;
  background-color: #ececec;
}

.search-f1:focus,
.search-f2:focus,
.search-f3:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.search-f2,
.search-f3 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.search-f2 {
  font-style: italic;
}

.event-des {
  width: 91%;
  margin-left: 20px;
  padding: 5px;
  background-color: #ececec;
  font-size: 12px;
}

.down-btn {
  color: white;
  background-color: #6148a1;
  font-size: 16px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.down-btn:hover {
  background-color: #4c338c;
  color: white;
}

.event-photo {
  width: 90%;
  height: 131px;
  margin-left: 20px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  border: 1px solid #6148a1;
}

.calendar-icon {
  font-size: 16px;
}

.camera-icon {
  color: #6148a1;
}

.time-btn {
  background-color: #ececec;
  font-size: 14px;
  outline: none;
}

.time-btn:hover {
  background-color: #eeeeee;
  font-size: 14px;
}
.EventCat {
  width: 91%;
  height: 36px;
  color: black;
  background-color: #ececec;
  margin-left: 18px;
}
.Eventdate {
  width: 91%;
  height: 36px;
  color: black;
  background-color: #ececec;
  margin-left: 18px;
  /* background-image: url(../../../../public/img/clender.png); */
 
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #fff;
  background-color: #4c338c;
  padding: 10px;
  position: relative;
  right: -14px;
  background: url(../../../../public/img/clender.png);
  background-size: 37px 37px;
  background-repeat: no-repeat;
  background-position: right;
}

/* ^ Add Event Styles */
/* Add MicroBlogs Styles */
.mb-profile {
  margin-top: -55px;
  justify-content: center;
  align-items: center;
}
.microblogProfilePic {
  width: 104px;
  height: 104px;
  background-position: center;
}

.mb-camera {
  color: white;
  background-color: #9f47a0;
  font-size: 25px;
  margin-left: -15px;
  cursor: pointer;
}

.mb-main {
  width: 463px;
  height: calc(100vh - 200px);
  overflow: auto;
  z-index: 100;
  position: absolute;
  top: 6%;
  margin: auto;
  /* left: 30%; */
  background-color: #fff;
}
.size-microblog-icon{
  font-size: 38px;
}
.size-camra-icon{
  font-size: 30px;
}

@media only screen and (max-width: 540px) {
  .mb-main {

    width: 263px;
 
    /* left: 30%; */

  }
  .microblogProfilePic {
    width: 40px;
    height: 40px;
    background-position: center;
  }
  .mb-profile {
    margin-top: -25px;
   
  }
  .size-microblog-icon{
    font-size: 25px;
  }
  .coverCam {
    position: absolute;
    margin-top: -28px;
  
  
  }
  .size-camra-icon{
    font-size: 25px;
  }
  .element.style {
    width: 231px;
    height: 206px;
}
.map-wrap-microblog {
    min-width: 131px;
    height: 206px;
}
  

}
@media only screen and (min-height: 840px) {
  .mb-main {
    height: fit-content;
    margin-bottom: 20px;
  }
}
.coverCam {
  position: absolute;
  right: 16px;
  top: 65px;
  background-color: #fff;
  padding: 4px;
  border-radius: 10px;
  color: black;
}
.mb-header {
  background-color: #e40090;
  font-size: 14px;
}

.mb-body {
  background-color: white;
}

.mb-body p {
  font-size: 12px;
  font-weight: 400;
}

.location-heading {
  color: #7c7c7c;
  size: 14px;
  font-weight: 500;
}

.mb-map {
  border: 1px solid #fea115;
  overflow: hidden;
}
.mb-search1 {
  width: 100%;
  height: 36px;
  border: none;
  border-radius: 4px;
  color: #ececec;
  font-size: 14px;
  background-color: #ececec;
  font-weight: 400;
}

.mb-search1:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.mb-search {
  position: relative;
  font-size: 20px;
}

/* ^ Add MicroBlogs Styles */
/* MicroBlogs Activities Styles */

.mb-activities-main{
  width: 463px;
}

.mb-activities {
  overflow: hidden;
  margin: auto;
}

@media only screen and (max-width: 600) {
  .mb-activities-main {
    max-width: 463px;
  }
}

.mba-header {
  background-color: #e40090;
  font-size: 14px;
}

.mba-body {
  background-color: white;
}
.PaperclipMA {
  position: absolute;
  left: 22px;
  margin-top: 8px;
  background-color: #6148a1;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}
.Paperclip {
  position: absolute;
  top: 88px;
  left: 43px;
  background-color: #6148a1;
  color: #fff;
  cursor: pointer;
  /* margin-top: 4px; */
  border-radius: 4px;
}

.PaperclipDA {
  position: absolute;
  top: 74px;
  left: 45px;
  background-color: #6148a1;
  color: #fff;
  cursor: pointer;
  /* margin-top: 4px; */
  border-radius: 4px;
}

.mainInput .react-emoji-picker--wrapper{
  bottom: -320px;
}
#test .react-input-emoji--wrapper{
  height: 40px;
}
#test .react-input-emoji--button {
  z-index: 1;
  right: 2px;
  position: absolute;
}
#test .react-input-emoji--input{
  padding-left: 35px;
}
#test .react-emoji{
  width: 96%;
  height: 42px;
  margin-left: 0px;
}
#test .react-input-emoji--button {
  z-index: 1;
  right: 2px;
  position: absolute;
}
#test .react-input-emoji--input {
  color: #000000;
  font-style: normal;
  font-size: 14px;
  background-color: #ececec;
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 35px;
}
#test .react-input-emoji--placeholder {
  padding-left: 25px;
}
#test .react-emoji-picker{
  position: absolute;
  top: 0px;
}
#test .react-emoji-picker--wrapper{
  right: -90px;
}
#test .emoji-mart{
  width: 290px !important;
  padding-top: 10px;
}
#test .emoji-mart-bar{
  display: none;
}
#test .emoji-mart-search{
  display: none;
}

.ma-search {
  font-style: italic;
}

.ma-search {
  outline: none;
  color: #ececec;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 14px;
  background-color: #ececec;
}

.ma-search:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.text-day {
  font-size: 12px;
}
.imageuserMA {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 5px;
}

.chat-box1 {
  background-color: #6148a1;
  max-width: 444px;
}

.chat-box1,
.chat-box2 p,
span {
  font-size: 12px;
  font-weight: 300;
}

/* ^ MicroBlogs Activities Styles */

/* Map Overview */
.drillMap {
  width: 100%;
  height: calc(100vh - 120px);
}
#createEventGeocoder {
  height: 50px;
  width: 90%;
  margin-left: 20px;
}

#createEventGeocoder .mapboxgl-ctrl-geocoder {
  width: 100%;
  height: 36px;
  box-shadow: none;
}
#createEventGeocoder .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--button {
  background-color: #ececec;
}

#createEventGeocoder .mapboxgl-ctrl-geocoder input[type="text"] {
  font-size: 14px;
  background-color: #ececec;
}

#createEventGeocoder .mapboxgl-ctrl-geocoder span.geocoder-icon-search {
  top: 15px;
  left: 15px;
}

#createEventGeocoder .mapboxgl-ctrl-geocoder ul {
  font-size: 14px;
}
/* Microblog Geocoder */
#createMicroblogGeocoder {
  height: 50px;
  width: 95%;
  margin-left: 20px;
}

#createMicroblogGeocoder .mapboxgl-ctrl-geocoder {
  width: 100%;
  height: 36px;
  box-shadow: none;
}
#createMicroblogGeocoder
  .mapboxgl-ctrl-geocoder
  .mapboxgl-ctrl-geocoder--button {
  background-color: #ececec;
}

#createMicroblogGeocoder .mapboxgl-ctrl-geocoder input[type="text"] {
  font-size: 14px;
  background-color: #ececec;
  width: 116%;
  border-radius: 4px;
}

#createMicroblogGeocoder .mapboxgl-ctrl-geocoder span.geocoder-icon-search {
  top: 15px;
  left: 15px;
}

#createMicroblogGeocoder .mapboxgl-ctrl-geocoder ul {
  font-size: 14px;
}
.map-wrap-dash {
  position: relative;
  /* padding-left: 5px; */
  width: 100%;
  height: 188px;
  background-color: transparent;
}
.map-dash {
  position: absolute;
  width: 95%;
  height: 170px;
  border-radius: 10px;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact-show {
  visibility: hidden;
}
.cityNameShow {
  position: absolute;
  bottom: -24px;
  left: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.CoverImgMicro{
  height: 85px;
  object-fit: cover;
  width: 96%;
}


/*TYping */
.chat-bubble {
  background-color:#fff;
  padding:16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display:inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6148A1;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:#6148A1; 
  }
  28% {
    transform: translateY(-7px);
    background-color:#6148A1; 
  }
  44% {
    transform: translateY(0px);
    background-color: #6148A1;
  }
}
.modleImage{
  width: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  position: relative;
}

@media only screen and (min-width: 2500px) {
  .section{
   height: 70vh;
  }
  .main-posts{
    height:82%;
  }
  .map-wrap-dash {
    height: 22vh;
  }
  .map-dash {
    height: 22vh;
  }
  .events-body{
    height: 25vh;
  }
  .microblogs-body{
    height: 25vh;
  }
}