  h5 {
    color: black;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
  }
  .p,
  p {
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
    margin: 0pt;
  }
  .s1 {
    color: #222;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
  }
  .s3 {
    color: black;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
  }
  .a,
  a {
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
  }
  li {
    display: block;
  }
  #l1 {
    padding-left: 0pt;
  }
  #l1 > li > *:first-child:before {
    content: "- ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
  li {
    display: block;
  }
  #l2 {
    padding-left: 0pt;
  }
  #l2 > li > *:first-child:before {
    content: "– ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
  }
  li {
    display: block;
  }
  #l3 {
    padding-left: 0pt;
    counter-reset: e1 1;
  }
  #l3 > li > *:first-child:before {
    counter-increment: e1;
    content: counter(e1, decimal) ") ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
  }
  #l3 > li:first-child > *:first-child:before {
    counter-increment: e1 0;
  }
  li {
    display: block;
  }
  #l4 {
    padding-left: 0pt;
  }
  #l4 > li > *:first-child:before {
    content: "– ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
  }