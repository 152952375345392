.item {
    align-items: center;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: #ECECEC;
   
  }
  
  .disabled-page {
    color: #808e9b;
  }
  
  .active {
   background-color: #6148a1;
   color: white;
   
  }

  .next {
 
    font-size: 4px; 
    
  }
  
  .pagination {
    display: flex;
    flex-direction: row;
    height: 60px;
    list-style-type: none; 
  }
  
  .pagination-page {
    font-weight: 700;
  }
  
  .pagination-page a {
    width: 40px;
    height: 40px;
    margin: auto;
    padding-top: 5px;
    text-align: center;
  }

  .previous {

    font-size: 4px;
    
    margin-left: 7px;  
  }