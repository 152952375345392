.blog-card-customize {
  padding-bottom: 30px;
  height: fit-content;
  width: 362px;
  border-radius: 8px;
  background-color: white;
}
.blog-border_top {
  border-top: 4px solid #8a438b;
  margin: 0 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.mainCards {
  margin-top: 30px;
}

.blog-cards{
  max-width: 380px;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1877px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1140px) {
  /* .mainCards {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    margin-top: 20px;
  } */
  .second-card {
    margin-top: 10px;
  }
  .third-card {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 912px) {
}
@media screen and (max-width: 820px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 540px) {
}
@media screen and (max-width: 428px) {
}
@media screen and (max-width: 414px) {
}

@media screen and (max-width: 411px) {
}

@media screen and (max-width: 393px) {
}
@media screen and (max-width: 390px) {
}
@media screen and (max-width: 375px) {
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 320px) {
}

@media screen and (max-width: 280px) {
}

.form-header.blue.accent-1 {
  background-color: #62479f !important;
}
.btn.btn-light-blue {
  background-color: #62479f !important;
}
.btn-floating.blue.accent-1 {
  background-color: #62479f !important;
}
.h1-responsive.font-weight-bold.text-center.my-5 {
  color: #62479f !important;
}
.btn.btn-outline-warning.my-2.my-sm-0:hover {
  color: #ffb940 !important;
}
button.btn.btn-pink.btn-rounded {
  background-color: #62479f !important;
}
span.badge.pink {
  background-color: #62479f !important;
}
@media screen and (max-width: 1440px) {
  #authorBio {
    margin-left: 150px;
  }
  .h5big {
    margin-left: 150px;
  }
  .personal-sm.pb-3 {
    margin-left: 150px;
  }
}

@media screen and (max-width: 1024px) {
  #authorBio {
    margin-left: 150px;
  }
  .h5big {
    margin-left: 150px;
  }
  .personal-sm.pb-3 {
    margin-left: 150px;
  }
}
@media screen and (max-width: 768px) {
  #authorPhoto {
    margin-left: -30px;
  }
  #authorBio {
    margin-left: 150px;
  }
  .h5big {
    margin-left: 150px;
  }
  .personal-sm.pb-3 {
    margin-left: 150px;
  }
}
@media screen and (max-width: 450px) {
  #authorPhoto {
    margin-left: 30px;
  }
  #authorBio {
    margin-left: 0px;
  }
  .h5big {
    margin-left: 0px;
  }
  .personal-sm.pb-3 {
    margin-left: 0px;
  }
}
@media screen and (max-width: 414px) {
  
  #authorPhoto {
    margin-left: 0px;
  }
}

@media screen and (max-width: 411px) {
  
  #authorPhoto {
    margin-left: 0px;
  }
}
@media screen and (max-width: 360px) {
  
  #authorPhoto {
    margin-left: 0px;
  }
}

@media screen and (max-width: 320px) {
  #authorPhoto {
    margin-left: -30px;
  }
}

@media screen and (max-width: 280px) {
  #authorPhoto {
    margin-left: -48px;
  }
}
.blogh4 {
  color: black !important;
  text-decoration: none;
}

.blue-text {
  color: black !important;
  text-decoration: none;
}
.image-size{
  height: 50vh;
  width: 99%;
  position: relative;
  right: 2%;
  border-radius: 7px;
  z-index: 2;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  overflow: hidden;
  cursor: default
}
.blogcardsize{
  width: 90%;
  position: relative;
  left: 2%;
}
.instahover{
  color: #DD2A7B;
}
.instahover:hover {
  color: #FEDA77;
}
@media screen and (max-width: 2560px){
}
@media screen and (max-width: 1877px){
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1280px){
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width:912px){
}
@media screen and (max-width: 820px){
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 540px){
}
@media screen and (max-width:428px){
}
@media screen and (max-width: 414px) {
}

@media screen and (max-width: 411px) {
}

@media screen and (max-width: 393px){
}
@media screen and (max-width: 390px){
}
@media screen and (max-width: 375px) {
}

@media screen and (max-width: 360px) {
  
}

@media screen and (max-width: 320px) {
  
}

@media screen and (max-width: 280px) {

}
