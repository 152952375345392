  .s1 {
	color: black;
	font-style: normal;
	font-weight: bold;
	text-decoration: none;
	font-size: 14pt;
  }
  .s2 {
	color: #4b4b4d;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
  }
  p {
	color: black;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
	margin: 0pt;
  }
  h5 {
	color: #3c5caa;
	font-style: normal;
	font-weight: bold;
	text-decoration: none;
	font-size: 11pt;
  }
  .a {
	color: #0070eb;
	font-style: normal;
	font-weight: normal;
	text-decoration: underline;
	font-size: 11pt;
  }
  .s5 {
	color: #0070eb;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
  }
  .s6 {
	color: #0070eb;
	font-style: normal;
	font-weight: normal;
	text-decoration: underline;
	font-size: 11pt;
  }
  .s7 {
	color: black;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 12pt;
  }
  .s8 {
	color: #4b4b4d;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
  }
  .s9 {
	color: black;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
  }
  .s11 {
	color: #0563c1;
	font-style: normal;
	font-weight: normal;
	text-decoration: underline;
	font-size: 11pt;
  }
  .s12 {
	color: #0563c1;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
  }
  li {
	display: block;
  }
  #l1 {
	padding-left: 0pt;
	counter-reset: c1 1;
  }
  #l1 > li > *:first-child:before {
	counter-increment: c1;
	content: counter(c1, decimal) ". ";
	color: #3c5caa;
	font-style: normal;
	font-weight: bold;
	text-decoration: none;
	font-size: 11pt;
  }
  #l1 > li:first-child > *:first-child:before {
	counter-increment: c1 0;
  }
  #l2 {
	padding-left: 0pt;
  }
  #l2 > li > *:first-child:before {
	content: "• ";
	color: #4b4b4d;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
  }
  #l3 {
	padding-left: 0pt;
  }
  #l3 > li > *:first-child:before {
	content: " ";
	color: black;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 10pt;
	vertical-align: 1pt;
  }
  #l4 {
	padding-left: 0pt;
	counter-reset: e1 1;
  }
  #l4 > li > *:first-child:before {
	counter-increment: e1;
	content: counter(e1, upper-latin) ". ";
	color: black;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
  }
  #l4 > li:first-child > *:first-child:before {
	counter-increment: e1 0;
  }