.FormLogo {
  height: 60px;
  width: 176.94915771484375px;
  margin-top: 4.5rem;
}
.card-customize {
  margin: auto;
  margin-top: 2%;
  padding-bottom: 2%;
  height: fit-content;
  max-width: 396px;
  border-radius: 8px;
  box-shadow: 0px 10px 64px rgba(21, 48, 89, 0.3);
}
.border_top {
  border-top: 4px solid #8a438b;
  margin: 0 70px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.LoginHeading {
  color: black;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.link-btn {
  padding: 5px 10px;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  border-color: rgb(99, 98, 98);
  box-shadow: #ccc;
  cursor: pointer;
  color: #ffffff;
  cursor: pointer;
}

select > option {
  font-size: 14px;
  color: #949aa6;
  font-weight: 400;
  line-height: 1.42857143;
}
.txt-no-account {
  cursor: pointer;
  color: #000000;
  vertical-align: middle;
  padding: 0 10px;
  font-size: 14px;
}
.sgnup-desc {
  padding: 10px;
  color: #a8a5a5;
  font-size: 20px;
}
.mute-text {
  color: black;
  font-size: 12px;
  font-weight: bolder;
}

.social-btn {
  background-color: transparent;
  height: 72px;
  width: 105px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 4px rgba(21, 48, 89, 0.3);
}
.social-btn:hover {
  background-color: transparent;
  z-index: 1000;
  box-shadow: 2px 3px 8px rgba(21, 48, 89, 0.3);
}
.social-btn > span {
  color: black;
  font-size: 12px;
  font-weight: 400;
}
.btn-group-sm {
  font-size: "12px";
}
.Label {
  font-size: 10px;
  position: absolute;
  left: 30px;
  bottom: 35px;
}
.MdEmail {
  position: absolute;
  left: 32px;
  top: 7px;
}
.input-btn {
  max-width: 348px;
  height: 36px;
  background: #ececec;
  /* background-color: #8a438b; */
  color: #000000;
  border: 1px solid rgba(145, 144, 144, 0.125);
  font-size: 12px;
  border-radius: 4px;
}
.email {
  padding-left: 37px;
}
.email::placeholder {
  color: rgb(31, 30, 30);
  font-weight: 300;
}
.IoMdLock {
  position: absolute;
  left: 32px;
  top: 7px;
}
.pass {
  padding-left: 37px;
  padding-top: 7px;
}
.pass::placeholder {
  color: rgb(97, 97, 97);
  font-size: 17px;
  font-weight: 900;
}
.FillEye {
  position: absolute;
  top: 8px;
  right: 35px;
}
.eyeInvisible {
  position: absolute;
  top: 8px;
  right: 35px;
  cursor: pointer;
}

.error-text {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: red;
}
.form-select {
  color: #666d7b;
  /* font-size: 12px; */
  border: 1px solid rgba(145, 144, 144, 0.125);
}
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}

/* Sumbit btn Design */

.ib-btn {
  background-color: #6148a1;
  border-color: #6148a1;
}
.submit-btn {
  max-width: 348px;
  font-size: 16px;
  font-weight: 600;
  /* margin: 15px 0px 0 24px; */
  border-radius: 8px;
  transition-duration: 0.5s;
  cursor: pointer;
  padding: 10px 12px;
}
.ib-btn:hover {
  background-color: #7159a8;
}
.dontHave {
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;
  margin-left: 24px;
  color: #000000;
}
@media only screen and (max-width: 400px) {
  /* .card-customize{
    width: 280px;
    height: fit-content;
  }
  .LoginHeading{
    font-size: 14px;
    margin: 25px;
    text-align: center;
  }
  .social-btn{
    width: 85px;
    justify-content: space-between;
    margin: 0px;
  }
  .input-btn {
    width: 250px;
    margin-left: 13px;
    padding-left: 25px;
  }
  .Label {
    left: 15px;
  }
  .MdEmail{
    left: 17px;
  }
  .IoMdLock{
    left: 17px;
  }
  .forgotPasswordtext{
    position: relative;
    left: -5px;
  }
  .RememberMe{
    position: absolute;
    right: 5px;
  }
  .submit-btn{
    width: 260px;
    margin: 10px;

  } */
}

.signup_button {
  width: 160px;
  height: 48px;
  background-color: #fea115;
  border-color: #fea115;
  border-radius: 8px;
  margin-top: 15px;
  margin-right: 24px;
}
.signup_button:hover {
  z-index: 10;
  background-color: #fea115;
  border-color: #f8ad3e;
  box-shadow: 2px 1px 1px rgba(21, 48, 89, 0.3);
}

.flip {
  animation: flip 0.6s linear;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* .signup_button:hover b {
  transform: rotateY(180deg);
} */

.signup_button_about {
  width: 100px;
  height: 45px;
  background-color: #fea115;
  border-color: #fea115;
  border-radius: 8px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.signup_button_about:hover {
  z-index: 10;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 2px 1px 1px rgba(21, 48, 89, 0.3);
}

/* ======= Go Back forgot Password====== */
.font12 {
  color: #000000;
  font-size: 12px;
}
.forgotPasswordtext {
  cursor: pointer;
  margin: 15px 24px 0px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.forgotPasswordtext:hover {
  color: #fea115;
}
.checkbox {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: -0px;
}
.checkbox:checked {
  background-color: #fea115;
  border-color: #fea115;
}
.RememberMe {
  margin: 15px 24px 0px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

/* Footer Wrapper */
.footerWrap {
  margin-top: 50px;
  text-align: center;
}

.footerWrap > button {
  color: #111111;
  text-align: center;
  font-size: 20px;
  padding: 0 5px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.footerWrap > button:hover {
  color: #fea115;
}

.footerAuth_patents > span{
  font-size: 16px;
}

@media only screen and (max-width: 471px) {
  .footerWrap button{
    font-size: 12px;
  }

  .footerAuth_patents > span{
    font-size: 12px;
  }
}

@media only screen and (max-width: 390px) {
  .footerWrap button{
    font-size: 11px;
  }

  .footerAuth_patents > span{
    font-size: 11px;
  }
}

/* forgot */
.MdinfoForgot {
  position: absolute;
  left: 15px;
  top: 7px;
}
.LabelForgot {
  font-size: 11px;
  position: absolute;
  left: 16px;
  bottom: 36px;
}

@media only screen and (max-width: 420px) {
  .MdinfoForgot {
    left: 10px;
  }
  .LabelForgot {
    left: 12px;
  }
}

.forgotBack {
  height: 48px;
  width: 116px;
  border-radius: 8px;
  padding: 0px 8px 0px 0px;
  background-color: #fea115;
  border-color: #fea115;
  margin: 14px 12px 0px 28px;
}
.forgotBack:hover {
  z-index: 10;
  background-color: #fea115;
  border-color: #f8ad3e;
  box-shadow: 2px 1px 1px rgba(21, 48, 89, 0.3);
}
.forgotSubmit {
  height: 48px;
  width: 220px;
  border-radius: 8px;
  background-color: #6148a1;
  border-color: #6148a1;
  /* margin: 14px 0px 0px 9.7rem; */
}
.forgotSubmit:hover {
  background-color: #6148a1;
  box-shadow: 2px 3px 3px rgba(21, 48, 89, 0.3);
}
.backToLogin{
  height: 48px;
  width: 116px;
  border-radius: 8px;
  padding: 0px 8px 0px 0px;
  background-color: #fea115;
  color: white;
  border: none;
}
.backToLogin:hover{
z-index: 10;
background-color: #fea115;
border-color: #f8ad3e;
box-shadow: 2px 1px 1px rgba(21, 48, 89, 0.3);
}
.forgotAiLeft{
  z-index: 1;
  position: absolute;
  top: 10px;
 
  color: rgb(255, 255, 255);
  font-weight: 800;
}
.forgotAiLeft:hover{
  z-index: 1;
}