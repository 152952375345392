.FormLogo-Signup{
  height: 60px;
  width: 176.94915771484375px;
  margin-top: 3.5rem;
}
.card-customize-signup {
  max-width: 396px;
  height:fit-content;
  margin: auto;
  margin-top: 1.5%;
  border-radius: 8px;
  box-shadow: 0px 10px 64px rgba(21, 48, 89, 0.3);
}
.form-select{
  background-image: url(../../../public/img/icon.png);
  background-size: 35px 35px;
  background-position: right;
}
.options{
  color: black;
  font-size: 14px;
  font-weight: 500;
}
.options:hover{
  background-color: blueviolet;
}
  .padd{
    padding-left: 30px;
  }
.AccountType {
  max-width: 348px;
  height: 36px;
  color: black;
  background-color: #ececec;
  /* margin-left: 24px; */
}
.BiChevronDown{
    position: absolute;
    right: 35px;
    top: 1px;
    padding: 1px;
    border-radius: 4px;
    background-color: #6148A1;
    color: white;
}

.dateSelect{
  color: rgb(24, 24, 24);
  background-color: #ececec;
  max-width: 105px;
  height: 36px;
}
.dateSelect >option{
  color: black;
}
.BiChevronDownM{
  position: absolute;
  left: 84px;
  top: 1px;
  padding: 1px;
  border-radius: 4px;
  background-color: #6148A1;
  color: white;
}
.BiChevronDownD{
  position: absolute;
  left: 48%;
  top: 1px;
  padding: 1px;
  border-radius: 4px;
  background-color: #6148A1;
  color: white;
}
.BiChevronDownY{
  position: absolute;
  right: 58px;
  top: 1px;
  padding: 1px;
  border-radius: 4px;
  background-color: #6148A1;
  color: white;
}
.Labelsignup{
  font-size: 10px;
  position: absolute;
  left: 30px;
  bottom: 35px;
}
.Mdinfo{
  position: absolute;
  left: 30px;
  top: 8px;
}
.input-btn::placeholder{
  padding-left: 20px;
}
@media only screen and (max-width :400) {
  
}