.press-card-main {
  display: flex;
  justify-content: center;
  margin-top: 28px;
}
.press-card-customize {
  height: fit-content;
  width: 724px;
  border-radius: 8px;
  padding-bottom: 30px;
}
.press-border_top {
  border-top: 4px solid #8a438b;
  margin: 0 190px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.press-LoginHeading {
  font-size: 16px;
  font-weight: bolder;
  color: black;
  text-align: center;
  /* margin-left: 38%; */
}
.press-dateSelect {
  height: 36px;
  width: 157px;
  border-radius: 4px;
  padding: 8px 0px 8px 38px;
  background-color: #ececec;
  margin: 10px;
}
.press-dateSelect > option {
  color: black;
}
.press-Bicalender {
  color: black;
  position: absolute;
  margin-left: -75px;
  margin-top: 18px;
}
.press-BiChevronDownM {
  position: absolute;
  right: 7%;
  top: 11px;
  padding: 1px;
  border-radius: 4px;
  background-color: #6148a1;
  color: white;
}
.press-BiChevronDownY {
  position: absolute;
  right: 7%;
  top: 11px;
  padding: 1px;
  border-radius: 4px;
  background-color: #6148a1;
  color: white;
}
.press-Search-btn {
  height: 36px;
  width: 204px;
  background: #6148a1;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  margin: 10px;
}
.press-card-size {
  width: 330px;
}
.press-image {
  height: 205px;
  width: 330px;
  border-radius: 6px;
}
.press-card-title {
  color: black;
  font-size: 14px;
  font-weight: 600;
}
.press-card-text {
  font-size: 12px;
}
.press-icon-share {
  padding: 5px;
  background-color: #56d185;
  border-radius: 5px;
  cursor: pointer;
}
.press-icon-Like {
  padding: 5px;
  background-color: #5abce2;
  border-radius: 5px;
}
.lastupdate {
  font-size: 10px;
}
.press-read-btn {
  /* width: 330px; */
  height: 48px;
  background: #6148a1;
  border-radius: 8px;
  border: none;
}
.blog-read-btn {
  width: 100%;
  height: 48px;
  background: #6148a1;
  border-radius: 8px;
  border: none;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1877px) {
}

@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1320px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 912px) {
}
@media screen and (max-width: 820px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 540px) {
}
@media screen and (max-width: 428px) {
}
@media screen and (max-width: 414px) {
}

@media screen and (max-width: 411px) {
}
@media screen and (max-width: 390px) {
}
@media screen and (max-width: 375px) {
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 320px) {
}
@media screen and (max-width: 280px) {
}

/* new page press Relese */

.form-header.blue.accent-1 {
  background-color: #62479f !important;
}
.btn.btn-light-blue {
  background-color: #62479f !important;
}
.btn-floating.blue.accent-1 {
  background-color: #62479f !important;
}
.h1-responsive.font-weight-bold.text-center.my-5 {
  color: #62479f !important;
}
.footer-copyright.py-3.text-center.wow.fadeIn {
  background-color: #62479f !important;
}
.btn.btn-outline-warning.my-2.my-sm-0:hover {
  color: #ffb940 !important;
}
button.btn.btn-pink.btn-rounded {
  background-color: #62479f !important;
}
span.badge.pink {
  background-color: #62479f !important;
}
a.px-2.fa-lg.in-ic {
  color: #dd2a7b;
}
a.in-ic.pr-2 {
  color: #dd2a7b;
}
@media screen and (max-width: 1440px) {
  #authorBio {
    margin-left: 0px;
  }
  .h5big {
    margin-left: 0px;
  }
  .personal-sm.pb-3 {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1200px) {
  #authorBio {
    margin-left: 150px;
  }
  .h5big {
    margin-left: 150px;
  }
  .personal-sm.pb-3 {
    margin-left: 150px;
  }
}
@media screen and (max-width: 768px) {
  #authorBio {
    margin-left: 0px;
  }
  .h5big {
    margin-left: 0px;
  }
  .personal-sm.pb-3 {
    margin-left: 0px;
  }
}
@media screen and (max-width: 450px) {
  #authorPhoto {
    height: "240px"; width: "230px"
  }
  #authorBio {
    margin-left: 0px;
  }
  .h5big {
    margin-left: 0px;
  }
  .personal-sm.pb-3 {
    margin-left: 0px;
  }
}
@media screen and (max-width: 414px) {
}

@media screen and (max-width: 411px) {
}

@media screen and (max-width: 360px) {
  #authorPhoto {
    height: "240px"; width: "230px"
  }
}

@media screen and (max-width: 320px) {
  #authorPhoto {
    height: "234px"; width: "220px"
  }
}

@media screen and (max-width: 280px) {
  #authorPhoto {
    height: "220px"; width: "215px"
  }
}
