html,
body {
  width: 100%;
  min-height: 100vh;
  /* font-family: "Roboto"; */
  color: #000;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

#wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background: #edf0f1;
  display: flex;
}

.pre-content {
  width: 100%;
  position: absolute;
  height: 328px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 6px 60px rgba(164, 179, 186, 0.25);
  z-index: 0;
}

.about-content .aboutmain{
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.about-content .aboutmain{
  width: calc(100% - 70px);
  max-width: 1156px;
}

.aboutheader {
  width: 100%;
  padding: 50px 0;
  display: flex;
}

@media screen and (max-width: 800px) {
  .aboutheader a {
    margin: auto;
  }
}

.aboutmain {
  background: #ffffff;
  box-shadow: 0px 10px 64px rgba(21, 48, 89, 0.3);
  border-radius: 24px;
  overflow: hidden;
}

.aboutsection {
  width: 100%;
  display: flex;
}

/*****WHAT****/

.about-what-left {
  width: 100%;
  max-width: 747px;
  position: relative;
}

.about-what-left-bg {
  width: 62%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background: linear-gradient(180deg, #e0c3f1 0%, #def1f2 100%);
  border-radius: 24px 0px 52px;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
}

.about-what-left img {
  max-width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 46px;
}

.what-right {
  width: 100%;
  max-width: 370px;
  padding-left: 16px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.topper::before {
  content: " ";
  width: 205px;
  height: 8px;
  background: linear-gradient(90deg, #c5e66c 0%, #3fb4ea 100%);
  border-radius: 0px 0px 8px 8px;
  display: block;
  position: absolute;
}

.what-right span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1385b9;
}

.about-h1 {
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: #002551;
}

@media screen and (max-width: 576px) {

  .about-h1 {
    font-weight: 800;
    font-size: 25px;
    line-height: 33px;
    color: #002551;
  }

}

@media screen and (max-width: 418px) {

  .about-h1 {
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    color: #002551;
  }

}


.about-h1 span {
  font-family: 'Roboto', sans-serif;
  line-height: 33px;
  color: #9f47a0;
  font-size: 28px;
  font-weight: 900;
}

.what-right h1 {
  margin: 0;
}

.what-right p {
  font-weight: 100;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  margin-top: 15px;
}

.what-right ul {
  padding-left: 12px;
  font-size: 14px;
  list-style: none;
}

.what-right ul li::before {
  content: "\2024";
  color: #000;
  font-weight: bold;
  display: inline-block;
  width: 12px;
  margin-left: -2px;
}

.what-right ul li {
  margin-top: 15px;
}

@media screen and (max-width: 800px) {
  .aboutsection {
    flex-flow: column;
  }

  .about-what-left {
    order: 1;
  }

  .about-what-left-bg {
    bottom: 0;
    top: auto;
    height: 194px;
    width: 100%;
    border-radius: 0;
  }

  .what-right {
    max-width: none;
    padding: 0 22px;
    /* text-align: center; */
  }

  .what-right ul {
    text-align: left;
    max-width: 230px;
    margin: 0 auto;
  }

  .topper::before {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

@media screen and (max-width: 400px) {
  .what-right {
    max-width: none;
    padding: 0 10px;
  }
}


/*****DIFF*****/

.diff-content {
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(96.81deg, #effbff 10.97%, #9ce7ff 80.91%);
  padding-left: 6%;
  padding-bottom: 30px;
  display: flex;
  overflow: hidden;
  position: relative;
}

.diff-content h1 {
  margin-top: 60px;
  position: relative;
  z-index: 1;
  text-align: right;
}

.diff-content p {
  margin-top: 65px;
  position: relative;
  z-index: 1;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-left: 50px;
}

.diff-content img {
  width: 55%;
  max-width: 634px;
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 800px) {
  .diff-content {
    flex-flow: column;
    padding: 0;
  }

  .diff-content h1 {
    padding: 0 20px;
    text-align: center;
  }

  .diff-content p {
    padding: 0px 20px;
    padding-top: 15px;
    text-align: center;
    margin: 0;
  }

  .diff-content img {
    width: 110%;
    left: -10%;
    max-width: none;
    position: relative;
  }
}

/*****WHO*****/

#who {
  box-sizing: border-box;
  padding: 50px 70px;
  padding-bottom: 0;
}

.who-image {
  width: 40%;
  max-width: 350px;
  display: flex;
}

.who-image img {
  width: 100%;
  margin: auto;
}

.who-content {
  width: 40%;
  padding-left: 40px;
  box-sizing: border-box;
}

.who-content img {
  display: none;
}

.who-content h1 {
  text-align: right;
  margin-top: 0;
  margin-right: 20px;
}

.who-content p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: right;
  max-width: 200px;
  margin-left: auto;
}

.who-content ul {
  padding: 0;
  margin-top: 5px;
  list-style: none;
}

.who-content ul li::before {
  content: "\2024";
  color: #9f47a0;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -12px;
}

.who-content ul li {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 800px) {
  #who {
    padding-left: 22px;
    padding-right: 22px;
  }

  .who-image {
    display: none;
  }

  .who-content {
    width: 100%;
    padding-left: 0;
  }

  .who-content img {
    display: initial;
    width: 90%;
    margin-left: 5%;
  }

  .who-content .h1 {
    text-align: center;
  }

  .who-content p {
    text-align: center;
    width: 90%;
    margin: 20px auto;
    max-width: none;
  }

  .who-content ul {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

/*****SIGN UP*****/

#sign-up {
  background-image: url("../../../../public/Images/clouds.png"),
    linear-gradient(180deg, #94e5ff -1.11%, rgba(239, 251, 255, 0) 100%);
  background-size: contain;
  background-repeat: no-repeat;
}

.sign-up-content {
  padding: 60px 90px;
  width: 100%;
}

.suc-title {
  display: flex;
}

.suc-title {
  text-align: right;
  margin: 0;
}

.suc-title p {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  max-width: 270px;
  margin: 0;
  margin-top: 5px;
  margin-left: 30px;
}

.suc-button {
  margin-right: 75px;
  margin-left: auto;
  position: relative;
  top: -10px;
}

.suc-button button {
  border-radius: 45px;
  height: 60px;
  width: 200px;
  /* font-size: 25px; */
}

.sup-process {
  position: relative;
  width: 100%;
  background-image: url("../../../../public/Images/figure-pc.png");
  background-position: center 230px;
  background-repeat: no-repeat;
  background-size: 82%;
  margin-top: 50px;
  display: flex;
  flex-flow: column;
}

.step {
  position: relative;
  margin: auto;
  /*width: 100%;*/
}

.step img {
  max-width: 100%;
}

.step .desc {
  position: absolute;
  display: flex;
}

.step .desc span {
  background: #9e479f;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  border-radius: 100px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  /* padding: 10px 30px; */
  /*! margin: auto; */
  width: 135px;
  max-height: 43px;
}

@media screen and (max-width: 600px ) {
  
  .who-content h1{
    margin-right: 5px;  
  }

  .step .desc span {
    font-size: 12px;
    /* line-height: 19px; */
    width: auto;
    max-width: none !important;
    max-height: 20px !important;
    min-width: 80px;
  }
}

@media screen and (max-width: 460px ) {

  .aboutheader{
    margin-left: 10px;
  }

  .content{
    width: calc(100% - 40px);
  }

  .step .desc span {
    font-size: 9px;
    /* line-height: 19px; */
    max-width: none !important;
    max-height: 9px !important;
    min-width: 35px;
    width: auto;
  }
}

.step .desc p {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  max-width: 230px;
  position: relative;
  margin: auto;
}

.step .desc.done span {
  background: #9bc628;
}

/*****steps****/

.step1 {
  margin-left: -70px;
  max-width: 82%;
}

.step1 .desc p {
  margin-left: 20px;
}

.step1 .desc {
  top: 80px;
  right: -250px;
}

.step2 {
  margin-right: -30px;
  margin-top: -24%;
  max-width: 56%;
}

.step2 .desc {
  top: 26%;
  flex-flow: column;
  left: -20px;
}

.step2 .desc p {
  text-align: right;
  margin-top: 15px;
  margin-left: -160px;
  z-index: 2;
}

.step3 {
  max-width: 81%;
  margin-top: -15%;
  margin-left: -70px;
}

.step3 .desc {
  top: -14%;
  flex-flow: column-reverse;
  left: 19%;
  margin: auto;
}

.step3 .desc p {
  text-align: left;
  margin-top: 0;
  margin-left: 0px;
  margin-bottom: 15px;
  left: 100px;
}

.step4 {
  max-width: 70%;
  margin-top: -14%;
  margin-right: -40px;
}

.step4 .desc {
  top: -15%;
  flex-flow: column-reverse;
  right: 9%;
  margin: auto;
}

.step4 .desc p {
  text-align: right;
  margin-top: 0;
  margin-left: -270px;
  margin-bottom: 15px;
  left: 100px;
}

.step5 {
  max-width: 81%;
  margin-top: -14%;
  margin-left: -70px;
}

.step5 .desc {
  top: -11%;
  flex-flow: column-reverse;
  left: 11%;
  margin: auto;
}

.step5 .desc p {
  text-align: left;
  margin-top: 0;
  margin-left: 90px;
  margin-bottom: 15px;
}

.sup-process .form {
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  max-width: 215px;
  margin-left: auto;
  margin-right: 3%;
  margin-top: -26%;
}

.sup-process .form h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #9f47a0;
  margin: 0;
}

.sup-process .form p {
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  margin: 0;
  margin-top: 10px;
}

.sup-process .form button {
  background: #fea115;
  border: 0;
  box-shadow: 0px 30px 30px rgba(36, 85, 130, 0.3);
  border-radius: 100px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  padding: 15px 20px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.sup-process .form button:hover {
  background: #9f47a0;
}

.signup-flip {
  animation: flip 0.6s linear;
}

@keyframes signup-flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@media screen and (max-width: 1240px) {

  .suc-button {
    margin-right: 10%;
  }

  .sign-up-content {
    padding: 60px 10px;
  }

  .sup-process {
    background-size: 70%;
  }

  .step .desc p {
    max-width: 190px;
  }

  .step2 .desc p {
    margin-left: -130px;
  }

  .step3 .desc {
    top: -17%;
    left: 26%;
  }

  .step3 .desc p {
    left: 9vw;
  }

  .step4 .desc {
    top: -13%;
    right: 21%;
  }

  .step4 .desc p {
    margin-left: -230px;
  }
}

@media screen and (max-width: 900px) {

  .suc-button {
    margin-right: auto;
    top: 5px;
  }

  .sign-up-content {
    padding: 60px 90px;
  }

  .sign-up-content {
    box-sizing: border-box;
    padding: 50px 0px;
  }

  .suc-title {
    flex-flow: column;
  }

  .suc-title h1 {
    text-align: center;
  }

  .suc-title p {
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
  }

  .sup-process {
    background-image: none;
  }

  .step {
    display: flex;
    flex-flow: column-reverse !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: none !important;
    background-image: url("../../../../public/Images/mobile-bar.png");
    background-repeat: no-repeat;
    background-position: 95px top;
    margin-bottom: 30px !important;
  }

  .step img {
    margin-bottom: -10%;
  }

  .step .desc {
    position: initial !important;
    padding: 0 7vw;
    flex-flow: row;
    margin: 0;
  }

  .step .desc p {
    margin: 0 !important;
    margin-left: auto !important;
    text-align: right;
    left: 0;
  }

  .step .desc span {
    font-size: 14px;
    /* line-height: 19px; */
    max-width: none !important;
    max-height: 40px !important;
    min-width: 60px;
    width: auto;
  }

  .step2,
  .step4 {
    background-position: calc(100% - 95px) top;
  }

  .step2 .desc,
  .step4 .desc {
    flex-flow: row-reverse !important;
  }

  .step2 .desc p,
  .step4 .desc p {
    margin-left: 0 !important;
    margin-right: auto !important;
    text-align: left;
  }

  .sup-process .form {
    max-width: none;
    width: calc(100% - 80px);
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
  }
}

/* footer start */
#footer {
  margin-top: 30px;
  margin-bottom: 30px;
}
  .copy {
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

#footer p:first-of-type {
  font-weight: bold;
} 
footer{
  padding: 30px;
  color: #000000;
  background-color: white;
}

footer p{
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

footer p:first-of-type{
  font-weight: bold;
}