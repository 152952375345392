@font-face {
  font-family: 'Roboto';
  src: local("Roboto"),
   url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
   font-style: normal;
  }
body {
  min-height: 100vh;
  font-family: 'Roboto';
  font-style: normal;
  background-color: #ffff;
  zoom:0;
  color: #474d59;
  margin: 0;
  background: linear-gradient(
    to top,
    #D1D1D1 0%,
    #D1D1D1 50%,
    #ffff 50%,
    #ffff 100%
  );
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'Roboto';
}
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d1d1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8461b9 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c7a8f6;
}