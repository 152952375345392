.full-content{
  width: 100%;
}
.First-card-customize{
  margin: auto;
  margin-top: 2%;
  padding-bottom: 2%;
  height: fit-content;
  max-width: 396px;
  border-radius: 8px;
  box-shadow: 0px 10px 64px rgba(21, 48, 89, 0.3);
}
.headingSteps {
  color: #6148a1;
  text-align: center;
  text-transform: capitalize;
  margin-top: 15px;
}
.setupHeading {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}
.logo-cam {
  background-color: #9f47a0;
  color: aliceblue;
  position: absolute;
  top: 30% !important;
  left: 58% !important;
  padding: 9px;
  cursor: pointer;
}
.prsonal {
  align-items: center;
  margin: auto;
}
.photouser {
  width: 130px;
  height: 130px;
  background-size: cover;
  background-position: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}
.userNameStep {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-top: 20px;
}
.genderStep {
  color: #6148a1;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.LabelStep {
  font-size: 11px;
  margin-left: 29px;
}
.step-input-btn {
  height: fit-content;
  max-width: 348px;
  background-color: #ececec;
  border-radius: 4px;
  /* margin-left: 28px; */
  padding-bottom: 30px;
  font-size: 14px;
}

.Addrefinput {
  max-width: 348px;
  height: 36px;
  background-color: #6148a1;
  /* margin-left: 28px; */
  border-radius: 0px 0px 5px 5px;
  padding-top: 5px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
}
.Addbtnp {
  color: white;
  font-size: 12px;
  font-weight: 520;
  padding-top: 4px;
  cursor: pointer;
}
.BsPlusLg-icon {
  margin-left: 10px;
  margin-top: 0px;
}

@media only screen and (min-width: 997px) {
  .logo-cam {
    position: absolute;
    top: 39%;
    left: 28%;
  }
}
@media only screen and (max-width: 768px) {
  .logo-cam {
    position: absolute;
    top: 39% !important;
    left: 28% !important;
  }
}

/* .text-size {
  width: 85%;
} */
.offers {
  width: 50%;
}
.btn-outline {
  border: 1px solid #bbc2c7;
}
.submit-butn {
  max-width: 348px;
  background-color: #6148a1;
  color: #ffffff;
  /* margin: 15px 0px 10px 24px; */
  padding: 14px 14px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  border-color: #6148a1;
  cursor: pointer;
}
.submit-butn:hover {
  background-color: #6148a1;
}

.business {
  margin-left: 25px;
}
@media only screen and (max-width: 997px) {
  .prsonal,
  .summary {
    flex-direction: column;
    text-align: center;
    margin: auto;
  }
  .full-content {
    width: 90%;
  }
}

/* icon close */

.aioutline {
  position: absolute;
  right: 2%;
  top: 3%;
  cursor: pointer;
}

/* second step */
.clickOnMap {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.lasttext {
  font-size: 12px;
}
.line {
  line-height: 32px;
}
.map-wrap {
  position: relative;
  /* width: 100%; */
  height: 60%; /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  /* margin-left: 6px; */
  max-width: 348px;
  height: 320px;
  border: 1px solid #6148a1;
  border-radius: 8px;
}
.secondbtn {
  max-width: 348px;
  background-color: #6148a1;
  color: #ffffff;
  margin-top: 10px;
  padding: 14px 12px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  border-color: #6148a1;
  cursor: pointer;
}

/* Third step */
#FirstCity .mapboxgl-ctrl-geocoder{
  width: 100%;
  height: 40px;
  z-index: 100;
  background-color: #ececec;
  box-shadow: none;
  background-image: none;
}

#SecondCity .mapboxgl-ctrl-geocoder{
  width: 100%;
  height: 40px;
  z-index: 50;
  background-color: #ececec;
  box-shadow: none;
  background-image: none;
}

#ThirdCity .mapboxgl-ctrl-geocoder{
  width: 100%;
  height: 40px;
  z-index: 1;
  background-color: #ececec;
  box-shadow: none;
  background-image: none;
}

.thirdlabel {
  font-size: 11px;
  margin-left: 18px;
}

@media only screen and (min-width: 408px) {
  #FirstCity .mapboxgl-ctrl-geocoder {
    width: 340px; 
  }
  
  #SecondCity .mapboxgl-ctrl-geocoder {
    width: 340px; 
  }
  
  #ThirdCity .mapboxgl-ctrl-geocoder {
    width: 340px; 
  }
  
  .thirdlabel {
    font-size: 11px;
    margin-left: 28px;
  }
}

.GrForm {
  position: absolute;
  left: 28px;
  top: 28px;
}
.input-pad{
  padding-left: 30px;
  font-size: 16px;
}
.aicloseinput{
  position: absolute;
  right:24px ;
  top: 0px;
  padding: 5px;
  border-radius: 4px;
  background-color: #6148A1;
  color: white;
  z-index: 100;
  cursor: pointer;
}
/* first Bussiness */
.react-tel-input{
  height: 36px;
  width: 348px;
  border-radius: 4px;
  margin-left: 28px;
  padding-bottom: 30px;
}
.react-tel-input .form-control{
  height: 36px;
  background-color: #ececec;
  font-size: 13px;
  border-radius: 4px;
}
.react-tel-input .flag-dropdown:hover{
  background-color: #6148A1;
}

/* Fourth step */
.rowspace{
  --bs-gutter-x: 0.9rem;
}
.rowspace2{
  --bs-gutter-x: 15px;
}
.cardNumber{
  width: 233px;
  height: 36px;
  background-color: #ECECEC;
  color: #000000;
  font-size: 13px;
  margin-left: 28px;
  padding-left: 37px;
}
.cardNumber::placeholder{
  color: #000000
}
.MdCard{
  position: absolute;
  top: 31px;
  left: 44px;
}
.CVClabel{
  font-size: 10px;
}
.CVCinput{
  width: 99px;
  height: 36px;
  background-color: #ECECEC;
  padding-left: 32px;
  font-size: 13px;
  margin-right: 10px;
}
.CVCinput::placeholder{
  color: #000000
}
.TiSortNumerically{
  position: absolute;
  top: 33px;
  left: 17px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 2px;
}
.cardMonth{
  margin-left: 28px;
  width: 166px;
  height: 36px;
  background-color: #ECECEC;
  padding-left: 32px;
}
.cardMonth::placeholder{
  color: #000000
}
.cardYear{
  width: 166px;
  height: 36px;
  background-color: #ECECEC;
  padding-left: 32px;
}
  .cardYear::placeholder{
    color: #000000
  }
.BiCalendarM{
  color: black;
  position: absolute;
  left: 39px;
  top: 30px;
}
.BiCalendarY{
  color: black;
  position: absolute;
  left: 11px;
  top: 30px;
}
.plan {
  color: #474d5e;
  padding-left: 15%;
  font-weight: 700;
  font-size: 16px;
}
.bgclor {
  background-color: #5eaf5e;
  border-color: #004e00;
  margin: 5px 15px;
  border-radius: 25px;
}
.autoRenew{
  margin-top: 15px;
  margin-left: 28px;
  font-size: 14px;
  color: #000000;
}
.checkbox1 {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-right: 10px;
  border: 1px solid #6148A1
}
.checkbox1:checked {
  background-color: #6148a1;
  border-color: #6148A1;
}
.submit-btn2 {
  height: 48px;
  max-width: 348px;
  border-radius: 8px;
  color: #ffffff;
  background-color:#6148A1;
  border: none;
  cursor: pointer;
  margin-top: 14px;
}

.submit-btn2Disabled {
  height: 48px;
  max-width: 348px;
  border-radius: 8px;
  color: #ffffff;
  background-color:grey;
  border: none;
  cursor: pointer;
  margin-top: 14px;
}
@media only screen and (max-width: 440px) {
  .input-pad {
    width: 95%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 997px) {
  .full-content {
    width: 95%;
    padding-left: 7%;
  }
}
/* thank You Model */
.font {
  font-size: 60px;
  color: #000000;
  font-weight: bolder;
}
.check {
  color: #5eaf5e;
}
.center {
  margin-top: 6%;
}